import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AddProduct} from './add-product.model';
import {ProductService} from './../api/product.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {CategoryService} from 'app/views/products/categories/category.service';
import {ListProductCategory} from 'app/views/products/product-list/list-product-category.model';
import {Attribute} from 'app/views/products/edit-product/edit-product.model';
import {UserService} from 'app/user/user.service';
import {Location} from '@angular/common';
import {CategoryConstantService} from '../categories/category-constant.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  productForm: FormGroup;
  categories: ListProductCategory[];
  modalRef: BsModalRef;
  attribs = [];
  attributeToDeleteId: number;

  constructor(private fb: FormBuilder, private productService: ProductService,
              private categoryService: CategoryService, private router: Router, private toastr: ToastrService,
              private modalService: BsModalService, private spinnerService: NgxSpinnerService,
              private userService: UserService,  private categoryConstansService: CategoryConstantService, private location: Location) {
    this.createForm();
  }

  ngOnInit() {
    this.spinnerService.show();
    this.getIntialData();
  }

  getIntialData() {
    this.categoryService.getOrderedListProductCategories().subscribe(
      categories => {
        this.categories = categories;
        this.spinnerService.hide();
      });
  }

  addAttribute() {
    const lastElem = this.attribs[this.attribs.length - 1];
    const newAttributeId = lastElem ? lastElem.id + 1 : 1;
    this.attribs.push(new Attribute(newAttributeId, this.productForm.value.attribute));
    this.productForm.patchValue({attribute: ''});
  }

  deleteAttribute(attributeId: number) {
    this.attribs = this.attribs.filter(a => a.id !== attributeId);
  }

  declineAttributeDelete(): void {
    this.modalRef.hide();
    this.attributeToDeleteId = null;
  }

  onSubmit() {
    if (this.isValidForm()) {
      const addProduct = this.prepareSaveProduct();
      this.productService.addProduct(addProduct).subscribe(
        response => {
          this.router.navigate(['./products/edit/' + response.data.id], {fragment: 'top'});
          this.toastr.success('Product created successfuly', '');
        });
    }
  }

  onCancel() {
    this.location.back();
  }

  prepareSaveProduct(): AddProduct {
    const formModel = this.productForm.value;

    const saveAddProduct: AddProduct = {
      name: formModel.name as string,
      info: formModel.info as string,
      shortName: formModel.shortName as string,
      catalogueNumber: formModel.catalogueNumber as string,
      isActive: false,
      assets: formModel.assets as string,
      description: formModel.description as string,
      additionalInfo: formModel.additionalInfo as string,
      additionalLedInfo: formModel.additionalLedInfo as string,
      assemblyDescription: formModel.assemblyDescription as string,
      category_id: formModel.categoryId as number,
      sortOrder: formModel.sortOrder as number,
      attribs: this.attribs.map(a => a.description)
    };
    return saveAddProduct;
  }

  createForm() {
    this.productForm = this.fb.group({
      name: ['', Validators.required],
      info: '',
      shortName: '',
      catalogueNumber: ['', Validators.required],
      assets: '',
      description: '',
      additionalInfo: [''],
      additionalLedInfo: [''],
      assemblyDescription: '',
      categoryId: ['', Validators.required],
      sortOrder: [0, Validators.required],
      attribute: ['']
    });
  }

  isValidForm() {
    return this.productForm.status === 'VALID';
  }

  get name() {
    return this.productForm.get('name');
  }

  get catalogueNumber() {
    return this.productForm.get('catalogueNumber');
  }

  get categoryId() {
    return this.productForm.get('categoryId');
  }


  get isLedCategory() {
    return this.productForm.getRawValue().categoryId == this.categoryConstansService.LED_TAPES
  }

  getControl(formControl, name) {
    const originalControl = this.productForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }
}


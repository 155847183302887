
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from '../../error.service';
import { User } from '../../user/user.model';
import { UserService } from '../../user/user.service';
import { Configurator } from './Configurator.model';
import { ConfiguratorStep } from './ConfiguratorStep.model';
import { ApiConfigurator, ApiGetConfiguratorResponse } from './api/api-configurators-responses.model';



@Injectable()
export class ConfiguratorService {

  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getConfigurators(): Observable<Configurator[]> {

    return observableForkJoin([
      this.http.get(this.apiUrl + '/configuratorsApi?langId=' + this.user.languageId)
    ]).pipe(
      map((data: any[]) => {
        const configuratorsResponse: ApiGetConfiguratorResponse = data[0];
        const listConfigurators = [];
        for (const configurator of configuratorsResponse.data) {
          const newListConfigurator = new Configurator(
            configurator.id, configurator.name, configurator.steps
          );
          listConfigurators.push(newListConfigurator);
        }
        return listConfigurators;
      }),
      catchError(this.errorService.handleError<any>('getConfigurators')),);
  }

  getConfigurator(configuratorId: number): Observable<Configurator> {
    return this.http.get(this.apiUrl + '/configuratorsApi/' + configuratorId + '?langId=' + this.user.languageId).pipe(

      map((response: ApiGetConfiguratorResponse) => {
        const apiConfigurator = (new ApiGetConfiguratorResponse(response.data)).getData();

        const steps: ConfiguratorStep = apiConfigurator.steps.data;

        const configurator: Configurator = {
          id: apiConfigurator.id,
          name: apiConfigurator.name,
          steps: steps,

        }
        return configurator;
      }),



      catchError(this.errorService.handleError<any>('getConfigurator')),);
  }

  updateConfigurator(configuratorId: number, configurator: Configurator): Observable<ApiGetConfiguratorResponse> {
    return this.http
      .put(this.apiUrl + '/configuratorsApi/' + configuratorId + '?langId=' + this.user.languageId,
        JSON.stringify(configurator), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateConfigurator')));
  }

}

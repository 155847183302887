export class ListFeature {
  constructor(public id: number,
    public name: string,
    public categoryId: number,
    public categoryName: string,
    public createdOn: string,
    public modifiedOn: string,
    public deletedOn: string,
    public active: boolean,
    public isVisibleOnSite: boolean,
    public sortOrder: number) {
  }
}

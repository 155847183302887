import { DistributorService } from './../distributor.service';
import { CategoryList, PostLink } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { UserLanguage } from 'app/user/user-language.model';
import { environment } from '../../../../environments/environment';
import { UserService } from 'app/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as Moment from 'moment';

@Component({
  selector: 'post-edit',
  templateUrl: './post-edit.component.html',
  styleUrls: ['./post-edit.component.scss']
})
export class PostEditComponent implements OnInit {
  postId: number;
  postEditForm: FormGroup;
  categories: CategoryList[];
  subCategories: CategoryList[];
  modalRef: BsModalRef;
  languages: UserLanguage[];
  postEdit: any;
  linkNames: any[];
  link: PostLink = {
    name: '',
    url: '',
  };

  apiUrl: string = environment.pageUrl;

  constructor(private fb: FormBuilder, private router: Router, private distributorService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService, private route: ActivatedRoute,
    private userService: UserService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.route.params.subscribe(params => {
      this.postId = parseInt(params['id']);
      this.distributorService.getLanguages().subscribe(langs => {
        this.languages = langs
        this.distributorService.getPost(this.postId).subscribe(
          post => {
            this.postEdit = post;
            this.setFormValues();
            this.spinnerService.hide();
          }
        );
      });
    });
    this.getIntialData();
    this.createForm();
  }

  setMainImage(image: string) {
    if (this.postEdit) {
      this.postEdit.mainImageSrc = image;
    }
  }

  getIntialData() {
    this.distributorService.getAllCategories().subscribe(
      categories => {
        this.categories = categories;
        this.spinnerService.hide();
      });
    this.distributorService.getAllSubCategories().subscribe(
      categories => {
        this.subCategories = categories;
        this.spinnerService.hide();
      });
    this.distributorService.getLinkNames().subscribe(
      links => {
        this.linkNames = links;
        this.spinnerService.hide();
      }
    );
  }

  onSubmit() {
    const data = this.getData();
    if (this.isValidForm()) {
      this.spinnerService.show();
      this.distributorService.updatePost(data, this.postId)
        .subscribe(
          (res) => {
            this.toastr.success('Post updated successfully');
            this.spinnerService.hide();
          });
    }
  }

  onCancel() {
    this.location.back()
  }

  private setFormValues() {
    this.postEditForm.setValue({
      isActive: this.postEdit.isActive as boolean,
      isMain: this.postEdit.isMain as boolean,
      title: this.postEdit.title as string,
      categoryId: this.postEdit.category.parent ? this.postEdit.categoryId as number : null,
      mainCategoryId: this.postEdit.category.parent ? this.postEdit.category.parent.id as number : this.postEdit.categoryId,
      publicationDate: this.postEdit.publicationDate as string,
      description: this.postEdit.description as string,
      linkName: this.postEdit.linkName as string,
      linkNameDef: '',
      linkUrl: '',
    });
  }

  createForm() {
    this.postEditForm = this.fb.group({
      isActive: [''],
      isMain: [''],
      title: ['', Validators.required],
      categoryId: [''],
      mainCategoryId: ['', Validators.required],
      publicationDate: ['', Validators.required],
      linkName: ['', Validators.required],
      description: [''],
      linkNameDef: [''],
      linkUrl: [''],
    });
  }

  private getData(): any {

    const formModel = this.postEditForm.value;
    const formattedDate = Moment(new Date(formModel.publicationDate)).format('YYYY-MM-DD');

    return {
      is_active: formModel.isActive as boolean,
      is_main: formModel.isMain as boolean,
      title: formModel.title as string,
      category_id: formModel.categoryId ? formModel.categoryId as number : formModel.mainCategoryId as number,
      publication_date: formattedDate,
      description: formModel.description as string,
      link_name: formModel.linkName as string,
    }
  }

  isValidForm() {
    return this.postEditForm.status === 'VALID';
  }

  get isActive() {
    return this.postEditForm.get('isActive');
  }

  get isMain() {
    return this.postEditForm.get('isMain');
  }

  get title() {
    return this.postEditForm.get('title');
  }

  get categoryId() {
    return this.postEditForm.get('categoryId');
  }
  get linkName() {
    return this.postEditForm.get('linkName');
  }

  get linkNameDef() {
    return this.postEditForm.get('linkNameDef');
  }
  get linkUrl() {
    return this.postEditForm.get('linkUrl');
  }

  get mainCategoryId() {
    return this.postEditForm.get('mainCategoryId');
  }
  get publicationDate() {
    return this.postEditForm.get('publicationDate');
  }
  get description() {
    return this.postEditForm.get('description');
  }
  get selectedMainCategoryId() {
    return this.postEditForm.get('mainCategoryId').value;
  }
  get contentControl() {
    const formControl = new FormControl(this.postEditForm.value.content as string, Validators.required);
    return this.getControl(formControl, 'content');
  }
  get summaryControl() {
    const formControl = new FormControl(this.postEditForm.value.summary as string, Validators.required);
    return this.getControl(formControl, 'summary');
  }

  getControl(formControl, name) {
    const originalControl = this.postEditForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }

  storePostLink() {
    const formModel = this.postEditForm.value;

    this.distributorService.addPostLink(formModel, this.postId).subscribe(post => {
      this.postEdit.links = post.links;
      this.linkNameDef.setValue('');
      this.linkUrl.setValue('');
    })
  }

}


<form
  [formGroup]="regionForm"
  (ngSubmit)="onSubmit()"
  novalidate
  #nform="ngForm"
>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row"></div>
            <div class="row">
              <div class="form-group col">
                <label for="title">Title</label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  formControlName="title"
                />
                <div
                  *ngIf="
                    title.invalid &&
                    (title.dirty || title.touched || nform.submitted)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="title.errors.required">Title is required.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-sm btn-warning"
              (click)="onCancel()"
            >
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-primary ml-3">
              <i class="fa fa-dot-circle-o"></i> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

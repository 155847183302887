import { CategoryEdit, CategoryList, RegionEdit } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { UserLanguage } from 'app/user/user-language.model';
import { environment } from '../../../../environments/environment';
import { UserService } from 'app/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as Moment from 'moment';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'regions-edit',
  templateUrl: './regions-edit.component.html',
  styleUrls: ['./regions-edit.component.scss']
})
export class RegionsEditComponent implements OnInit {
  regionId: number;
  editForm: FormGroup;
  modalRef: BsModalRef;
  languages: UserLanguage[];
  region: RegionEdit;

  apiUrl: string = environment.pageUrl;

  constructor(private fb: FormBuilder, private router: Router, private distributorsService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService, private route: ActivatedRoute,
    private userService: UserService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.route.params.subscribe(params => {
      this.regionId = parseInt(params['id']);
      this.distributorsService.getLanguages().subscribe(langs => {
        this.languages = langs
        this.distributorsService.getRegion(this.regionId).subscribe(
          (region: RegionEdit) => {
            this.region = region;
            this.setFormValues();
            this.spinnerService.hide();
          }
        );
      });
    });
    this.createForm();
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const regionData: RegionEdit = this.getData();
      this.distributorsService.updateRegion(regionData, this.regionId)
        .subscribe(
          (res) => {
            this.toastr.success('Region updated successfully');
            this.spinnerService.hide();
            this.router.navigate(['/distributors/region/list']);
          });
    }
  }

  onCancel() {
    this.location.back()
  }

  private setFormValues() {
    this.editForm.setValue({
      title: this.region.title as string,
    });
  }


  createForm() {
    this.editForm = this.fb.group({
      title: ['', Validators.required],
    });
  }

  private getData(): RegionEdit {

    const formModel = this.editForm.value;

    return {
      title: formModel.title as string,
    }
  }

  isValidForm() {
    return this.editForm.status === 'VALID';
  }

  get title() {
    return this.editForm.get('title');
  }

  getControl(formControl, name) {
    const originalControl = this.editForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }
}



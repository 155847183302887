import {EditCategory} from '../categories/edit-category/edit-category.model';
import {FeatureItem} from '../features/featureItem.model';

export class EditProduct {
  constructor(
    public id: number,
    public name: string,
    public info: string,
    public shortName: string,
    public catalogueNumber: string,
    public productGuid: string,
    public isActive: boolean,
    public isVirtualProduct: boolean,
    public isVisibleOnList: boolean,
    public inVisibleOnSearch: boolean,
    public assets: string,
    public description: string,
    public assemblyDescription: string,
    public additionalInfo: string,
    public additionalLedInfo: string,
    public promotedUntil: string,
    public categoryId: number,
    public instructional_video_link: string,
    public instructional_video_link_2: string,
    public preventProductCardGeneration: boolean,
    public categories: EditCategory[],
    public documents: EditProductDocument[],
    public productImages: EditProductImage[],
    public usageImages: EditProductImage[],
    public technicalDrawingImages: EditProductImage[],
    public featureItems: FeatureItem[],
    public specificationRecords: EditProductSpecification[],
    public childs: ChildEditProduct[],
    public attribs: Attribute[],
    public image: string,
    public historyRecords: any
    ) {
  }
}

export class ChildEditProduct {
  constructor(public id: number,
    public name: string,
    public info: string,
    public catalogueNumber: string,
    public bindingAngle: number) {
  }
}

export class EditProductImage {
  constructor(public id: number,
    public filename: string,
    public name: string,
    public directoryGuid: string,
    public thumbFilename: string,
    public galleryTypeId: number,
    public sortOrder: number) {
  }
}

export class EditProductDocument {
  constructor(public id: number,
              public name: string,
              public directoryGuid: string,
              public languageId: number,
              public thumbFilename: string) {
  }
}

export class EditProductSpecification {
  constructor(public id: number,
              public colorId: number,
              public color: string,
              public refNumber: string,
              public availableLengths: string) {
  }
}

export class AutocompleteProduct {
  constructor(public id: number,
    public name: string,
    public catalogueNumber: string,
    public categoryId: number) {
  }
}


export class Attribute {
  constructor(public id: number,
    public description: string) {
  }
}

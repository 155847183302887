import {FeatureItem} from './featureItem.model';

export class Feature {
  constructor(
    public id: number,
    public name: string,
    public categoryId: number,
    public items: FeatureItem[],
    public isRangeFeature: boolean,
    public rangeUnit: string,
    public isActive: boolean,
    public isVisibleOnSite: boolean,
    public sortOrder: number) {
  }
}

import { CategoryList, TraderNews } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { UserLanguage } from 'app/user/user-language.model';
import { environment } from '../../../../environments/environment';
import { UserService } from 'app/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as Moment from 'moment';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit {
  newsId: number;
  newsForm: FormGroup;
  modalRef: BsModalRef;
  languages: UserLanguage[];
  news: TraderNews;

  apiUrl: string = environment.pageUrl;

  constructor(private fb: FormBuilder, private router: Router, private distributorsService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService, private route: ActivatedRoute,
    private userService: UserService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.route.params.subscribe(params => {
      this.newsId = parseInt(params['id']);
      this.distributorsService.getLanguages().subscribe(langs => {
        this.languages = langs
        this.distributorsService.getNews(this.newsId).subscribe(
          (news: TraderNews) => {
            this.news = news;
            this.setFormValues();
            this.spinnerService.hide();
          }
        );
      });
    });
    this.createForm();
  }
  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const news: TraderNews = this.getData();
      this.distributorsService.updateNews(news, this.newsId)
        .subscribe(
          (res) => {
            this.toastr.success('News updated successfully');
            this.spinnerService.hide();
            this.router.navigate(['/distributors/news/list']);
          });
    }
  }

  onCancel() {
    this.location.back()
  }

  private setFormValues() {
    this.newsForm.setValue({
      is_active: this.news.is_active as boolean,
      title: this.news.title as string,
      description: this.news.description as string,
    });
  }


  createForm() {
    this.newsForm = this.fb.group({
      is_active: [''],
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  private getData(): any {

    const formModel = this.newsForm.value;

    return {
      is_active: formModel.is_active as boolean,
      title: formModel.title as string,
      description: formModel.description as string
    }
  }

  isValidForm() {
    return this.newsForm.status === 'VALID';
  }
 
  get is_active() {
    return this.newsForm.get('isActive');
  }


  get title() {
    return this.newsForm.get('title');
  }

  get description() {
    return this.newsForm.get('description');
  }

  get contentControl() {
    const formControl = new FormControl(this.newsForm.value.content as string, Validators.required);
    return this.getControl(formControl, 'content');
  }
  get summaryControl() {
    const formControl = new FormControl(this.newsForm.value.summary as string, Validators.required);
    return this.getControl(formControl, 'summary');
  }

  getControl(formControl, name) {
    const originalControl = this.newsForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }
}


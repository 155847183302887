
<div *ngIf="image">
  <img src="{{image.filename}}" class="main-image-thumbnail">
  <button class="btn btn-sm btn-danger" type="button" (click)="openDeleteModal(deleteTemplate, image.directoryGuid)">
    <i class="fa fa-trash"></i>
  </button>
</div>
<div id="fine-uploader-manual-trigger">
    <div id="{{divId}}"></div>
</div>

<ng-template #deleteTemplate>
  <div class="modal-header">
      <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
      <p>Do you want to delete this file?</p>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="declineDelete()">No</button>
      <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
  </div>
</ng-template>

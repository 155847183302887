import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {CountryService} from '../country.service';
import {AddCountry} from 'app/views/distributors/country/country-add/Addcountry.model';
import {Location} from '@angular/common';


@Component({
  selector: 'app-edit-country',
  templateUrl: './edit-country.component.html'
})
export class EditCountryComponent {

  countryForm: FormGroup;
  countryId: number;
  country: AddCountry;

  constructor(private fb: FormBuilder, private countryService: CountryService, private router: Router,
              private route: ActivatedRoute, private toastr: ToastrService, private location: Location) {
    this.createForm();
    this.route.params.subscribe(params => {
      this.countryId = parseInt(params['id']);
      countryService.getCountry(this.countryId).subscribe(
        country => {
          this.country = country;
          this.setFormValues();
        }
      );
    });
  }

  onSubmit() {
    const country = this.prepareEditCountry();
    this.countryService.updateCountry(this.countryId, country)
      .subscribe(
        () => {
          this.toastr.success('Country updated successfully');
          this.router.navigate(['./distributors/countries/list']);
        });
  }

  onCancel() {
    this.location.back();
  }

  createForm() {
    this.countryForm = this.fb.group({
      name: ['', Validators.required]
    });
  }

  prepareEditCountry(): AddCountry {
    const formModel = this.countryForm.value;
    const country: AddCountry = {
      name: formModel.name as string
    };
    return country;
  }

  isValidForm() {
    return this.countryForm.status === 'VALID';
  }

  get name() {
    return this.countryForm.get('name');
  }

  private setFormValues() {
    this.countryForm.setValue({
      name: this.country.name as string,
    })
  }
}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { UsersService } from '../users.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../user/user.model';
import { UserLanguage } from '../../../user/user-language.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html'
})
export class UsersEditComponent implements OnInit {

  userForm: FormGroup;
  userId: number;
  user: User;
  languages: UserLanguage[]

  constructor(private fb: FormBuilder, private usersService: UsersService, private router: Router,
    private toastr: ToastrService, private spinnerService: NgxSpinnerService,
    private location: Location, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userId = parseInt(params['id']);
      this.usersService.getLanguages().subscribe(langs => {
        this.languages = langs
        this.usersService.getUser(this.userId).subscribe(
          user => {
            this.user = user;
            this.setFormValues();
          }
        );
      });
    });
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      role: ['', Validators.required],
      phone: [''],
      notificationsEnabled: false
    });
  }

  onSubmit() {
    if (this.isValidForm()) {

      const formModel = this.userForm.value;

      const selectedLangsIds = formModel.langs
        .map((v, i) => v ? this.languages[i].id : null)
        .filter(v => v !== null);
      const user: any = {
        name: formModel.name as string,
        email: formModel.email as string,
        role: formModel.role as string,
        phone: formModel.phone as string,
        notificationsEnabled: formModel.notificationsEnabled as boolean,
        langs: selectedLangsIds
      };
      this.usersService.updateUser(this.userId, user)
        .subscribe(() => {
          this.toastr.success('User account updated successfully');
          this.router.navigate(['./users/list']);
        });
    }
  }

  onCancel() {
    this.location.back();
  }

  isValidForm() {
    return this.userForm.status === 'VALID';
  }

  get name() {
    return this.userForm.get('name');
  }

  get phone() {
    return this.userForm.get('phone');
  }

  get email() {
    return this.userForm.get('email');
  }

  get langs() {
    return this.userForm.get('langs');
  }

  private setFormValues() {
    this.userForm.setValue({
      name: this.user.name as string,
      phone: this.user.phone as string,
      email: this.user.email as string,
      role: this.user.role as string,
      notificationsEnabled: this.user.notificationsEnabled
    });

    const arr = new FormArray([]);
    for (const l of this.languages) {
      const control = new FormControl(this.user.availableLanguages.find(al => al.id === l.id));
      arr.push(control);
    }
    this.userForm.addControl('langs', arr);
  }

}

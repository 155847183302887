import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ErrorService } from "../../error.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { User } from "../../user/user.model";
import { UserService } from "../../user/user.service";
import { environment } from "../../../environments/environment";
import { ApiGetGalleryResponse,ApiGetGalleryItemsResponse } from "./api/api-gallery-response.model";
import { Gallery, GalleryImage } from "./gallery.model";
import { Observable } from "rxjs";

@Injectable()
export class GalleryService {
  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  public SLIDER_GALERY_ID = 1;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private errorService: ErrorService
  ) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getGalleryList(galleryId: number): Observable<GalleryImage[]> {
    return this.http
      .get<ApiGetGalleryResponse>(
        this.apiUrl +
          "/galleries/" +
          galleryId +
          "?langId=" +
          this.user.languageId
      )
      .pipe(
        map((response) => {
          const gallery = response.data;
          const galleryImages: GalleryImage[] = [];
          for (const image of gallery.items.data) {
            galleryImages.push({
              id: image.id,
              name: image.name,
              description: image.description,
              fileName: image.filename,
              thumbFilename: image.thumbFilename,
              targetUrl: image.targetUrl,
              type: image.type,
              sortOrder: Number(image.sortOrder),
              directoryGuid: image.directoryGuid,
              galleryId: image.gallery_id,
              galleryType: image.galleryType,
              vars: image.vars,
              thumbText: image.thumbText,
              createdOn: image.createdOn,
              modifiedOn: image.modifiedOn,
              isActive: image.is_active,
              publicationDate: image.publicationDate,
              title: image.title,
            });
          }
          return galleryImages;
        }),
        catchError(this.errorService.handleError<any>("getGalleryList"))
      );
  }

  getGalleryListSize(query?: string, showActive?: number): Observable<number> {
    let params = '?query=';
    if (query) {
      params += query;
    }

    if (Number(showActive) !== -1) {
      params += '&active=' + showActive;
    }

    params += '&langId=' + this.user.languageId;

    return this.http.get(this.apiUrl + '/galleriesListSize/1' + params).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('getArticleBasicListSize')),);
  }

  getOrderedBySortOrderGallery(galleryId: number,showActive: number, pageIndex?: number, pageSize?: number,
    query?: string, sortObject?: any): Observable<GalleryImage[]> {
    let params = 'langId=' + this.user.languageId;


    if (Number(showActive) !== -1) {
      params += '&active=' + showActive;
    }

    if (pageIndex) {
      params += '&page=' + pageIndex;
    } else {
      params += '&page=1';
    }

    if (pageSize) {
      params += '&perPage=' + pageSize;
    } else {
      params += '&perPage=30';
    }

    if (query) {
      params += '&query=' + query;
    }

    if (sortObject !== null && typeof sortObject !== 'undefined' && sortObject.active && sortObject.direction) {
      params += '&sortBy=' + sortObject.active + '&sortDirection=' + sortObject.direction;
    }

    return this.http.get<ApiGetGalleryItemsResponse>(this.apiUrl + '/galleriesList/'+galleryId+'?' + params).pipe(
      map((response) => {
          const gallery = response.data;
         const galleryImages: GalleryImage[] = [];

         for (const image in response.data) {
           const item = response.data[image];
           galleryImages.push({
             id: item.id,
             name: item.name,
             description: item.description,
             fileName: item.filename,
             thumbFilename: item.thumbFilename,
             targetUrl: item.targetUrl,
             type: item.type,
             sortOrder: Number(item.sortOrder),
             directoryGuid: item.directoryGuid,
             galleryId: item.gallery_id,
             galleryType: item.galleryType,
             vars: item.vars,
             thumbText: item.thumbText,
             createdOn: item.createdOn,
             modifiedOn: item.modifiedOn,
             isActive: item.is_active,
             publicationDate: item.publicationDate,
             title: item.title,
            });
          }
        return galleryImages;
      }),
      catchError(this.errorService.handleError<any>('getOrderedBySortOrderProducts')),);
  }
  getGallery(galleryId: number): Observable<Gallery> {
    return this.http
      .get<ApiGetGalleryResponse>(
        this.apiUrl +
          "/galleries/" +
          galleryId +
          "?langId=" +
          this.user.languageId
      )
      .pipe(
        map((response) => {
          const gallery = response.data;
          const galleryImages: GalleryImage[] = [];
          for (const image of gallery.items.data) {
            galleryImages.push({
              id: image.id,
              name: image.name,
              description: image.description,
              fileName: image.filename,
              thumbFilename: image.thumbFilename,
              targetUrl: image.targetUrl,
              type: image.type,
              sortOrder: Number(image.sortOrder),
              directoryGuid: image.directoryGuid,
              galleryId: image.gallery_id,
              galleryType: image.galleryType,
              vars: image.vars,
              thumbText: image.thumbText,
              createdOn: image.createdOn,
              modifiedOn: image.modifiedOn,
              isActive: image.is_active,
              publicationDate: image.publicationDate,
              title: image.title,
            });
          }
          return new Gallery(gallery.id, gallery.name, galleryImages);
        }),
        catchError(this.errorService.handleError<any>("getGallery"))
      );
  }

  public getGalleryItem(galleryItemId: number): Observable<any> {
    return this.http
      .get<any>(
        this.apiUrl +
          "/galleryItem/" +
          galleryItemId +
          "?langId=" +
          this.user.languageId
      )
      .pipe(
        map((response) => {
          const galleryItem = response.data;
          return {
            id: galleryItem.id,
            name: galleryItem.name,
            description: galleryItem.description,
            fileName: galleryItem.filename,
            thumbFilename: galleryItem.thumbFilename,
            targetUrl: galleryItem.targetUrl,
            type: galleryItem.type,
            sortOrder: Number(galleryItem.sortOrder),
            directoryGuid: galleryItem.directoryGuid,
            galleryId: galleryItem.gallery_id,
            galleryType: galleryItem.gallery_type,
            vars: galleryItem.vars,
            thumbText: galleryItem.thumbText,
            publicationDate: galleryItem.publicationDate,
            title: galleryItem.title,
            isActive: galleryItem.is_active,
          };
        }),
        catchError(this.errorService.handleError<any>("getGalleryItem"))
      );
  }
  galleryItemSortUp(galleryItemId: number) {
    return this.http
      .post(
        this.apiUrl + "/galleryItem/" + galleryItemId + "/sortUp",
        {},
        { headers: this.headers }
      )
      .pipe(
        catchError(this.errorService.handleError<any>("galleryItemSortUp"))
      );
  }
  galleryItemSortDown(galleryItemId: number) {
    return this.http
      .post(
        this.apiUrl + "/galleryItem/" + galleryItemId + "/sortDown",
        {},
        { headers: this.headers }
      )
      .pipe(
        catchError(this.errorService.handleError<any>("galleryItemSortDown"))
      );
  }

  deleteGalleryItem(galleryItemId: number) {
    return this.http
      .delete(this.apiUrl + "/galleryItem/" + galleryItemId + "?langId=" +
        this.user.languageId, {
        headers: this.headers,
      })
      .pipe(
        catchError(this.errorService.handleError<any>("deleteGalleryItem"))
      );
  }

  updateGalleryItem(galleryItemId: any, galleryImageData: any) {
    return this.http
      .put(
        this.apiUrl +
          "/galleryItem/" +
          galleryItemId +
          "?langId=" +
          this.user.languageId,
        JSON.stringify(galleryImageData),
        { headers: this.headers }
      )
      .pipe(
        catchError(this.errorService.handleError<any>("updateGalleryItem"))
      );
  }

  addGalleryItem(galleryImageData: any) {
    return this.http
      .post(
        this.apiUrl +
          "/galleries/Item" +
          "?langId=" +
          this.user.languageId,
        JSON.stringify(galleryImageData),
        { headers: this.headers }
      )
      .pipe(
        catchError(this.errorService.handleError<any>("addGalleryItem"))
      );
  }

  uploadthumbnail(id: number, file) {
    return this.http.post(this.apiUrl + "/uploader", file, {
      headers: this.headers,
    });
  }

  attachSliderHeroImage(
    fileObject: any,
    galleryId: number
  ): Observable<GalleryImage> {
    const body: any = {
      galleryId: galleryId,
      languageId: this.user.languageId,
      filename: fileObject.filename,
      filepath: fileObject.filepath,
    };
    return this.http
      .post<any>(
        this.apiUrl + "/galleryItem/attachGalleryHeroImage",
        JSON.stringify(body),
        { headers: this.headers }
      )
      .pipe(
        map(response => {
          const galleryItem = response.data;
          return {
            id: galleryItem.id,
            name: galleryItem.name,
            description: galleryItem.description,
            fileName: galleryItem.filename,
            thumbFilename: galleryItem.thumbFilename,
            targetUrl: galleryItem.targetUrl,
            type: galleryItem.type,
            sortOrder: Number(galleryItem.sortOrder),
            directoryGuid: galleryItem.directoryGuid,
            galleryId: galleryItem.gallery_id,
            galleryType: galleryItem.gallery_type,
            vars: galleryItem.vars,
            thumbText: galleryItem.thumbText,
            publicationDate: galleryItem.publicationDate,
            title: galleryItem.title,
            isActive: galleryItem.is_active,
          };
        }),
        catchError(this.errorService.handleError<any>("attachgalleryMainImage"))
      );
  }

  detachSliderHeroImage(galleryId: number): Observable<GalleryImage> {
    const body: any = {
      galleryId: galleryId,
      languageId: this.user.languageId,
    };
    return this.http
      .post<any>(
        this.apiUrl + "/galleryItem/detachGalleryHeroImage",
        JSON.stringify(body),
        { headers: this.headers }
      )
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError(this.errorService.handleError<any>("detachSliderHeroImage"))
      );
  }

  attachThumbnailImage(
    fileObject: any,
    galleryId: number
  ): Observable<GalleryImage> {
    const body: any = {
      galleryId: galleryId,
      languageId: this.user.languageId,
      filename: fileObject.filename,
      filepath: fileObject.filepath,
    };
    return this.http
      .post<any>(
        this.apiUrl + "/galleryItem/attachThumbnailImage",
        JSON.stringify(body),
        { headers: this.headers }
      )
      .pipe(
        map(response => {
          const galleryItem = response.data;
          return {
            id: galleryItem.id,
            name: galleryItem.name,
            description: galleryItem.description,
            fileName: galleryItem.filename,
            thumbFilename: galleryItem.thumbFilename,
            targetUrl: galleryItem.targetUrl,
            type: galleryItem.type,
            sortOrder: Number(galleryItem.sortOrder),
            directoryGuid: galleryItem.directoryGuid,
            galleryId: galleryItem.gallery_id,
            galleryType: galleryItem.gallery_type,
            vars: galleryItem.vars,
            thumbText: galleryItem.thumbText,
            publicationDate: galleryItem.publicationDate,
            title: galleryItem.title,
            isActive: galleryItem.is_active,
          };
        }),
        catchError(this.errorService.handleError<any>("attachThumbnailImage"))
      );
  }

  detachThumbnailImage(galleryId: number): Observable<GalleryImage> {
    const body: any = {
      galleryId: galleryId,
      languageId: this.user.languageId,
    };
    return this.http
      .post<any>(
        this.apiUrl + "/galleryItem/detachThumbnailImage",
        JSON.stringify(body),
        { headers: this.headers }
      )
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError(this.errorService.handleError<any>("detachThumbnailImage"))
      );
  }
}

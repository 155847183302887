import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {UserService} from './user/user.service';

@Injectable()
export class CanActivateViaStandardUserGuard implements CanActivate {

  constructor(private userService: UserService) {}

  canActivate() {
    return !this.userService.isRestricted();
  }
}

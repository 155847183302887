
import {merge as observableMerge, fromEvent as observableFromEvent, BehaviorSubject, Observable} from 'rxjs';

import {map, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {Router} from '@angular/router';

import {ListFeature} from './list-feature.model';
import {ProductService} from './../../api/product.service';
import {ToastrService} from 'ngx-toastr';
import {CategoryService} from '../../categories/category.service';
import {ListProductCategory} from '../../product-list/list-product-category.model';
import {UserService} from '../../../../user/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-product-feature-list',
  templateUrl: './feature-list.component.html',
  styles: [':host >>> .mat-cell:nth-child(2), .mat-header-cell:nth-child(2) {flex: 0 0 20%;}']
})
export class FeatureListComponent implements OnInit {

  products: ListFeature[];

  displayedColumns = ['id', 'name', 'categoryName', 'modifiedOn', 'createdOn', 'active','isVisibleOnSite' ,'sortOrder', 'actions'];
  dataSource: FeatureDataSource | null;
  featureDatabase: FeatureDatabase;
  modalRef: BsModalRef;
  featureId: number;
  orderedCategories: Observable<ListProductCategory[]>;
  categoryFilterActive = false;

  @ViewChild('filter', {static: true}) public filter: ElementRef;
  @ViewChild(MatSort, {static: true}) public sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;

  constructor(private toastr: ToastrService, private productService: ProductService, private categoryService: CategoryService,
              private router: Router, private modalService: BsModalService, private spinnerService: NgxSpinnerService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.featureDatabase = new FeatureDatabase(this.productService);
    this.dataSource = new FeatureDataSource(this.featureDatabase, this.paginator, this.sort);
    this.orderedCategories = this.categoryService.getOrderedListProductCategories();
    observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),)
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  onCategoryChange(categoryId) {
    this.dataSource.categoryFilter = parseInt(categoryId);
    this.categoryFilterActive = this.dataSource.categoryFilter !== -1;
  }

  redirectToEdit(categoryId) {
    this.router.navigate(['./products/features/edit/' + categoryId]);
  }

  openModal(template: TemplateRef<any>, pageId: number) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
    this.featureId = pageId;
  }

  confirm(): void {
    this.productService.deleteFeature(this.featureId)
      .subscribe(
        () => {
          this.toastr.success('Page removed successfully', '');
          this.deleteRowDataTable(this.featureId);
        });
    this.modalRef.hide();
  }

  decline(): void {
    this.featureId = null;
    this.modalRef.hide();
  }

  private deleteRowDataTable(itemId) {
    const dsData = this.featureDatabase.data;
    const itemIndex = dsData.findIndex(obj => obj['id'] === itemId);
    dsData.splice(itemIndex, 1)
    this.featureDatabase.dataChange.next(dsData);
  }

  sortUp(featureId) {
    this.spinnerService.show();
    this.productService.featureSortUp(featureId).subscribe(() => {
      this.productService.getFeatures().subscribe(
        features => {
          this.featureDatabase.updateFeatures(features);
          this.spinnerService.hide();
        });
    });
  }

  sortDown(featureId) {
    this.spinnerService.show();
    this.productService.featureSortDown(featureId).subscribe(() => {
      this.productService.getFeatures().subscribe(
        features => {
          this.featureDatabase.updateFeatures(features);
          this.spinnerService.hide();
        });
    });
  }

  get isRestrictedUser() {
    return this.userService.isRestricted();
  }
}

export class FeatureDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<ListFeature[]> = new BehaviorSubject<ListFeature[]>([]);

  updateFeatures(features) {
    this.dataChange.next(features);
  }

  get data(): ListFeature[] {
    return this.dataChange.value;
  }

  constructor(private productService: ProductService) {
    productService.getFeatures().subscribe(
      features => {
        this.dataChange.next(features);
      });
    ;
  }
}

export class FeatureDataSource extends DataSource<ListFeature> {
  _filterChange = new BehaviorSubject('');


  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  _categoryFilterChange = new BehaviorSubject(-1);

  get categoryFilter(): number {
    return this._categoryFilterChange.value || -1;
  }

  set categoryFilter(categoryId: number) {
    this._categoryFilterChange.next(categoryId);
  }

  constructor(private _featureDatabase: FeatureDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<ListFeature[]> {
    const displayDataChanges = [
      this._featureDatabase.dataChange,
      this._filterChange,
      this._sort.sortChange,
      this._categoryFilterChange,
      this._paginator.page
    ];

    return observableMerge(...displayDataChanges).pipe(map(() => {
      const data = this._featureDatabase.data.slice().filter((item: ListFeature) => {
        const searchStr = (item.name).toLowerCase();
        const searchResult = searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        const categoryResult = this.categoryFilter === -1 || item.categoryId === this.categoryFilter;
        return searchResult && categoryResult;
      });

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect() {
  }

  /** Returns a sorted copy of the database data. */
  sortData(data: ListFeature[]): ListFeature[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'id':
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case 'name':
          [propertyA, propertyB] = [a.name, b.name];
          break;
        case 'categoryName':
          [propertyA, propertyB] = [a.categoryName, b.categoryName];
          break;
        case 'sortOrder':
          [propertyA, propertyB] = [a.sortOrder, b.sortOrder];
          break;
        case 'createdOn':
          [propertyA, propertyB] = [a.createdOn, b.createdOn];
          break;
        case 'modifiedOn':
          [propertyA, propertyB] = [a.modifiedOn, b.modifiedOn];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

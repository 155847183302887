import { DistributorService } from './../distributor.service';
import { CategoryList, PostLink, TraderSlider } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { UserLanguage } from 'app/user/user-language.model';
import { environment } from '../../../../environments/environment';
import { UserService } from 'app/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'slider-edit',
  templateUrl: './slider-edit.component.html',
  styleUrls: ['./slider-edit.component.scss']
})
export class SliderEditComponent implements OnInit {
  sliderId: number;
  sliderEditForm: FormGroup;
  modalRef: BsModalRef;
  languages: UserLanguage[];
  sliderEdit: TraderSlider;
  apiUrl: string = environment.pageUrl;

  constructor(private fb: FormBuilder, private router: Router, private distributorService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService, private route: ActivatedRoute,
    private userService: UserService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.route.params.subscribe(params => {
      this.sliderId = parseInt(params['id']);
      this.distributorService.getLanguages().subscribe(langs => {
        this.languages = langs
        this.distributorService.getSlider(this.sliderId).subscribe(
          post => {
            this.sliderEdit = post;
            this.setFormValues();
            this.spinnerService.hide();
          }
        );
      });
    });
    this.getIntialData();
    this.createForm();
  }

  setMainImage(image: string) {
    if (this.sliderEdit) {
      // this.postEdit.mainImageSrc = image;
    }
  }

  getIntialData() {
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const data = this.getData();
      this.distributorService.updateSlider(data, this.sliderId)
        .subscribe(
          (res) => {
            this.toastr.success('Slider updated successfully');
            this.spinnerService.hide();
          });
    }
  }

  onCancel() {
    this.location.back()
  }

  private setFormValues() {
    this.sliderEditForm.setValue({
      is_active: this.sliderEdit.is_active as boolean,
      title: this.sliderEdit.title as string
    });
  }

  createForm() {
    this.sliderEditForm = this.fb.group({
      is_active: [''],
      title: ['', Validators.required],
    });
  }

  private getData(): any {
    const formModel = this.sliderEditForm.value;
    return {
      is_active: formModel.is_active as boolean,
      title: formModel.title as string,
    }
  }

  isValidForm() {
    return this.sliderEditForm.status === 'VALID';
  }

  get is_active() {
    return this.sliderEditForm.get('is_active');
  }

  get title() {
    return this.sliderEditForm.get('title');
  }

  getControl(formControl, name) {
    const originalControl = this.sliderEditForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }

  storePostLink(){
      const formModel = this.sliderEditForm.value;
      // this.distributorService.addPostLink(formModel, this.sliderId).subscribe(post => {
      //   this.sliderEdit.links = post.links;
      //   this.linkName.setValue('');
      //   this.linkUrl.setValue('');
      // })
  }

}


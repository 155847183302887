import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductRelatedFeature} from '../../product-related-feature.model';
import {ProductRelatedFeatureValue} from '../../product-related-feature-value';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html'
})
export class FeatureComponent implements OnInit {

  @Input()
  productRelatedFeature: ProductRelatedFeature;

  @Output() featureItemChanged: EventEmitter<ProductRelatedFeatureValue> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  featureItemChange(event) {
    const featureItemId = Number(event.target.value);
    const productFeatureValue = this.productRelatedFeature.items.find(item => item.featureItem.id === featureItemId);
    productFeatureValue.selected = event.target.checked;
    productFeatureValue.value = null;
    this.featureItemChanged.emit(productFeatureValue);
  }
}

<div class="animated fadeIn">
    <div class="example-container">
        <div class="example-header">
            <mat-form-field floatPlaceholder="never">
                <input matInput #filter placeholder="Filter categories">
            </mat-form-field>
            <div style="width:5%"></div>
            <div style="width:30%">
                <select class="form-control" (change)="onCategoryChange($event.target.value)">
                    <option value="">Select category</option>
                    <option *ngFor="let category of orderedCategories | async" [value]="category.id">{{category.name}}</option>
                </select>
            </div>
        </div>

        <mat-paginator #paginator [length]="exampleDatabase.data.length" [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>

        <mat-table #table [dataSource]="dataSource" >

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let row"> <span [innerHTML]="row.name"></span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef> Is active </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <span *ngIf="row && row.isActive == 1" class="badge badge-success">Active</span>
                    <span *ngIf="row && row.isActive == 0" class="badge badge-dark">Inactive</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sortOrder">
                <mat-header-cell *matHeaderCellDef
                                 [ngClass]="{'hidden-column': !selectedCategoryId}"
                                 style="flex: 0 0 5%"> sortOrder </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          [ngClass]="{'hidden-column': !selectedCategoryId}"
                          style="flex: 0 0 5%"> <span [innerHTML]="row.sortOrder"></span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index;">
                    <button class="btn btn-sm btn-primary mr-1" type="button" (click)="redirectToEdit(row.id)">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-sm btn-danger mr-1" type="button"
                            *ngIf="row.parentCategoryId != null"
                            (click)="openModal(template, row.id)">
                        <i class="fa fa-trash"></i>
                    </button>
                    <button class="btn btn-sm btn-primary mr-1" type="button"
                            *ngIf="selectedCategoryId != null && selectedCategoryId == row.parentCategoryId"
                            (click)="sortUp(row.id)">
                        <i class="fa fa-chevron-up"></i>
                    </button>
                    <button class="btn btn-sm btn-primary mr-1" type="button"
                            *ngIf="selectedCategoryId != null && selectedCategoryId == row.parentCategoryId"
                            (click)="sortDown(row.id)">
                        <i class="fa fa-chevron-down"></i>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center">
        <p>Do you want to confirm?</p>
        <button type="button" class="btn btn-default" (click)="confirm()">Yes</button>
        <button type="button" class="btn btn-primary" (click)="decline()">No</button>
    </div>
</ng-template>
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfiguratorService } from '../configurator.service';
import { Configurator } from '../Configurator.model';
import { DatePipe, Location } from '@angular/common';

@Component({
  selector: 'app-edit-configurator',
  templateUrl: './edit-configurator.component.html'
})
export class EditConfiguratorComponent {

  configuratorForm: FormGroup;
  configuratorId: number;
  configurator: Configurator;

  constructor(private fb: FormBuilder, private configuratorService: ConfiguratorService, private router: Router,
    private route: ActivatedRoute, private toastr: ToastrService,
    private datePipe: DatePipe, private location: Location) {
    this.createForm();
    this.route.params.subscribe(params => {
      this.configuratorId = parseInt(params['id']);
      configuratorService.getConfigurator(this.configuratorId).subscribe(
        configurator => {
          this.configurator = configurator;
          this.setFormValues();
        });
    });
  }

  onSubmit() {
    if (this.isValidForm()) {
      const configurator = this.prepareEditConfigurator();
      this.configuratorService.updateConfigurator(this.configuratorId, configurator)
        .subscribe(
          () => {
            this.toastr.success('Configurator created successfully');
            this.router.navigate(['./configurators/list']);
          });
    }
  }

  onCancel() {
    this.location.back();
  }

  createForm() {
    this.configuratorForm = this.fb.group({
      name: ['', Validators.required],
      ledTapeIndex: '',
      ledTapeIpRating: '',
      ledTapeInputVoltage: '',
      ledTapeLedColor: '',
      ledTapeLedOutput: '',
      ledTapeLengthOfLedTape: '',
      ledTapeLengthOfWireLead: '',
      ledTapeSummary: '',
      ledFixtureIndex: '',
      ledFixtureFinish: '',
      ledFixtureCover: '',
      ledFixtureIpRating: '',
      ledFixtureInputVoltage: '',
      ledFixtureLedColor: '',
      ledFixtureLedOutput: '',
      ledFixtureLength: '',
      ledFixtureMounting: '',
      ledFixtureWireLead: '',
      ledFixtureSummary: '',
    });
  }

  prepareEditConfigurator(): Configurator {
    const formModel = this.configuratorForm.value;

    const configurator: Configurator = {
      id: this.configuratorId,
      name: formModel.name as string,
      steps: {
        ledTapeIndex : formModel.ledTapeIndex as string,
        ledTapeIpRating : formModel.ledTapeIpRating as string,
        ledTapeInputVoltage : formModel.ledTapeInputVoltage as string,
        ledFixtureInputVoltage : formModel.ledFixtureInputVoltage as string,
        ledTapeLedColor : formModel.ledTapeLedColor as string,
        ledTapeLedOutput : formModel.ledTapeLedOutput as string,
        ledTapeLengthOfLedTape : formModel.ledTapeLengthOfLedTape as string,
        ledTapeLengthOfWireLead : formModel.ledTapeLengthOfWireLead as string,
        ledTapeSummary : formModel.ledTapeSummary as string,
        ledFixtureIndex : formModel.ledFixtureIndex as string,
        ledFixtureFinish : formModel.ledFixtureFinish as string,
        ledFixtureCover : formModel.ledFixtureCover as string,
        ledFixtureIpRating : formModel.ledFixtureIpRating as string,
        ledFixtureLedColor : formModel.ledFixtureLedColor as string,
        ledFixtureLedOutput : formModel.ledFixtureLedOutput as string,
        ledFixtureLength : formModel.ledFixtureLength as string,
        ledFixtureMounting: formModel.ledFixtureMounting as string,
        ledFixtureWireLead : formModel.ledFixtureWireLead as string,
        ledFixtureSummary : formModel.ledFixtureSummary as string,
      }
    };
    return configurator;
  }

  isValidForm() {
    return this.configuratorForm.status === 'VALID';
  }

  get name() {
    return this.configuratorForm.get('name');
  }

  get ledTapeIpRating() {
    return this.configuratorForm.get('ledTapeIpRating');
  }

  get ledTapeInputVoltage() {
    return this.configuratorForm.get('ledTapeInputVoltage');
  }

  get ledFixtureInputVoltage() {
    return this.configuratorForm.get('ledFixtureInputVoltage');
  }

  get ledTapeLedColor() {
    return this.configuratorForm.get('ledTapeLedColor');
  }
  get ledTapeLedOutput() {
    return this.configuratorForm.get('ledTapeLedOutput');
  }
  get ledTapeLengthOfLedTape() {
    return this.configuratorForm.get('ledTapeLengthOfLedTape');
  }
  get ledTapeLengthOfWireLead() {
    return this.configuratorForm.get('ledTapeLengthOfWireLead');
  }
  get ledTapeSummary() {
    return this.configuratorForm.get('ledTapeSummary');
  }
  get ledFixtureIndex() {
    return this.configuratorForm.get('ledFixtureIndex');
  }
  get ledFixtureFinish() {
    return this.configuratorForm.get('ledFixtureFinish');
  }
  get ledFixtureCover() {
    return this.configuratorForm.get('ledFixtureCover');
  }
  get ledFixtureIpRating() {
    return this.configuratorForm.get('ledFixtureIpRating');
  }
  get ledFixtureLedColor() {
    return this.configuratorForm.get('ledFixtureLedColor');
  }
  get ledFixtureLedOutput() {
    return this.configuratorForm.get('ledFixtureLedOutput');
  }
  get ledFixtureLength() {
    return this.configuratorForm.get('ledFixtureLength');
  }
  get ledFixtureMounting() {
    return this.configuratorForm.get('ledFixtureMounting');
  }

  get ledFixtureWireLead() {
    return this.configuratorForm.get('ledFixtureWireLead');
  }
  get ledFixtureSummary() {
    return this.configuratorForm.get('ledFixtureSummary');
  }

  private setFormValues() {
    this.configuratorForm.setValue({
      name: this.configurator.name as string,
      ledTapeIndex: this.configurator.steps.ledTapeIndex as string,
      ledTapeIpRating: this.configurator.steps.ledTapeIpRating as string,
      ledTapeInputVoltage: this.configurator.steps.ledTapeInputVoltage as string,
      ledFixtureInputVoltage: this.configurator.steps.ledFixtureInputVoltage as string,
      ledTapeLedColor: this.configurator.steps.ledTapeLedColor as string,
      ledTapeLedOutput: this.configurator.steps.ledTapeLedOutput as string,
      ledTapeLengthOfLedTape: this.configurator.steps.ledTapeLengthOfLedTape as string,
      ledTapeLengthOfWireLead: this.configurator.steps.ledTapeLengthOfWireLead as string,
      ledTapeSummary: this.configurator.steps.ledTapeSummary as string,
      ledFixtureIndex: this.configurator.steps.ledFixtureIndex as string,
      ledFixtureFinish: this.configurator.steps.ledFixtureFinish as string,
      ledFixtureCover: this.configurator.steps.ledFixtureCover as string,
      ledFixtureIpRating: this.configurator.steps.ledFixtureIpRating as string,
      ledFixtureLedColor: this.configurator.steps.ledFixtureLedColor as string,
      ledFixtureLedOutput: this.configurator.steps.ledFixtureLedOutput as string,
      ledFixtureLength: this.configurator.steps.ledFixtureLength as string,
      ledFixtureMounting: this.configurator.steps.ledFixtureMounting as string,
      ledFixtureWireLead: this.configurator.steps.ledFixtureWireLead as string,
      ledFixtureSummary: this.configurator.steps.ledFixtureSummary as string,
    })
  }
}


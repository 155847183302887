import { CategoryAdd, RegionEdit } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'regions-add',
  templateUrl: './regions-add.component.html',
  styleUrls: ['./regions-add.component.scss']
})
export class RegionsAddComponent implements OnInit {
  regionForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private distributorsService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService) {
    this.createForm();
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const regionAdd = this.getData();
      this.distributorsService.addRegion(regionAdd)
        .subscribe(
          (res) => {
            this.toastr.success('New region created successfully');
            this.spinnerService.hide();
            this.router.navigate(['./distributors/region/list/']);
          });
    }
  }

  onCancel() {
    this.location.back()
  }

  createForm() {
    this.regionForm = this.fb.group({
      title: ['', Validators.required]
    });
  }

  private getData(): RegionEdit {
    const formModel = this.regionForm.value;
    return {
      title: formModel.title as string,
    }
  }

  isValidForm() {
    return this.regionForm.status === 'VALID';
  }
  get title() {
    return this.regionForm.get('title');
  }
}


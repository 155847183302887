import { CategoryList, SubCategoryAdd } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'post-add',
  templateUrl: './post-add.component.html',
  styleUrls: ['./post-add.component.scss']
})
export class PostAddComponent implements OnInit {
  postAddForm: FormGroup;
  categories: CategoryList[];
  subCategories: CategoryList[];
  modalRef: BsModalRef;
  linkNames: any[];

  constructor(private fb: FormBuilder, private router: Router, private distributorService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.getIntialData();
  }

  getIntialData() {
    this.distributorService.getAllCategories().subscribe(
      categories => {
        this.categories = categories;
        this.spinnerService.hide();
      });
    this.distributorService.getAllSubCategories().subscribe(
        categories => {
          this.subCategories = categories;
          this.spinnerService.hide();
        });
    this.distributorService.getLinkNames().subscribe(
          links => {
            this.linkNames = links;
            this.spinnerService.hide();
          }
        );
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const data = this.getData();
      this.distributorService.addPost(data)
        .subscribe(
          res => {
            this.toastr.success('New post created successfully');
            this.spinnerService.hide();
            this.router.navigate(['./distributors/post/edit/' + res.id]);
          });
    }
  }

  onCancel() {
    this.location.back()
  }
  createForm() {
    this.postAddForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      categoryId: [''],
      mainCategoryId: ['', Validators.required],
      publicationDate: ['', Validators.required],
      linkName: ['', Validators.required],
    });
  }

  private getData(): any {
    const formModel = this.postAddForm.value;
    return {
      title: formModel.title as string,
      category_id: formModel.categoryId as number,
      main_category_id: formModel.mainCategoryId as number,
      description: formModel.description as string,
      publication_date: formModel.publicationDate as string,
      link_name: formModel.linkName as string,
    }
  }

  isValidForm() {
    return this.postAddForm.status === 'VALID';
  }

  get title() {
    return this.postAddForm.get('title');
  }

  get linkName() {
    return this.postAddForm.get('linkName');
  }
 
  get description() {
    return this.postAddForm.get('description');
  }

  get categoryId() {
    return this.postAddForm.get('categoryId');
  }

  get publicationDate() {
    return this.postAddForm.get('publicationDate');
  }

  get mainCategoryId() {
    return this.postAddForm.get('mainCategoryId');
  }

  get selectedMainCategoryId() {
    return this.postAddForm.get('mainCategoryId').value;
  }

  get contentControl() {
    const formControl = new FormControl(this.postAddForm.value.content as string, Validators.required);
    return this.getControl(formControl, 'content');
  }
  get summaryControl() {
    const formControl = new FormControl(this.postAddForm.value.summary as string, Validators.required);
    return this.getControl(formControl, 'summary');
  }

  getControl(formControl, name) {
    const originalControl = this.postAddForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }
}



export class ListCategory {
  constructor(public id: number,
    public name: string,
    public description: string,
    public mainCategoryId: number,
    public parentCategoryId: number,
    public modifiedOn: string,
    public createdOn: string,
    public isActive: boolean,
    public sortOrder: number) {
  }
}
import { CategoryList, SubCategoryAdd } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'sub-category-add',
  templateUrl: './sub-category-add.component.html',
  styleUrls: ['./sub-category-add.component.scss']
})
export class SubCategoryAddComponent implements OnInit {
  subCategoryAddForm: FormGroup;
  categories: CategoryList[];
  linkNames: any[];
  modalRef: BsModalRef;

  constructor(private fb: FormBuilder, private router: Router, private distributorService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.getIntialData();
  }

  getIntialData() {
    this.distributorService.getAllCategories().subscribe(
      categories => {
        this.categories = categories;
        this.spinnerService.hide();
      });
    this.distributorService.getLinkNames().subscribe(
      linkNames => {
        this.linkNames = linkNames;
        this.spinnerService.hide();
      });
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const subCategoryData: SubCategoryAdd = this.getData();
      this.distributorService.addSubCategory(subCategoryData)
        .subscribe(
          res => {
            this.toastr.success('New subcategory created successfully');
            this.spinnerService.hide();
            this.router.navigate(['./distributors/sub-categories/edit/' + res.id]);
          });
    }
  }

  onCancel() {
    this.location.back()
  }
  createForm() {
    this.subCategoryAddForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      order: [''],
      linkName: ['', Validators.required],
      parentId: ['', Validators.required],
    });
  }

  private getData(): any {
    const formModel = this.subCategoryAddForm.value;
    return {
      title: formModel.title as string,
      parent_id: formModel.parentId as number,
      order: formModel.order as number,
      link_name: formModel.linkName as string,
      description: formModel.description as string,
    }
  }

  isValidForm() {
    return this.subCategoryAddForm.status === 'VALID';
  }

  get title() {
    return this.subCategoryAddForm.get('title');
  }
  get order() {
    return this.subCategoryAddForm.get('order');
  }

  get linkName() {
    return this.subCategoryAddForm.get('linkName');
  }

  get description() {
    return this.subCategoryAddForm.get('description');
  }

  get parentId() {
    return this.subCategoryAddForm.get('parentId');
  }

  get contentControl() {
    const formControl = new FormControl(this.subCategoryAddForm.value.content as string, Validators.required);
    return this.getControl(formControl, 'content');
  }
  get summaryControl() {
    const formControl = new FormControl(this.subCategoryAddForm.value.summary as string, Validators.required);
    return this.getControl(formControl, 'summary');
  }

  getControl(formControl, name) {
    const originalControl = this.subCategoryAddForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if(originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }
}


import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {DistributorsRoutingModule} from './distributors-routing.module';
import {DistributorsListComponent} from './distributors-list/distributors-list.component';
import {DistributorService} from './distributor.service';
import {AddDistributorComponent} from './distributor-add/add-distributor.component';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CountryService} from './country/country.service';
import {EditDistributorComponent} from './distributor-edit/edit-distributor.component';
import {CountriesListComponent} from './country/country-list/countries-list.component';
import {AddCountryComponent} from './country/country-add/add-country.component';
import {EditCountryComponent} from './country/country-edit/edit-country.component';
import { SharedModule } from '../shared/shared.module';
import { CategoryAddComponent } from './category-add/category-add.component';
import { CategoryEditComponent } from './category-edit/category-edit.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { SubCategoryAddComponent } from './sub-category-add/sub-category-add.component';
import { SubCategoryEditComponent } from './sub-category-edit/sub-category-edit.component';
import { SubCategoryListComponent } from './sub-category-list/sub-category-list.component';
import {TinyMceModule} from 'angular-tinymce';
import { environment } from '../../../environments/environment';
import { SubCategoryMainImageComponent } from './sub-category-main-image/sub-category-main-image.component';
import { PostListComponent } from './post-list/post-list.component';
import { PostAddComponent } from './post-add/post-add.component';
import { PostEditComponent } from './post-edit/post-edit.component';
import { SubCategoryPipe } from './sub-category.pipe';
import { PostMainImageComponent } from './post-main-image/post-main-image.component';
import { PostAddFileComponent } from './post-add-file/post-add-file.component';
import { PostLinkTableComponent } from './post-link-table/post-link-table.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserAddComponent } from './user-add/user-add.component';
import { UserLogoImageComponent } from './user-logo-image/user-logo-image.component';
import { UserFileUploaderComponent } from './uploader/user-file-uploader/user-file-uploader.component';
import { FileListComponent } from './uploader/file-list/file-list.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsAddComponent } from './news-add/news-add.component';
import { NewsEditComponent } from './news-edit/news-edit.component';
import { SliderListComponent } from './slider-list/slider-list.component';
import { SliderEditComponent } from './slider-edit/slider-edit.component';
import { SliderAddItemComponent } from './slider-add-item/slider-add-item.component';
import { HelpersListComponent } from './helpers-list/helpers-list.component';
import { HelpersAddComponent } from './helpers-add/helpers-add.component';
import { HelpersEditComponent } from './helpers-edit/helpers-edit.component';
import { RegionsListComponent } from './regions-list/regions-list.component';
import { RegionsAddComponent } from './regions-add/regions-add.component';
import { RegionsEditComponent } from './regions-edit/regions-edit.component';
import { HelpersImageComponent } from './helpers-image/helpers-image.component';
import { RolesListComponent } from './roles-list/roles-list.component';
import { RolesEditComponent } from './roles-edit/roles-edit.component';
import { RolesAddComponent } from './roles-add/roles-add.component';

export function uploaderHandler(blobInfo, success, failure) {

  const token = JSON.parse(localStorage.getItem('current_klus_simpliq_user'));

  let xhr, formData;
  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', environment.apiUrl + '/uploader');
  xhr.setRequestHeader('Authorization', 'Bearer ' + token.access_token);
  xhr.onload = function () {
    let json;

    if (xhr.status !== 200) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location !== 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };
  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
}

@NgModule({
  imports: [
    DistributorsRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
   SharedModule,
   TinyMceModule.forRoot({
    baseURL: '/assets/tinymce',
    skin_url: '/assets/tinymce/skins/lightgray',
    theme_url: '/assets/tinymce/themes/modern/theme.min.js',
    tinymceScriptURL: '/assets/tinymce/tinymce.min.js',
    menubar: false,
    toolbar_items_size: 'small',
    autoresize_max_height: 450,
    autoresize_min_height: 300,
    image_advtab: true,
    images_upload_url: environment.apiUrl + '/uploader',
    images_upload_base_path: '',
    toolbar1: 'bold italic underline strikethrough |styleselect formatselect fontselect fontsizeselect | image code',
    toolbar2: 'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink ',
    plugins: ['autoresize link image lists table code'],
    verify_html: false,
    valid_children: '+body[style]',
    relative_urls: false,
    // images_upload_credentials: true,
    // images_reuse_filename: true,
    images_upload_handler: uploaderHandler,
  })
  ],
  declarations: [ DistributorsListComponent, AddDistributorComponent, EditDistributorComponent,
    CountriesListComponent, AddCountryComponent, EditCountryComponent, CategoryAddComponent, CategoryEditComponent, CategoryListComponent,
    SubCategoryAddComponent, SubCategoryEditComponent, SubCategoryListComponent, SubCategoryMainImageComponent, PostListComponent, PostAddComponent,
    PostEditComponent, SubCategoryPipe, PostMainImageComponent, PostAddFileComponent, PostLinkTableComponent, UserListComponent, UserEditComponent,
    UserAddComponent, UserLogoImageComponent, UserFileUploaderComponent, FileListComponent, NewsListComponent, NewsAddComponent, NewsEditComponent,
    SliderListComponent, SliderEditComponent, SliderAddItemComponent, HelpersListComponent, HelpersAddComponent, HelpersEditComponent, RegionsListComponent, RegionsAddComponent, RegionsEditComponent, HelpersImageComponent, RolesListComponent, RolesEditComponent, RolesAddComponent],
  entryComponents: [
  ],
  providers: [
    DistributorService, CountryService, DatePipe
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class DistributorsModule { }

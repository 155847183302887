
import {of as observableOf, Observable, BehaviorSubject} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

import {environment} from 'environments/environment';

import {ErrorService} from 'app/error.service';
import {UserLanguage} from 'app/user/user-language.model';
import {User} from 'app/user/user.model';
import {UserResponse} from 'app/user/user-response.model';

@Injectable()
export class UserService {

  ADMIN_ROLE = 'admin';
  STANDARD_USER_ROLE = 'standard';

  private apiUrl: string;
  private headers: HttpHeaders;
  private localStorageItemKey: string;
  private user: User;

  dataChange: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService,
              private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.localStorageItemKey = 'current_klus_simpliq_user_data';

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.dataChange.subscribe((user) => {
      if (user != null) {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      }
    });
  }

  public getCachedUser(): User {
    if (this.user == null) {
      const localStorageItem = localStorage.getItem(this.localStorageItemKey);
      this.user = JSON.parse(localStorageItem);
    }
    return this.user;
  }

  public getUser(): Observable<User> {
    const localStorageItem = localStorage.getItem(this.localStorageItemKey);
    if (localStorageItem != null) {
      return observableOf<User>(JSON.parse(localStorageItem));
    } else {
      return this.getCurrentUser();
    }
  }

  updateUserLanguage(userId: number, languageId: number) {
    return this.http
      .put(this.apiUrl + '/users/' + userId, JSON.stringify({languageId: languageId}), {headers: this.headers}).pipe(
      map((userResponse: UserResponse) => {
        const u: User =  {
          id: userResponse.id,
          name: userResponse.name,
          email: userResponse.email,
          isVerified: userResponse.isVerified,
          notificationsEnabled: userResponse.notificationsEnabled,
          languageId: userResponse.language_id,
          availableLanguages: userResponse.languages,
          role: userResponse.role,
          phone: userResponse.phone
        }
        localStorage.setItem(this.localStorageItemKey, JSON.stringify(u));
        this.user = u;
        return u;
      }),
      catchError(this.errorService.handleError<any>('updateUserLanguage')),);
  }



  public removeCurrentUser() {
    localStorage.removeItem(this.localStorageItemKey);
    this.user = null;
  }

  public isAdmin(): boolean {
    return this.getCachedUser().role === this.ADMIN_ROLE;
  }

  public isRestricted(): boolean {
    return this.getCachedUser().role !== this.ADMIN_ROLE && this.getCachedUser().role !== this.STANDARD_USER_ROLE;
  }

  private getCurrentUser(): Observable<User> {
    return this.http.get(this.apiUrl + '/user').pipe(
      map((userResponse: UserResponse) => {
        const user: User = {
          id: userResponse.id,
          name: userResponse.name,
          email: userResponse.email,
          isVerified: userResponse.isVerified,
          notificationsEnabled: userResponse.notificationsEnabled,
          languageId: userResponse.language_id,
          availableLanguages: userResponse.languages,
          role: userResponse.role,
          phone: userResponse.phone,
        };
        localStorage.setItem(this.localStorageItemKey, JSON.stringify(user));
        return user;
      }),
      catchError(this.errorService.handleError<any>('getCurrentUser')),);
  }

  selectedLang(): UserLanguage {
    return this.getCachedUser().availableLanguages.find(al => al.id === this.user.languageId)
  }

  private getUserLanguages(): Observable<UserLanguage[]> {
    return this.getUser().pipe(map(u => u.availableLanguages));
  }

  changePassword(password: string) {
    return this.http
      .put(this.apiUrl + '/user/changePassword', JSON.stringify({password: password}), {headers: this.headers}).pipe(
      catchError(this.errorService.handleError<any>('changePassword')));
  }
}


import {merge as observableMerge, fromEvent as observableFromEvent,  BehaviorSubject ,  Observable } from 'rxjs';

import {map, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Router } from '@angular/router';

import { ListProduct } from './list-product.model';
import { ProductService } from './../api/product.service';
import { ToastrService } from 'ngx-toastr';
import { ListProductCategory } from 'app/views/products/product-list/list-product-category.model';
import { CategoryService } from 'app/views/products/categories/category.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../user/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {

  products: ListProduct[];

  displayedColumns = ['id', 'name', 'catalogueNumber', 'category', 'modifiedOn', 'modifiedBy', 'createdOn', 'sortOrder', 'active', 'actions'];
  dataSource: ProductDataSource | null;
  productDatabase: ProductDatabase;
  dataSourceSize = 0;
  modalRef: BsModalRef;
  productId: number;
  orderedCategories: ListProductCategory[];
  selectedCategoryId: number;
  showActive = -1;
  productSortForm: FormGroup;
  show: boolean;
  query = '';

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  @ViewChild('filter', { static: true }) public filter: ElementRef;

  constructor(private productService: ProductService, private categoryService: CategoryService, private router: Router,
    private toastr: ToastrService, private modalService: BsModalService,
    private spinnerService: NgxSpinnerService, private fb: FormBuilder,
    private userService: UserService) {
  }

  ngOnInit() {
    this.spinnerService.show();
    this.getInitialData();
    observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(500),
      distinctUntilChanged(),)
      .subscribe(() => {
        this.query = this.filter.nativeElement.value;
        this.spinnerService.show();
        if (!this.dataSource) {
          return;
        }
        this.productService.getOrderedBySortOrderProducts(this.selectedCategoryId, this.showActive, false,
          this.paginator.pageIndex, this.paginator.pageSize, this.query, this.sort).subscribe(
            products => {
              this.productService.getProductBasicListSize(this.query, this.selectedCategoryId, this.showActive).subscribe(response => {
                this.dataSourceSize = response;
                this.productDatabase.updateProducts(products);
                this.spinnerService.hide();
              });
            });
      });

    this.productSortForm = this.fb.group({
      sortPosition: ['', [Validators.required, Validators.min(1)]]
    });
  }
  sortData(sortObject: any) {
    this.spinnerService.show();
    this.productService.getOrderedBySortOrderProducts(this.selectedCategoryId, this.showActive, false,
      this.paginator.pageIndex, this.paginator.pageSize, this.query, sortObject).subscribe(
        products => {
          this.productDatabase.updateProducts(products);
          this.spinnerService.hide();
        });
  }

  getInitialData() {
    this.categoryService.getOrderedListProductCategories().subscribe(
      categories => {
        this.orderedCategories = categories;
      });
    this.productDatabase = new ProductDatabase(this.productService, this.spinnerService);
    this.dataSource = new ProductDataSource(this.productDatabase, this.paginator, this.sort, this.spinnerService);

    this.productService.getOrderedBySortOrderProducts(this.selectedCategoryId, this.showActive, false,
      this.paginator.pageIndex, this.paginator.pageSize, this.query, { active: 'createdOn', direction: 'desc' }).subscribe(
        products => {
          this.productDatabase.updateProducts(products);
          this.spinnerService.hide();
        }
      );

    this.productService.getProductBasicListSize(this.query, this.selectedCategoryId, this.showActive).subscribe(response => {
      this.dataSourceSize = response;
    });

  }

  copyProduct(productId) {
    let newProductId = null;
    if (confirm('Do you really want to copy this product?')) {

      this.productService.copyProductRequest(productId).subscribe(response => {
        newProductId = response.newProductId;
        this.router.navigate(['./products/edit/' + newProductId], { fragment: 'top' });
        this.toastr.success('Product copied successfully');
      })
    }
  }

  sortUp(productId) {
    this.productService.updateProductSortUp(productId)
      .subscribe(
        response => {
        },
        error => {
        },
        () => {
          this.productService.getOrderedBySortOrderProducts(this.selectedCategoryId, this.showActive, true).subscribe(
            products => {
              this.productDatabase.updateProducts(products);
            });
          this.toastr.success('Product updated successfully');
        });
  }

  sortDown(productId) {
    this.productService.updateProductSortDown(productId)
      .subscribe(
        response => {
        },
        error => {
        },
        () => {
          this.productService.getOrderedBySortOrderProducts(this.selectedCategoryId, this.showActive, true).subscribe(
            products => {
              this.productDatabase.updateProducts(products);
            });
          this.toastr.success('Product updated successfully');
        });
  }

  toggleLang(productId, state: boolean) {
    this.spinnerService.show();
    if (state) {
      this.productService.updateProductEnableInCurrentLang(productId)
        .subscribe(
          () => {
            this.updateStatusAfterLangToggle(productId, state);
          },
          () => { },
          () => {
            this.spinnerService.hide();
          }
        );
    } else {
      this.productService.updateProductDisableInCurrentLang(productId)
        .subscribe(
          () => {
            this.updateStatusAfterLangToggle(productId, state);
          },
          () => { },
          () => {
            this.spinnerService.hide();
          }
        );
    }
  }

  private updateStatusAfterLangToggle(productId: number, state: boolean) {
    const foundIndex = this.productDatabase.dataChange.value.findIndex(x => x.id === productId);
    const product: ListProduct = this.productDatabase.dataChange.value[foundIndex];
    product.isEnabledInCurrentLanguage = state;
  }

  onCategoryChange(categoryId) {
    this.selectedCategoryId = categoryId;
    this.getData(1);
  }
  onActiveChange(active) {
    this.showActive = active;
    this.getData(1);
  }

  getData(page?: number, pageSize?: number) {
    this.spinnerService.show();

    this.productService.getProductBasicListSize(this.query, this.selectedCategoryId, this.showActive).subscribe(response => {
      this.dataSourceSize = response;
      this.productService.getOrderedBySortOrderProducts(this.selectedCategoryId, this.showActive, true, page,
        pageSize, this.query, this.sort).subscribe(
          products => {
            this.productDatabase.updateProducts(products);
            this.spinnerService.hide();
          });
    });
  }
  openModal(template: TemplateRef<any>, productId: number) {
    this.productId = productId;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }
  openSortModal(template: TemplateRef<any>, productId: number) {
    this.productSortForm.controls['sortPosition'].setValidators([Validators.required,
    Validators.min(1), Validators.max(this.paginator.length)]);
    this.productId = productId;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    this.productService.deleteProduct(this.productId)
      .subscribe(() => {
        this.toastr.success('Product removed successfuly', '');
        this.deleteRowDataTable(this.productId);
      });
    this.modalRef.hide();
  }

  private deleteRowDataTable(itemId) {
    const dsData = this.productDatabase.data;
    const itemIndex = dsData.findIndex(obj => obj['id'] === itemId);
    dsData.splice(itemIndex, 1)
    this.productDatabase.dataChange.next(dsData);
  }

  decline(): void {
    this.modalRef.hide();
  }

  isValidForm() {
    return this.productSortForm.status === 'VALID';
  }

  get sortPosition() {
    return this.productSortForm.get('sortPosition');
  }

  sortProduct() {
    if (this.isValidForm()) {
      const position: number = this.productSortForm.value.sortPosition;
      this.productService.updateProductSort(this.productId, position).subscribe(() => {
        this.onCategoryChange(this.selectedCategoryId);
        this.modalRef.hide();
      });
    }
  }

  changePage(event: PageEvent) {
    const page = event.pageIndex + 1;
    const pageSize = event.pageSize;
    this.getData(page, pageSize);
  }

  get isRestrictedUser() {
    return this.userService.isRestricted();
  }

  ngOnDestroy() {
    this.showActive = -1;
    this.query = '';
  }

}

export class ProductDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<ListProduct[]> = new BehaviorSubject<ListProduct[]>([]);

  get data(): ListProduct[] {
    return this.dataChange.value;
  }

  updateProducts(products) {
    this.dataChange.next(products);
  }

  constructor(private productService: ProductService, private spinnerService: NgxSpinnerService) {
    // productService.getOrderedBySortOrderProducts(null, -1).subscribe(
    //   products => {
    //     this.dataChange.next(products);
    //     this.spinnerService.hide();
    //   });
  }
}

export class ProductDataSource extends DataSource<ListProduct> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  _activeFilterChange = new BehaviorSubject('all');

  get activeFilter(): string {
    return this._activeFilterChange.value || 'all';
  }

  set activeFilter(active: string) {
    this._activeFilterChange.next(active);
  }

  prevFilterValue: string;
  prevActiveFilterValue: string;


  constructor(private _productDatabase: ProductDatabase, private _paginator: MatPaginator,
    private _sort: MatSort, private spinnerService: NgxSpinnerService) {
    super();
    this.prevFilterValue = this.filter.toLowerCase();
    this.prevActiveFilterValue = this.activeFilter;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<ListProduct[]> {
    const displayDataChanges = [
      this._productDatabase.dataChange,
      this._activeFilterChange,
      this._paginator.page,
      // this._paginator._changePageSize
    ];


    return observableMerge(...displayDataChanges).pipe(map(() => {
      const data = this._productDatabase.data;

      // const data = this._productDatabase.data;.slice().filter((item: ListProduct) => {
      //   const searchStr = (item.name + item.catalogueNumber).toLowerCase();
      //   const searchResult =  searchStr.indexOf(this.filter.toLowerCase()) !== -1;

      //   const activeResult = this.activeFilter === 'all'
      //     || (this.activeFilter === 'true' && item.isActive || this.activeFilter === 'false' && !item.isActive);
      //   return searchResult && activeResult;
      // });

      // if (this.filter.toLowerCase() !== this.prevFilterValue || this.activeFilter !== this.prevActiveFilterValue) {
      //   this._paginator.pageIndex = 0;
      // }
      // this.prevFilterValue = this.filter.toLowerCase();
      // this.prevActiveFilterValue = this.activeFilter;

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the page's slice of data.
      // const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData; //.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect() {
  }

  /** Returns a sorted copy of the database data. */
  sortData(data: ListProduct[]): ListProduct[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'id':
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case 'name':
          [propertyA, propertyB] = [a.name.toLocaleLowerCase(), b.name.toLocaleLowerCase()];
          break;
        case 'category':
          [propertyA, propertyB] = [a.category.toLocaleLowerCase(), b.category.toLocaleLowerCase()];
          break;
        case 'modifiedOn':
          [propertyA, propertyB] = [a.modifiedOn, b.modifiedOn];
          break;
        case 'createdOn':
          [propertyA, propertyB] = [a.createdOn, b.createdOn];
          break;
        case 'sortOrder':
          [propertyA, propertyB] = [a.sortOrder, b.sortOrder];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

export class ApiGetConfiguratorResponse {
  constructor(public data) {
  }
  public getData(): ApiConfigurator {
    return this.data;
  }
}
export class ApiConfigurator {
  constructor(public id: number,
    public name: string,
    public steps: ApiItems) {
  }
}


export class ApiItems {
  constructor(public data: ApiConfiguratorStep) {
  }
}
export class ApiConfiguratorStep {
  constructor(public id: number,
    public ledTapeIndex: string,
    public ledTapeIpRating: string,
    public ledTapeInputVoltage: string,
    public ledFixtureInputVoltage: string,
    public ledTapeLedColor: string,
    public ledTapeLedOutput: string,
    public ledTapeLengthOfLedTape: string,
    public ledTapeLengthOfWireLead: string,
    public ledTapeSummary: string,
    public ledFixtureIndex: string,
    public ledFixtureFinish: string,
    public ledFixtureCover: string,
    public ledFixtureIpRating: string,
    public ledFixtureLedColor: string,
    public ledFixtureLedOutput: string,
    public ledFixtureLength: string,
    public ledFixtureMounting: string,
    public ledFixtureWireLead: string,
    public ledFixtureSummary: string,
    public modifiedOn: string,
    public createdOn: string,
  ) {
  }
}

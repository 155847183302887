<form [formGroup]="configuratorForm" (ngSubmit)="onSubmit()" novalidate #dform="ngForm">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-lg-6">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name">
                <div *ngIf="name.invalid && (name.dirty || name.touched || dform.submitted)" class="alert alert-danger">
                  <div *ngIf="name.errors.required">
                    Name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            Translations
          </div>

          <div *ngIf="configuratorId == 1; else ledFixture">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="ledTapeIndex"><strong>Index</strong></label>
                  <angular-tinymce formControlName='ledTapeIndex' ngDefaultControl></angular-tinymce>
                </div>
                <div class="form-group col-lg-6">
                  <label for="ledTapeIpRating"><strong>IP rating</strong></label>
                  <angular-tinymce formControlName='ledTapeIpRating' ngDefaultControl></angular-tinymce>
                </div>
                <div class="form-group col-lg-6">
                  <label for="ledTapeInputVoltage"><strong>Input Voltage</strong></label>
                  <angular-tinymce formControlName='ledTapeInputVoltage' ngDefaultControl></angular-tinymce>
                </div>
                <div class="form-group col-lg-6 mt-4">
                  <label for="ledTapeLedColor"><strong>LED color</strong></label>
                  <angular-tinymce formControlName='ledTapeLedColor' ngDefaultControl></angular-tinymce>
                </div>
                <div class="form-group col-lg-6 mt-4">
                  <label for="ledTapeLedOutput"><strong>LED output</strong></label>
                  <angular-tinymce formControlName='ledTapeLedOutput' ngDefaultControl></angular-tinymce>
                </div>

                <div class="form-group col-lg-6 mt-4">
                  <label for="ledTapeLengthOfLedTape"><strong>length of LED tape</strong></label>
                  <angular-tinymce formControlName='ledTapeLengthOfLedTape' ngDefaultControl></angular-tinymce>
                </div>
                <div class="form-group col-lg-6 mt-4">
                  <label for="ledTapeLengthOfWireLead"><strong>length of wire lead</strong></label>
                  <angular-tinymce formControlName='ledTapeLengthOfWireLead' ngDefaultControl></angular-tinymce>
                </div>
                <div class="form-group col-lg-6 mt-4">
                  <label for="ledTapeSummary"><strong>summary</strong></label>
                  <angular-tinymce formControlName='ledTapeSummary' ngDefaultControl></angular-tinymce>
                </div>
              </div>
            </div>

          </div>
          <ng-template #ledFixture>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureIndex"><strong>Index</strong></label>
                      <angular-tinymce formControlName='ledFixtureIndex' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureFinish"><strong>Finish</strong></label>
                      <angular-tinymce formControlName='ledFixtureFinish' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureCover"><strong>Cover</strong></label>
                      <angular-tinymce formControlName='ledFixtureCover' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureIpRating"><strong>IP rating</strong></label>
                      <angular-tinymce formControlName='ledFixtureIpRating' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="ledFixtureInputVoltage"><strong>Input Voltage</strong></label>
                    <angular-tinymce formControlName='ledFixtureInputVoltage' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureLedColor"><strong>LED color</strong></label>
                      <angular-tinymce formControlName='ledFixtureLedColor' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureLedOutput"><strong>LED output</strong></label>
                      <angular-tinymce formControlName='ledFixtureLedOutput' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureLength"><strong>length</strong></label>
                      <angular-tinymce formControlName='ledFixtureLength' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureMounting"><strong>mounting</strong></label>
                      <angular-tinymce formControlName='ledFixtureMounting' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureWireLead"><strong>wire lead</strong></label>
                      <angular-tinymce formControlName='ledFixtureWireLead' ngDefaultControl></angular-tinymce>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="ledFixtureSummary"><strong>summary</strong></label>
                      <angular-tinymce formControlName='ledFixtureSummary' ngDefaultControl></angular-tinymce>
                  </div>
                </div>
              </div>
          </ng-template>
          <div class="card">

            <div class="card-footer">
              <button type="button" class="btn btn-sm btn-warning" (click)="onCancel()">
                <i class="fa fa-ban"></i> Cancel</button>
              <button type="submit" class="btn btn-sm btn-primary">
                <i class="fa fa-dot-circle-o"></i> Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {ListCategory} from '../../categories/category-list/list-category.model';
import {CategoryService} from '../../categories/category.service';
import {ProductService} from '../../api/product.service';
import {FeatureItem} from '../../features/featureItem.model';
import {ProductRelatedFeature} from './product-related-feature.model';
import {ToastrService} from 'ngx-toastr';
import {ProductRelatedFeatureValue} from './product-related-feature-value';
import {Helpers} from '../../../../helpers/helpers.service';
import {UserService} from '../../../../user/user.service';
@Component({
  selector: 'app-product-features',
  templateUrl: './product-features.component.html'
})
export class ProductFeaturesComponent implements OnInit {

  @Input()
  productId: number;

  @Input()
  productCategoryId: number;

  @Input()
  featureItems: FeatureItem[];

  @Input()
  categories: Observable<ListCategory>;

  @Output() featuresChanged: EventEmitter<FeatureItem[]> = new EventEmitter();

  productRelatedFeatures: Observable<ProductRelatedFeature[]>;

  helpers : any;

  constructor(private categoryService: CategoryService, private productService: ProductService, private toastr: ToastrService, private userService: UserService) {
    this.helpers = new Helpers();
   }

  ngOnInit(): void {

    this.productRelatedFeatures = this.productService
      .getProductRelatedFeatures(this.featureItems, this.categoryService.getRelatedFeatures(this.productCategoryId, true));
  }

  onFeatureItemChange(event: ProductRelatedFeatureValue) {
    const featureItemId = event.featureItem.id;

    if (event.selected) {
      this.productService.addFeatureItemToProduct(this.productId, featureItemId, event.value).subscribe(product => {
          this.toastr.success('Feature value changed successfully', '');
          this.featuresChanged.emit(product.data.featureItems.data)
          this.productService.getProduct(this.productId).subscribe(
            product => {
              this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
            });
        });
    } else {
      this.productService.removeFeatureItemFromProduct(this.productId, featureItemId, null).subscribe(product => {
          this.toastr.success('Feature value changed successfully', '');
          this.featuresChanged.emit(product.data.featureItems.data);
          this.productService.getProduct(this.productId).subscribe(
            product => {
              this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
            });
        });
    }
  }

}

export class Gallery {
  constructor(public id: number, public name: string, public images: GalleryImage[]) {
  }
}

export class GalleryImage {
  constructor(
    public id: number,
    public galleryId: number,
    public name: string,
    public description: string,
    public fileName: string,
    public thumbFilename: string,
    public targetUrl: string,
    public type: string,
    public sortOrder: number,
    public directoryGuid: string,
    public galleryType:string,
    public vars:string,
    public thumbText:string,
    public createdOn:string,
    public modifiedOn:string,
    public publicationDate:string,
    public isActive:boolean,
    public title:string,
    ) {
  }
}

export class GalleryImageEdit {
  constructor(
    public id: number,
    public galleryId: number,
    public isActive: boolean,
    public name: string,
    public description: string,
    public fileName: string,
    public thumbFilename: string,
    public targetUrl: string,
    public type: string,
    public sortOrder: number,
    public directoryGuid: string,
    public galleryType:string,
    public vars:string,
    public thumbText:string,
    public title:string,
    public publicationDate: string,
    ) {
  }
}

export class GalleryList {
  constructor(
    public id: number,
    public galleryId: number,
    public isActive: boolean,
    public name: string,
    public description: string,
    public fileName: string,
    public thumbFilename: string,
    public targetUrl: string,
    public type: string,
    public sortOrder: number,
    public directoryGuid: string,
    public galleryType:string,
    public vars:string,
    public thumbText:string,
    public title:string,
    public publicationDate: string,
    public createdOn:string,
    public modifiedOn:string,
  ) { }
}

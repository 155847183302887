<div class="card">
  <div class="card-header">
    {{productRelatedFeature.feature.name}}&nbsp;[{{productRelatedFeature.feature.rangeUnit}}]
    <label class="switch switch-3d switch-primary float-right">
      <input type="checkbox" class="switch-input" value="true"
             (click)="changeTriggered($event)"
             [checked]="selectedItem && selectedItem.selected">
      <span class="switch-label"></span>
      <span class="switch-handle"></span>
    </label>
  </div>

<div class="card-body">
  <div *ngIf="selectedItem" class="mb-2"><b>Product value:&nbsp;</b>{{selectedItem.value}}&nbsp;{{productRelatedFeature.feature.rangeUnit}}</div>
  <div *ngIf="selectedItem" class="mb-2"><b>Filter ranges</b></div>
  <table class="table">
    <tr>
      <th>Label</th>
      <th>min</th>
      <th>max</th>
      <th></th>
    </tr>
    <tr *ngFor="let pri of productRelatedFeature.items ">
      <td>{{pri.featureItem.name}}</td>
      <td>{{pri.featureItem.minIntValue}}</td>
      <td>{{pri.featureItem.maxIntValue}}</td>
      <td class="text-success"><i *ngIf="selectedItem && selectedItem.featureItem.id == pri.featureItem.id" class="fa fa-check"></i></td>
    </tr>
  </table>
</div>
</div>


<ng-template #featureItemValueTemplate>
  <form [formGroup]="featureItemValueForm" (ngSubmit)="setFeatureItemValue()" novalidate #valueForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title">Enter feature value</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
          <input  type="number" align="right" formControlName="featureValue" id="featureValue" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" step="0.01" required >
          <label for="featureValue">{{productRelatedFeature.feature.rangeUnit}}</label>
          <div *ngIf="featureValue.invalid && (valueForm.submitted || featureValue.dirty || featureValue.touched)" class="alert alert-danger">
            <div *ngIf="featureValue.errors.required">
              Value is required.
            </div>
            <div *ngIf="featureValue.errors.min">
              Value is to low.
            </div>
            <div *ngIf="featureValue.errors.min">
              Value is to height.
            </div>
          </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="modalRef.hide()">Cancel</button>
      <button type="submit" class="btn btn-primary">Save</button>
    </div>
  </form>
</ng-template>
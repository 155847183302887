<form [formGroup]="countryForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-lg-6">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name">
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                  <div *ngIf="name.errors.required">
                    Name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">

          <div class="card-footer">
            <button type="button" class="btn btn-sm btn-warning" (click)="onCancel()">
              <i class="fa fa-ban"></i> Cancel</button>
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="!isValidForm()">
              <i class="fa fa-dot-circle-o"></i> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div id="fine-uploader-manual-trigger">
  <div class="col mb-2" *ngIf="fileType==3" (ngInit)="reloadLastModifieds()">
    Last modified by&nbsp;:&nbsp;<span id="lastInCouplersAndAuto"></span>
    <br>
    <br>
    <span class="mr-2">Auto generated product card </span>
    <label class="switch switch-lg switch-3d switch-primary">
      <input type="checkbox" class="switch-input" value="true" [checked]="productCard"
        (change)="preventProductCardGenerationChange($event)">
      <span class="switch-label"></span>
      <span class="switch-handle"></span>
    </label>
  </div>
  <div *ngIf="fileType==1" (ngInit)="reloadLastModifieds()">
    Last modified by&nbsp;:&nbsp;<span id="lastInAlbumProducts"></span>
  </div>
  <div *ngIf="fileType==2" (ngInit)="reloadLastModifieds()">
    Last modified by&nbsp;:&nbsp;<span id="lastInAlbumUsages"></span>
  </div>
  <div *ngIf="fileType==3" (ngInit)="reloadLastModifieds()">
    Last modified by&nbsp;:&nbsp;<span id="lastInAlbumDocuments"></span>
  </div>
  <div id="{{divId}}"></div>
</div>

<div class="mt-1">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12">
        <div class="mb-1">
          <div style="width:100%">
            <input class="form-control" #filter placeholder="Filter" type="text" (focus)="show($event)">
          </div>
        </div>
        <div [hidden]="!canSee" style="border:1px solid rgba(0,0,0,.12);">
          <div class="row align-items-center">
            <div class="col-10">
              <mat-paginator #paginator [length]="database.data.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </div>
            <div class="col-2 text-right">
              <button (click)="hide($event)" class="btn btn-danger">X</button>
            </div>
          </div>

          <mat-table #table [dataSource]="dataSource" matSort matSortActive="filetime" matSortDirection="desc">

            <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

            <ng-container matColumnDef="filename">
              <mat-header-cell *matHeaderCellDef mat-sort-header> File name </mat-header-cell>
              <mat-cell *matCellDef="let row"> <span [innerHTML]="row.filename"></span> </mat-cell>
            </ng-container>
            <ng-container matColumnDef="filetime">
              <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 20%"> Date </mat-header-cell>
              <mat-cell *matCellDef="let row" style="flex: 0 0 20%"> <span [innerHTML]="row.filetime"></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="filepath">
              <mat-header-cell *matHeaderCellDef mat-sort-header>File path </mat-header-cell>
              <mat-cell *matCellDef="let row"> <span [innerHTML]="row.filepath"></span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef class="text-center" style="flex: 0 0 15%"></mat-header-cell>
              <mat-cell *matCellDef="let row" class="text-center" style="flex: 0 0 20%">
                <button type="button" class="btn btn-success" (click)="attachToProduct(row)">
                  <i class="fa fa-arrow-up" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-primary" (click)="copyTextToClipboard(row.filepath)">
                  <i class="fa fa-clone" aria-hidden="true"></i>
                </button>
                <a target="_blank" href="{{row.filepath}}" class="btn btn-primary" download="filename">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
                <button type="button" class="btn btn-danger" (click)="remove(row.filename)">
                  <i class="fa fa-trash"></i>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <!-- <app-file-uploader (onAllCompleted)="onFileUploaded()"></app-file-uploader> -->
      </div>
    </div>
  </div>

  <ng-template #template>
    <div class="modal-body text-center">
      <p>Do you want to confirm?</p>
      <button type="button" class="btn btn-default" (click)="confirm()">Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline()">No</button>
    </div>
  </ng-template>

</div>


<br />
<br />
<div *ngIf="images">
  <div class="table-responsive">
    <table class="table table-bordered" *ngIf="images.length > 0">
      <thead>
        <tr>
          <th style="width: 5%">Id</th>
          <th style="width: 35%">Name</th>
          <th style="width: 20%">Image</th>
          <th style="width: 40%">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let image of images; let i = index">
          <td>{{image.id}}</td>
          <td>{{image.name}}</td>
          <td>
            <img src="{{image.thumbFilename}}" class="image-thumbnail">
          </td>
          <td>
            <a class="btn btn-sm btn-primary mr-1" href="{{image.filename}}" target="_blank">
              <i class="fa fa-search"></i>
            </a>
            <button class="btn btn-sm btn-primary mr-1" type="button"
              (click)="openEditModal(editTemplate, image.id, image.name)">
              <i class="fa fa-pencil"></i>
            </button>
            <button class="btn btn-sm btn-danger mr-1" type="button"
              (click)="openDeleteModal(deleteTemplate, image.directoryGuid, image.name)">
              <i class="fa fa-trash"></i>
            </button>
            <button *ngIf="i != 0" class="btn btn-sm btn-primary mr-1" type="button" (click)="moveBefore(i)">
              <i class="fa fa-chevron-up"></i>
            </button>
            <button *ngIf="i < images.length -1 " class="btn btn-sm btn-primary mr-1" type="button" (click)="moveAfter(i)">
              <i class="fa fa-chevron-down"></i>
            </button>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #deleteTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <p>Do you want to delete this file?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineDelete()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
  </div>
</ng-template>

<ng-template #editTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="editFileForm">
      <div class="form-group">
        <label>File name</label>
        <input type="text" class="form-control" formControlName="name">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineEdit()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmEdit()">Yes</button>
  </div>
</ng-template>
<div class="animated fadeIn">
  <div class="example-container">
    <div class="example-header">
      <mat-form-field floatPlaceholder="never">
        <input matInput #filter placeholder="Filter">
      </mat-form-field>
      <div style="width:5%"></div>
    </div>

    <mat-paginator #paginator [length]="database.data.length" [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <mat-table #table [dataSource]="dataSource" matSort>

      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef  mat-sort-header> Title </mat-header-cell>
        <mat-cell *matCellDef="let row"> <span [innerHTML]="row.title"></span> </mat-cell>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef  mat-sort-header> Created At </mat-header-cell>
        <mat-cell *matCellDef="let row"> <span> {{row.created_at | date : 'yyyy-MM-dd HH:mm'}} </span> </mat-cell>
      </ng-container>
      <ng-container matColumnDef="updated_at">
        <mat-header-cell *matHeaderCellDef  mat-sort-header> Updated At </mat-header-cell>
        <mat-cell *matCellDef="let row"> <span> {{ row.updated_at | date : 'yyyy-MM-dd HH:mm' }} </span> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="btn btn-sm btn-primary mr-1" type="button" (click)="redirectToRole(row.id)">
            <i class="fa fa-pencil"></i>
          </button>
          <button *ngIf="isAdmin()" class="btn btn-sm btn-danger" type="button" (click)="openModal(template, row.id)">
            <i class="fa fa-trash"></i>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>Do you want to confirm?</p>
    <button type="button" class="btn btn-default" (click)="confirm()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="decline()">No</button>
  </div>
</ng-template>

<form [formGroup]="featureForm" (ngSubmit)="onSubmit()" novalidate #fForm="ngForm">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="name">Nazwa</label>
              <input type="text" class="form-control" id="name" formControlName="name">
              <div *ngIf="name.invalid && (fForm.submitted || name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="category-id">Category</label>
              <select class="form-control" formControlName="categoryId" id="category-id">
                <option value="">None</option>
                <option *ngFor="let category of categories" [value]="category.id">{{category.name}}</option>
              </select>
              <div *ngIf="categoryId.invalid && (fForm.submitted || categoryId.dirty || categoryId.touched)" class="alert alert-danger">
                <div *ngIf="categoryId.errors.required">
                  Category is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <input type="checkbox" id="isRangeFeature" name="isRangeFeature" formControlName="isRangeFeature"/> Range value feature
            </div>
            <div class="form-group" *ngIf="featureForm.get('isRangeFeature').value">
              <label for="rangeUnit">Unit label</label>
              <input type="text" class="form-control" id="rangeUnit" formControlName="rangeUnit">
              <div *ngIf="rangeUnit.invalid && (fForm.submitted || rangeUnit.dirty || rangeUnit.touched)" class="alert alert-danger">
                <div *ngIf="rangeUnit.errors.required">
                  Unit label is required.
                </div>
              </div>
            </div>

            <div class="form-group">
              <input type="checkbox" id="isActive" name="isActive" formControlName="isActive"/> Active
            </div>
            <div class="form-group">
              <input type="checkbox" id="isVisibleOnSite" name="isVisibleOnSite" formControlName="isVisibleOnSite"/> Visible on site
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-footer">

            <button type="reset" class="btn btn-sm btn-warning mr-1" (click)="onCancel()">
              <i class="fa fa-ban"></i> Reset</button>
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="submitting">
              <i class="fa fa-dot-circle-o"></i> Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


import {AfterViewInit, Component, Input} from '@angular/core';
import {FineUploader} from 'fine-uploader';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'edit-product-main-image',
  templateUrl: './edit-product-main-image.component.html',
  styleUrls: ['./edit-product-main-image.component.scss']
})
export class EditProductMainImageComponent implements AfterViewInit {

  @Input()
  divId: string;

  @Input()
  fileType: number;

  @Input()
  image: string;

  @Input()
  productId: number;

  @Input()
  languageId: number;

  uploader: FineUploader;
  imagesAllowedExtensions = ['jpeg', 'jpg', 'gif', 'png'];

  constructor() {
  }

  ngAfterViewInit() {
    this.getInitialData();
  }

  getInitialData() {
    this.uploader = this.configureUploader(this.divId, this);
  }

  configureUploader(elementId: string, that: any) {
    return new FineUploader({
      element: document.getElementById(elementId),
      autoUpload: true,
      multiple: false,
      request: {
        endpoint: environment.apiUrl + '/uploader',
        params: {
          productId: that.productId,
          languageId: that.languageId,
          fileType: that.fileType
        }
      },
      validation: {
        allowedExtensions: that.imagesAllowedExtensions
      },
      callbacks: {
        onSubmit: function (options) {
          that.spinnerService.show();
        },
        onAllComplete: function (succeeded, failed) {
          that.productService.getProduct(that.productId).subscribe(
            product => {
              switch (parseInt(that.fileType)) {
                case 5:
                  that.image = product.image;
                  break;
                default:
                  break;
              }
              this.reset();
              that.spinnerService.hide();
            });
        }
      }
    });
  }
}
<div id="qq-template" hidden>
  <div class="qq-uploader-selector qq-uploader" qq-drop-area-text="Drop files here">
    <div class="qq-total-progress-bar-container-selector qq-total-progress-bar-container">
      <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
           class="qq-total-progress-bar-selector qq-progress-bar qq-total-progress-bar"></div>
    </div>
    <div class="qq-upload-drop-area-selector qq-upload-drop-area" qq-hide-dropzone>
      <span class="qq-upload-drop-area-text-selector"></span>
    </div>
    <div class="buttons">
      <div class="qq-upload-button-selector qq-upload-button">
        <div>Select file</div>
      </div>
      <!-- <button type="button" id="trigger-upload" class="btn btn-primary">
                  <i class="icon-upload icon-white"></i> Upload
              </button> -->
    </div>
    <span class="qq-drop-processing-selector qq-drop-processing">
            <span>Processing dropped files...</span>
            <span class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"></span>
        </span>
    <ul class="qq-upload-list-selector qq-upload-list" aria-live="polite" aria-relevant="additions removals">
      <li>
        <div class="qq-progress-bar-container-selector">
          <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
               class="qq-progress-bar-selector qq-progress-bar"></div>
        </div>
        <span class="qq-upload-spinner-selector qq-upload-spinner"></span>
        <img class="qq-thumbnail-selector" qq-max-size="100" qq-server-scale>
        <span class="qq-upload-file-selector qq-upload-file"></span>
        <span class="qq-edit-filename-icon-selector qq-edit-filename-icon" aria-label="Edit filename"></span>
        <input class="qq-edit-filename-selector qq-edit-filename" tabindex="0" type="text">
        <span class="qq-upload-size-selector qq-upload-size"></span>
        <span role="status" class="qq-upload-status-text-selector qq-upload-status-text"></span>
      </li>
    </ul>
  </div>
</div>

<form [formGroup]="featureForm" (ngSubmit)="onSubmit()" novalidate #fForm="ngForm">
<div class="animated fadeIn">


  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col mb-2">
          <span class="mr-2">Active </span>
          <label class="switch switch-lg switch-3d switch-primary">
            <input type="checkbox" class="switch-input" formControlName="isActive">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
          &nbsp;
          <span class="mr-2">Visible on site </span>
          <label class="switch switch-lg switch-3d switch-primary">
            <input type="checkbox" class="switch-input" formControlName="isVisibleOnSite">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-lg-6">
              <label for="name">Nazwa</label>
              <input type="text" class="form-control" id="name" formControlName="name">
              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6">
              <label for="categoryId">Category</label>
              <select class="form-control" formControlName="categoryId" id="categoryId" disabled>
                <option value="">None</option>
                <option *ngFor="let category of categories | async" [value]="category.id">{{category.name}}</option>
              </select>
              <div *ngIf="categoryId.invalid && (categoryId.dirty || categoryId.touched)" class="alert alert-danger">
                <div *ngIf="categoryId.errors.required">
                  Category is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6">
              <input type="checkbox" id="isRangeFeature" name="isRangeFeature" formControlName="isRangeFeature" /> Range
              value feature
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-6" *ngIf="featureForm.get('isRangeFeature').value">
              <label for="rangeUnit">Unit label</label>
              <input type="text" class="form-control" id="rangeUnit" formControlName="rangeUnit">
              <div *ngIf="rangeUnit.invalid && (fForm.submitted || rangeUnit.dirty || rangeUnit.touched)" class="alert alert-danger">
                <div *ngIf="rangeUnit.errors.required">
                  Unit label is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-8 mt-2" *ngIf="feature && feature.items">


              <label>Feature items</label>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 40%">Name</th>
                      <th style="width: 10%" *ngIf="isRangeFeature">Min value</th>
                      <th style="width: 10%" *ngIf="isRangeFeature">Max value</th>
                      <th style="width: 30%">Action</th>
                      <th style="width: 20%">Active in configurator</th>
                      <th style="width: 20%">Active on site</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of feature.items">
                      <td>{{item.name}}</td>
                      <td *ngIf="isRangeFeature">{{item.minIntValue}}</td>
                      <td *ngIf="isRangeFeature">{{item.maxIntValue}}</td>
                      <td>
                        <button type="button" class="btn btn-sm btn-primary" (click)="openEditItemModal(editItemTemplate, item)">
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button type="button" class="btn btn-sm btn-danger" (click)="opelDeleteItemModal(deleteItemTemplate, item)">
                          <i class="fa fa-trash"></i>
                        </button>
                        <button class="btn btn-sm btn-secondary" type="button" (click)="sortUp(item.id)">
                          <i class="fa fa-chevron-up"></i>
                        </button>
                        <button class="btn btn-sm btn-secondary" type="button" (click)="sortDown(item.id)">
                          <i class="fa fa-chevron-down"></i>
                        </button>
                      </td>
                      <td>
                        <label class="switch switch-lg switch-3d switch-primary">
                          <input type="checkbox" class="switch-input" [checked]="item.isVisibleOnConfigurator" (click)="clickOnIsVisibleOnConfigurator($event.target.checked,item.id)">
                          <span class="switch-label"></span>
                          <span class="switch-handle"></span>
                        </label>
                      </td>
                      <td>
                        <label class="switch switch-lg switch-3d switch-primary">
                          <input type="checkbox" class="switch-input" [checked]="item.isVisibleOnSite" (click)="clickOnIsVisibleOnSite($event.target.checked,item.id)">
                          <span class="switch-label"></span>
                          <span class="switch-handle"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input type="text" class="form-control" formControlName="featureItem" />
                      </td>
                      <td *ngIf="isRangeFeature">
                        <input type="text" class="form-control" formControlName="featureItemMinValue" />
                      </td>
                      <td *ngIf="isRangeFeature">
                        <input type="text" class="form-control" formControlName="featureItemMaxValue" />
                      </td>
                      <td>
                        <button type="button" class="btn btn-sm btn-primary" (click)="addItem()" [disabled]="featureForm.get('featureItem').value === ''">
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card">
        <div class="card-footer">

          <button type="reset" class="btn btn-sm btn-warning" (click)="onCancel()">
            <i class="fa fa-ban"></i> Reset
          </button>
          <button type="submit" class="btn btn-sm btn-primary" [disabled]="!isValidForm()">
            <i class="fa fa-dot-circle-o"></i> Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</form>

<ng-template #editItemTemplate>
<div class="modal-header">
  <h4 class="modal-title">Confirm</h4>
</div>
<div class="modal-body">
  <form [formGroup]="editItemForm">
    <div class="form-group">
      <label for="name">Feature item</label>
      <input type="text" class="form-control" formControlName="name">
    </div>


    <div *ngIf="this.featureItem">
        <edit-feature-item-image (notify)="onNotify($event)" divId="album-products"
        [image]="this.featureItem.image.data[0]" [featureItemId]="this.featureItem.id" [featureId]="feature.id" fileType="10">
        </edit-feature-item-image>
    </div>

    <div class="form-group" *ngIf="isRangeFeature">
      <label for="name">Min value</label>
      <input type="text" class="form-control" formControlName="minIntValue">
    </div>
    <div class="form-group" *ngIf="isRangeFeature">
      <label for="name">Max value</label>
      <input type="text" class="form-control" formControlName="maxIntValue">
    </div>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="declineItemEdit()">No</button>
  <button type="button" class="btn btn-primary" (click)="confirmItemEdit()">Yes</button>
</div>
</ng-template>

<ng-template #deleteItemTemplate>
<div class="modal-header">
  <h4 class="modal-title">Confirm</h4>
</div>
<div class="modal-body">
  <p>Do you want to delete this feature item?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="declineItemDelete()">No</button>
  <button type="button" class="btn btn-primary" (click)="confirmItemDelete()">Yes</button>
</div>
</ng-template>

<ng-template #deleteFeatureImageTemplate>
<div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
</div>
<div class="modal-body">
    <p>Do you want to delete this file?</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineFeatureImageDelete()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmFeatureImageDelete()">Yes</button>
</div>
</ng-template>



<form [formGroup]="categoryEditForm" (ngSubmit)="onSubmit()" novalidate  #pform="ngForm">
    <div class="animated fadeIn">
      <div class="col mb-2">
          <span class="mr-2">Active </span>
          <label class="switch switch-lg switch-3d switch-primary" >
            <input type="checkbox" class="switch-input" formControlName="isActive">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Basic information
                        <button type="submit" class="btn btn-sm btn-primary float-right">
                         <i class="fa fa-save"></i> Submit
                       </button>
                       <button type="button" class="btn btn-sm btn-warning float-right mr-2" (click)="onCancel()">
                         <i class="fa fa-ban"></i> Cancel</button>
                       <button type="button" class="btn btn-sm btn-secondary float-right mr-2" [routerLink]="['/distributors/categories/list']">
                         <i class="fa fa-arrow-left"></i> Categories list
                       </button>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="title">Title</label>
                            <input type="text" class="form-control" id="title" formControlName="title">
                            <div *ngIf="title.invalid && (title.dirty || title.touched || pform.submitted)" class="alert alert-danger">
                                <div *ngIf="title.errors.required">
                                    Title is required.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                                <label for="description">Category description</label>
                                <angular-tinymce
                                  formControlName="description"
                                  ngDefaultControl
                                ></angular-tinymce>
                        </div>
                    </div>
                </div>
            </div>
            <!--/.col-->
            <div class="col-md-12">
                <div class="card">
                 <div class="card-footer">
                   <button type="reset" class="btn btn-sm btn-danger" (click)="onCancel()">
                     <i class="fa fa-ban"></i> Cancel</button>
                   <button type="submit" class="btn btn-sm btn-primary">
                       <i class="fa fa-dot-circle-o"></i> Submit</button>
                 </div>
             </div>
            </div>
        </div>
    </div>
  </form>

<div *ngIf="files && files.length">
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th style="width: 20%">Title</th>
          <th style="width: 20%">Description</th>
          <th style="width: 20%">Order</th>
          <th style="width: 20%">File</th>
          <th style="width: 20%">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of files">
          <td>{{ item.title }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.sort_order }}</td>
          <td>
            <a target="_blank" href="{{ item.filepath }}" download>
              {{ item.filename }}
            </a>
          </td>
          <td>
            <button
              class="btn btn-sm btn-primary mr-2"
              type="button"
              (click)="openModal(template, item)"
            >
              <i class="fa fa-pencil"></i>
            </button>
            <a
              class="btn btn-sm btn-primary mr-2"
              target="_blank"
              href="{{ item.filepath }}"
            >
              <i class="fa fa-search-plus"></i>
            </a>
            <button
              class="btn btn-sm btn-danger"
              type="button"
              (click)="detachSliderItem(item.id)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div id="fine-uploader-manual-trigger">
  <div id="{{ divId }}"></div>
</div>
<div class="mt-1">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12">
        <div class="mb-1">
          <div style="width: 100%">
            <input
              class="form-control"
              #filter
              placeholder="Find file"
              type="text"
              (focus)="show($event)"
            />
          </div>
        </div>
        <div [hidden]="!canSee" style="border: 1px solid rgba(0, 0, 0, 0.12)">
          <div class="row align-items-center">
            <div class="col-10">
              <mat-paginator
                #paginator
                [length]="database.data.length"
                [pageIndex]="0"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]"
              >
              </mat-paginator>
            </div>
            <div class="col-2 text-right">
              <div (click)="hide($event)" class="btn btn-danger">X</div>
            </div>
          </div>

          <mat-table
            #table
            [dataSource]="dataSource"
            matSort
            matSortActive="filetime"
            matSortDirection="desc"
          >
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <ng-container matColumnDef="filename">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                File name
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span [innerHTML]="row.filename"></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="filetime">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="flex: 0 0 20%"
              >
                Date
              </mat-header-cell>
              <mat-cell *matCellDef="let row" style="flex: 0 0 20%">
                <span [innerHTML]="row.filetime"></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="filepath">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >File path
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span [innerHTML]="row.filepath"></span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell
                *matHeaderCellDef
                class="text-center"
                style="flex: 0 0 15%"
              ></mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="text-center"
                style="flex: 0 0 20%"
              >
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="attachSliderItem(row)"
                >
                  <i class="fa fa-arrow-up" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="copyTextToClipboard(row.filepath)"
                >
                  <i class="fa fa-clone" aria-hidden="true"></i>
                </button>
                <a
                  target="_blank"
                  href="{{ row.filepath }}"
                  class="btn btn-primary"
                  download="filename"
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="remove(row.filename)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <!-- <app-file-uploader (onAllCompleted)="onFileUploaded()"></app-file-uploader> -->
      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-body text-center">
    <p>Edit slider item</p>
    <div class="form-group">
      <label for="stitle">Slider title</label>
      <input
        type="text"
        class="form-control"
        id="stitle"
        [(ngModel)]="selectedItem.title"
      />
    </div>

    <div class="form-group">
      <label for="sdescription">Slider description</label>
      <textarea
        type="text"
        class="form-control"
        id="sdescription"
        [(ngModel)]="selectedItem.description"
      ></textarea>
    </div>

    <div class="form-group">
      <label for="sorder">Order</label>
      <input
        type="number"
        class="form-control"
        id="sorder"
        [(ngModel)]="selectedItem.sort_order"
      />
    </div>

    <div class="mt-4">
      <button type="button" class="btn btn-default" (click)="confirm()">
        Save
      </button>
      <button type="button" class="btn btn-primary" (click)="decline()">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

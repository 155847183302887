import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '../users.service';
import {AddUser} from '../add-user.model';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {UserLanguage} from '../../../user/user-language.model';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html'
})
export class UsersAddComponent implements OnInit {

  userForm: FormGroup;
  languages: UserLanguage[]

  constructor(private fb: FormBuilder, private usersService: UsersService, private router: Router,
              private toastr: ToastrService, private spinnerService: NgxSpinnerService, private location: Location) {

  }

  ngOnInit() {
    this.usersService.getLanguages().subscribe(langs => {
      this.languages = langs
      this.setFormValues();
    });
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required,  Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required,  Validators.minLength(6)]],
      notificationsEnabled: true,
      isActive: false,
      phone: [''],
      role: 'standard'
    });
  }

  onSubmit() {
    if (this.isValidForm()) {

      const formModel = this.userForm.value;
      const selectedLangsIds = formModel.langs
        .map((v, i) => v ? this.languages[i].id : null)
        .filter(v => v !== null);

      const user: AddUser = {
        name: formModel.name as string,
        email: formModel.email as string,
        isActive: formModel.isActive as boolean,
        notificationsEnabled: formModel.notificationsEnabled as boolean,
        password: formModel.password as string,
        password_confirmation: formModel.password_confirmation as string,
        role: formModel.role as string,
        langs: selectedLangsIds,
        phone: formModel.phone as string,
      };
      this.usersService.addUser(user)
        .subscribe(() => {
          this.toastr.success('User account created successfully');
          this.router.navigate(['./users/list']);
        });
    }
  }

  onCancel() {
    this.location.back();
  }

  isValidForm() {
    return this.userForm.status === 'VALID';
  }

  get name() {
    return this.userForm.get('name');
  }

  get email() {
    return this.userForm.get('email');
  }

  get password() {
    return this.userForm.get('password');
  }
  get phone() {
    return this.userForm.get('phone');
  }

  get password_confirmation() {
    return this.userForm.get('password_confirmation');
  }
  get langs() {
    return this.userForm.get('langs');
  }


  private setFormValues() {
    const arr = new FormArray([]);
    for (const l of this.languages) {
      const  control = new FormControl(false);
      arr.push(control);
    }
    this.userForm.addControl('langs', arr);
  }
}

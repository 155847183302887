<div *ngIf="links && links.length">
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th style="width: 20%">Id</th>
          <th style="width: 20%">Name</th>
          <th style="width: 40%">Url</th>
          <th style="width: 20%">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of links">
          <td>{{ item.id }}</td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.url }}
          </td>
          <td>
            <a
              class="btn btn-sm btn-primary mr-2"
              target="_blank"
              href="{{ item.url }}"
            >
              <i class="fa fa-search-plus"></i>
            </a>
            <button
              class="btn btn-sm btn-primary mr-2"
              type="button"
              (click)="openModal(template, item)"
            >
              <i class="fa fa-pencil"></i>
            </button>
            <button
              class="btn btn-sm btn-danger"
              type="button"
              (click)="detachPostLink(item.id)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #template>
  <div class="modal-body">
    <div class="text-center">
      <p>Edit link</p>
    </div>
    <div class="form-group text-left">
      <label for="pl_name">Name</label>
      <input
        type="text"
        class="form-control"
        id="pl_name"
        name="pl_name"
        [(ngModel)]="selectedLink.name"
      />
    </div>
    <div class="form-group text-left">
      <label for="pl_url">URL</label>
      <input
        type="text"
        class="form-control"
        id="pl_url"
        name="pl_url"
        [(ngModel)]="selectedLink.url"
      />
    </div>

    <div class="mt-4">
      <button type="button" class="btn btn-default" (click)="confirm()">
        Save
      </button>
      <button type="button" class="btn btn-primary ml-2" (click)="decline()">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

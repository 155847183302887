import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TokenInterceptor } from './auth/token.interceptor';
import { AuthenticationService } from './auth/authentication.service';
import { AppComponent } from './app.component';
import 'hammerjs';
// Import containers
import { FullLayout, SimpleLayout } from './containers';
// Import components
import {
  AppBreadcrumbs,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarHeader,
  AppUserSettingsComponent
} from './components';
// Import directives
import { AsideToggleDirective, NAV_DROPDOWN_DIRECTIVES, SIDEBAR_TOGGLE_DIRECTIVES } from './directives';
// Import routing module
import { AppRoutingModule } from './app.routing';
// Import 3rd party components
import { ProductsModule } from 'app/views/products/products.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerService } from 'app/spinner/spinner.service';
import { ErrorService } from 'app/error.service';
import { UserService } from 'app/user/user.service';
import { DistributorsModule } from './views/distributors/distributors.module';
import { DistributorService } from './views/distributors/distributor.service';
import { ConfiguratorsModule } from './views/configurators/configurators.module';
import { ConfiguratorService } from './views/configurators/configurator.service';
import { UsersModule } from './views/users/users.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
@NgModule({
  imports: [
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ProductsModule,
    DistributorsModule,
    ConfiguratorsModule,
    UsersModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    // NgbModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  declarations: [
    AppComponent,
    FullLayout,
    SimpleLayout,
    AppBreadcrumbs,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarHeader,
    AppUserSettingsComponent,
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ErrorService,
    UserService,
    DistributorService,
    ConfiguratorService,
    SpinnerService,
    AuthenticationService, {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

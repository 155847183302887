import { TraderPosition, AddTeam } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DistributorService } from '../distributor.service';
import { CountryService } from '../country/country.service';
import { Country } from '../country/country.model';
import { DatePipe, Location } from '@angular/common';

@Component({
  selector: 'helpers-add',
  templateUrl: './helpers-add.component.html',
  styleUrls: ['./helpers-add.component.scss']
})
export class HelpersAddComponent {

  userForm: FormGroup;

  constructor(private fb: FormBuilder, private distributorService: DistributorService, private router: Router,
    private countryService: CountryService, private toastr: ToastrService, private datePipe: DatePipe,
    private location: Location) {
    this.createForm();
  }
  onSubmit() {
    if (this.isValidForm()) {
      const addTeam = this.prepareAddTeam();
      this.distributorService.addTeamUser(addTeam)
        .subscribe(response => {
          console.log(response);
            this.toastr.success('Team user created successfully');
            this.router.navigate(['./distributors/helper/edit/' + response.id]);
          });
    }
  }

  onCancel() {
    this.location.back();
  }

  prepareAddTeam(): AddTeam {
    const formModel = this.userForm.value;

    const addTeam: AddTeam = {
      name: formModel.name as string,
      surname: formModel.surname as string,
      phone: formModel.phone as string,
      mobile: formModel.mobile as string,
      email: formModel.email as string,
    };
    return addTeam;
  }

  createForm() {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: '',
      phone: '',
      mobile: '',
    });
  }

  isValidForm() {
    return this.userForm.status === 'VALID';
  }

  get name() {
    return this.userForm.get('name');
  }
  get surname() {
    return this.userForm.get('surname');
  }
  get phone() {
    return this.userForm.get('phone');
  }
  get mobile() {
    return this.userForm.get('mobile');
  }
  get email() {
    return this.userForm.get('email');
  }
}



<form [formGroup]="editForm" (ngSubmit)="onSubmit()" novalidate  #pform="ngForm">
  <div class="animated fadeIn">
      <div class="row">
          <div class="col-md-12">
              <div class="card">
                  <div class="card-header">
                      Basic information
                      <button type="submit" class="btn btn-sm btn-primary float-right">
                       <i class="fa fa-save"></i> Submit
                     </button>
                     <button type="button" class="btn btn-sm btn-warning float-right mr-2" (click)="onCancel()">
                       <i class="fa fa-ban"></i> Cancel</button>
                     <button type="button" class="btn btn-sm btn-secondary float-right mr-2" [routerLink]="['/distributors/region/list']">
                       <i class="fa fa-arrow-left"></i> Region list
                     </button>
                  </div>
                  <div class="card-body">
                      <div class="form-group">
                          <label for="title">Title</label>
                          <input type="text" class="form-control" id="title" formControlName="title">
                          <div *ngIf="title.invalid && (title.dirty || title.touched || pform.submitted)" class="alert alert-danger">
                              <div *ngIf="title.errors.required">
                                  Title is required.
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!--/.col-->
          <div class="col-md-12">
              <div class="card">
               <div class="card-footer">
                 <button type="reset" class="btn btn-sm btn-danger" (click)="onCancel()">
                   <i class="fa fa-ban"></i> Cancel</button>
                 <button type="submit" class="btn btn-sm btn-primary">
                     <i class="fa fa-dot-circle-o"></i> Submit</button>
               </div>
           </div>
          </div>
      </div>
  </div>
</form>

import { CategoryAdd, TraderNews } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'news-add',
  templateUrl: './news-add.component.html',
  styleUrls: ['./news-add.component.scss']
})
export class NewsAddComponent implements OnInit {

  newsForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private distributorsService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService) {
    this.createForm();
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const newsAdd = this.getData();
      this.distributorsService.addNews(newsAdd)
        .subscribe(
          (res) => {
            this.toastr.success('News created successfully');
            this.spinnerService.hide();
            this.router.navigate(['./distributors/news/edit/' + res.id]);
          });
    }
  }

  onCancel() {
    this.location.back()
  }


  createForm() {
    this.newsForm = this.fb.group({
      description: ['', Validators.required],
      title: ['', Validators.required]
    });
  }

  private getData(): TraderNews {
    const formModel = this.newsForm.value;
    return {
      title: formModel.title as string,
      description: formModel.description as string
    }
  }

  isValidForm() {
    return this.newsForm.status === 'VALID';
  }

  get description() {
    return this.newsForm.get('description');
  }

  get title() {
    return this.newsForm.get('title');
  }

  get contentControl() {
    const formControl = new FormControl(this.newsForm.value.content as string, Validators.required);
    return this.getControl(formControl, 'content');
  }
  get summaryControl() {
    const formControl = new FormControl(this.newsForm.value.summary as string, Validators.required);
    return this.getControl(formControl, 'summary');
  }

  getControl(formControl, name) {
    const originalControl = this.newsForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }
}


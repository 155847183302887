<div class="col-md-12">
  <div class="row">
    <div class="col-md-6" *ngIf="!isRestrictedUser">
      <div class="card">
        <div class="card-header">
          Technical drawing
        </div>
        <div class="card-body">
          Last modified by&nbsp;:&nbsp;<span id="lastInTechnicalDrawing"></span>
          <edit-product-album-images *ngIf="productId && product && product.technicalDrawingImages"
                                     divId="album-technical-drawing"
                                     [images]="product.technicalDrawingImages" [productId]="productId"
                                     fileType="4">
          </edit-product-album-images>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          Attributes
          <button class="btn btn-sm btn-primary float-right"
                  *ngIf="!isRestrictedUser"
                  (click)="openEditAttributeModal(attributeTemplate, null)">
            <i class="fa fa-plus"></i>&nbsp; Add
          </button>
        </div>
        <div class="card-body">
            Last modified by&nbsp;:&nbsp;<span id="lastInAttributes"></span>
          <div [formGroup]="attributeForm" class="form-group" *ngIf="product && product.attribs">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th style="width: 70%">Name</th>
                  <th style="width: 30%">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let attribute of product.attribs">
                  <td>{{attribute.description}}</td>
                  <td>
                    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="openEditAttributeModal(attributeTemplate, attribute)">
                      <i class="fa fa-pencil"></i>
                    </button>

                    <button type="button" class="btn btn-sm btn-danger mr-1"
                            *ngIf="!isRestrictedUser"
                            (click)="opelDeleteAttributeModal(deleteAttributeTemplate, attribute)">
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-sm mr-1"
                            *ngIf="!isRestrictedUser"
                            (click)="attributeSortUp(attribute.id)">
                      <i class="fa fa-chevron-up"></i></button>
                    <button type="button" class="btn btn-sm mr-1"
                            *ngIf="!isRestrictedUser"
                            (click)="attributeSortDown(attribute.id)">
                      <i class="fa fa-chevron-down"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="col-md-12">
  <div class="row">
    <div class="col-md-6" *ngIf="!isRestrictedUser" id="specContainer">
      <div class="card">
        <div class="card-header">
          Specifications
          <button class="btn btn-sm btn-primary float-right" (click)="openEditSpecificationModal(specificationTemplate, null)">
            <i class="fa fa-plus"></i>&nbsp; Add
          </button>
        </div>
        <div class="card-body">
            Last modified by&nbsp;:&nbsp;<span id="lastInSpecifications"></span>
          <div [formGroup]="specificationForm" class="form-group" *ngIf="product && product.specificationRecords">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th>Color</th>
                  <th>Ref. number</th>
                  <th>Available lengths</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let specificationRecord of product.specificationRecords">
                  <td>{{specificationRecord.color}}</td>
                  <td>{{specificationRecord.refNumber}}</td>
                  <td>{{specificationRecord.availableLengths}}</td>
                  <td>
                    <button type="button" class="btn btn-sm btn-primary mr-1"
                            (click)="openEditSpecificationModal(specificationTemplate, specificationRecord)">
                      <i class="fa fa-pencil"></i>
                    </button>

                    <button type="button" class="btn btn-sm btn-danger mr-1"
                            (click)="opelDeleteSpecificationModal(deleteSpecificationTemplate, specificationRecord)">
                      <i class="fa fa-trash"></i>
                    </button>

                    <button type="button" class="btn btn-sm mr-1" (click)="specificationSortUp(specificationRecord.id)">
                      <i class="fa fa-chevron-up"></i></button>
                    <button type="button" class="btn btn-sm" (click)="specificationSortDown(specificationRecord.id)">
                      <i class="fa fa-chevron-down"></i></button>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div >
<ng-template #attributeTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="attributeForm">
      <div class="form-group">
        <label for="description">Attribute</label>
        <input type="text" class="form-control" formControlName="description" id="description">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineAttributeEdit()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmAttributeEdit()">Yes</button>
  </div>
</ng-template>

<ng-template #deleteAttributeTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <p>Do you want to delete this attribute?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineAttributeDelete()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmAttributeDelete()">Yes</button>
  </div>
</ng-template>

<ng-template #specificationTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="specificationForm">
      <div class="form-group">
        <label for="colorId">Color</label>
        <select class="form-control" formControlName="colorId" id="colorId">
          <option value="">None</option>
          <option *ngFor="let color of getSpecFeatureItems()" [value]="color.id">
            {{color.name}}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="refNumber">Reference number</label>
        <input type="text" class="form-control" formControlName="refNumber" id="refNumber">
      </div>
      <div class="form-group">
        <label for="availableLengths">Available lengths</label>
        <input type="text" class="form-control" formControlName="availableLengths" id="availableLengths">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineSpecificationEdit()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmSpecificationEdit()">Yes</button>
  </div>
</ng-template>

<ng-template #deleteSpecificationTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <p>Do you want to delete this attribute?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineSpecificationDelete()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmSpecificationDelete()">Yes</button>
  </div>
</ng-template>
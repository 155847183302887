import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {DistributorService} from '../distributor.service';
import {Country} from '../country/country.model';
import {CountryService} from '../country/country.service';
import {Distributor} from '../Distributor.model';
import {DatePipe, Location} from '@angular/common';

@Component({
  selector: 'app-edit-distributor',
  templateUrl: './edit-distributor.component.html'
})
export class EditDistributorComponent {

  distributorForm: FormGroup;
  distributorId: number;
  distributor: Distributor;
  countries: Country[];

  constructor(private fb: FormBuilder, private distributorService: DistributorService, private router: Router,
              private route: ActivatedRoute, private countryService: CountryService, private toastr: ToastrService,
              private datePipe: DatePipe, private location: Location) {
    this.createForm();
    this.route.params.subscribe(params => {
      this.distributorId = parseInt(params['id']);
      countryService.getCountries().subscribe(
        countries => {
          this.countries = countries
          distributorService.getDistributor(this.distributorId).subscribe(
            distributor => {
              this.distributor = distributor;
              this.setFormValues();
            });
        }
      );
    });
  }

  onSubmit() {
    if (this.isValidForm()) {
      const distributor = this.prepareEditDistributor();
      this.distributorService.updateDistributor(this.distributorId, distributor)
        .subscribe(
          () => {
            this.toastr.success('Distributtor created successfully');
            this.router.navigate(['./distributors/list']);
          });
    }
  }

  onCancel() {
    this.location.back();
  }

  createForm() {
    this.distributorForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: '',
      postalCode: '',
      email: '',
      www: '',
      tel: '',
      tel2: '',
      voivodeship_id: '',
      country_id: ['', Validators.required],
      type: ['', Validators.required],
      latitude: '',
      longitude: '',
      isActive: false
    });
  }

  prepareEditDistributor(): Distributor {
    const formModel = this.distributorForm.value;

    const distributor: Distributor = {
      id: this.distributorId,
      name: formModel.name as string,
      address: formModel.address as string,
      postalCode: formModel.postalCode as string,
      city: formModel.city as string,
      country_id: formModel.country_id,
      country: null,
      voivodeship_id: formModel.voivodeship_id,
      tel: formModel.tel as string,
      tel2: formModel.tel2 as string,
      email: formModel.email as string,
      www: formModel.www as string,
      type: formModel.type,
      latitude: formModel.latitude as string,
      longitude: formModel.longitude as string,
      isActive: formModel.isActive as boolean
    };
    return distributor;
  }

  isValidForm() {
    return this.distributorForm.status === 'VALID';
  }

  get name() {
    return this.distributorForm.get('name');
  }

  get address() {
    return this.distributorForm.get('address');
  }

  get country_id() {
    return this.distributorForm.get('country_id');
  }
  get voivodeship_id(){
    return this.distributorForm.get('voivodeship_id');
  }

  private setFormValues() {
    this.distributorForm.setValue({
      name: this.distributor.name as string,
      address: this.distributor.address as string,
      postalCode: this.distributor.postalCode,
      city: this.distributor.city as string,
      country_id: this.distributor.country_id,
      voivodeship_id: this.distributor.voivodeship_id,
      tel: this.distributor.tel as string,
      tel2: this.distributor.tel2,
      email: this.distributor.email as string,
      www: this.distributor.www as string,
      type: this.distributor.type,
      latitude: this.distributor.latitude as string,
      longitude: this.distributor.longitude as string,
      isActive: this.distributor.isActive as boolean
    })
  }
}


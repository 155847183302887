import { CategoryAdd } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.scss']
})
export class CategoryAddComponent implements OnInit {
  categoryForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private distributorsService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService) {
    this.createForm();
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const categoryAdd = this.getData();
      this.distributorsService.addCategory(categoryAdd)
        .subscribe(
          (res) => {
            this.toastr.success('New category created successfully');
            this.spinnerService.hide();
            this.router.navigate(['./distributors/categories/edit/' + res.id]);
          });
    }
  }

  onCancel() {
    this.location.back()
  }


  createForm() {
    this.categoryForm = this.fb.group({
      description: [''],
      title: ['', Validators.required]
    });
  }

  private getData(): CategoryAdd {
    const formModel = this.categoryForm.value;
    return {
      title: formModel.title as string,
      description: formModel.description as string
    }
  }

  isValidForm() {
    return this.categoryForm.status === 'VALID';
  }

  get description() {
    return this.categoryForm.get('description');
  }

  get title() {
    return this.categoryForm.get('title');
  }

  get contentControl() {
    const formControl = new FormControl(this.categoryForm.value.content as string, Validators.required);
    return this.getControl(formControl, 'content');
  }
  get summaryControl() {
    const formControl = new FormControl(this.categoryForm.value.summary as string, Validators.required);
    return this.getControl(formControl, 'summary');
  }

  getControl(formControl, name) {
    const originalControl = this.categoryForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }
}


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DistributorService } from '../distributor.service';
import { RoleEdit } from '../Distributor.model';

@Component({
  selector: 'roles-add',
  templateUrl: './roles-add.component.html',
  styleUrls: ['./roles-add.component.scss']
})
export class RolesAddComponent implements OnInit {
  roleForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private distributorsService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService) {
    this.createForm();
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const roleAdd = this.getData();
      this.distributorsService.addRole(roleAdd)
        .subscribe(
          (res) => {
            this.toastr.success('New role created successfully');
            this.spinnerService.hide();
            this.router.navigate(['./distributors/roles/list/']);
          });
    }
  }

  onCancel() {
    this.location.back()
  }

  createForm() {
    this.roleForm = this.fb.group({
      title: ['', Validators.required]
    });
  }

  private getData(): RoleEdit {
    const formModel = this.roleForm.value;
    return {
      title: formModel.title as string,
    }
  }

  isValidForm() {
    return this.roleForm.status === 'VALID';
  }
  get title() {
    return this.roleForm.get('title');
  }
}



/**
 * Created by hawlik on 08.02.18.
 */
export class Page {
  constructor(public id: number,
              public name: string,
              public title: string,
              public meta_title: string,
              public meta_keywords: string,
              public meta_description: string,
              public content: string,
              public slug: string,
              public guid: string,
              public publicVisible: boolean) {

  }
}

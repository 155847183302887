import {AfterViewInit, Component, EventEmitter, Output} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {FineUploader} from 'fine-uploader';
import {User} from '../../../../user/user.model';
import {AuthenticationService} from '../../../../auth/authentication.service';
import {ToastrService} from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'user-file-uploader',
  templateUrl: './user-file-uploader.component.html'
})
export class UserFileUploaderComponent implements AfterViewInit {

  user: User;
  uploader: FineUploader;
  filesAllowedExtension = ['jpeg', 'jpg', 'gif', 'png', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'zip'];


  @Output() onAllCompleted: EventEmitter<any> = new EventEmitter();

  constructor(private toastr: ToastrService, private modalService: BsModalService, private authService: AuthenticationService) {
  }


  ngAfterViewInit(): void {
    this.configureUploader(this);
  }

  configureUploader(that: any) {
    if (that.uploader == null) {
      this.uploader = new FineUploader({
        element: document.getElementById('file-container'),
        autoUpload: true,
        request: {
          endpoint: environment.apiUrl + '/uploader',
          customHeaders: {
            'Authorization': `Bearer ${that.authService.getToken()}`
          }
        },
        validation: {
          allowedExtensions: that.filesAllowedExtension
        },
        callbacks: {
          onSubmit: function (options) {
            this.setParams({isContentFile: true});
          },
          onAllComplete: function (succeeded, failed) {
            this.reset();
            if (succeeded) {
              that.onAllCompleted.emit(succeeded);
            }
          }
        }
      });
    } else {
      this.uploader.setParams({isContentFile: true});
    }
  }

}

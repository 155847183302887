import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditProduct } from './edit-product.model';
import { ProductService } from './../api/product.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'app/views/products/categories/category.service';
import { ListProductCategory } from 'app/views/products/product-list/list-product-category.model';
import { ListCategory } from '../categories/category-list/list-category.model';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../user/user.service';
import { CategoryConstantService } from '../categories/category-constant.service';
import { Helpers } from '../../../helpers/helpers.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  productForm: FormGroup;
  product: EditProduct;
  productId: number;
  categories: ListProductCategory[];
  categoryList: Observable<ListCategory[]>;
  modalRef: BsModalRef;
  contentUrl: string;
  helpers: any;

  constructor(private fb: FormBuilder, private productService: ProductService, private categoryService: CategoryService,
    private route: ActivatedRoute, private router: Router, private toastr: ToastrService,
    private spinnerService: NgxSpinnerService, private datePipe: DatePipe,
    private userService: UserService, private categoryConstansService: CategoryConstantService) {
    this.contentUrl = environment.pageUrl;
    this.helpers = new Helpers();
  }

  ngOnInit() {
    this.spinnerService.show();
    this.getInitialData();
    this.createForm();
    this.router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        }
      }
    });
  }

  getInitialData() {
    this.route.params.subscribe(params => {
      this.productId = parseInt(params['id']);
      this.categoryService.getOrderedListProductCategories().subscribe(
        categories => {
          this.categories = categories;
          this.productService.getProduct(this.productId).subscribe(
            product => {
              this.product = product;
              this.setFormValues();
              this.spinnerService.hide();
              this.helpers.setHistoryRecords(this.isRestrictedUser, product);
            });

        });
    });
  }
  changeActiveFlag($event) {
    if (this.productForm.get('isActive').value) {
      this.product.isVisibleOnList = true;
    } else {
      this.product.isVisibleOnList = false;
    }
    this.productForm.controls['isVisibleOnList'].setValue(this.product.isVisibleOnList);
  }


  copyProduct() {
    let newProductId = null;
    if (confirm('Do you really want to copy this product?')) {

      this.productService.copyProductRequest(this.productId).subscribe(response => {
        newProductId = response.newProductId;
        this.toastr.success('Product copied successfully');
        window.open(
          '/#/products/edit/' + newProductId,
          '_blank'
        );
      })
    }
  }

  updateImages() {
    this.productService.getProduct(this.productId).subscribe(
      product => {
        this.product = product;
        this.setFormValues();

      });
  }

  createForm() {
    this.productForm = this.fb.group({
      name: ['', Validators.required],
      description: '',
      info: '',
      shortName: '',
      catalogueNumber: [{ value: '', disabled: this.isRestrictedUser }, Validators.required],
      isActive: false,
      isVisibleOnList: false,
      inVisibleOnSearch: false,
      isVirtualProduct: false,
      assets: '',
      additionalInfo: '',
      assemblyDescription: '',
      additionalLedInfo: '',
      promotedUntil: '',
      categoryId: [{ value: '', disabled: this.isRestrictedUser }, Validators.required],
      attribute: [''],
      instructionalVideo: '',
      instructionalVideo2: ''
    });
  }

  setFormValues() {
    this.productForm.setValue({
      name: this.product.name,
      description: this.product.description,
      info: this.product.info,
      shortName: this.product.shortName,
      catalogueNumber: this.product.catalogueNumber,
      assets: this.product.assets,
      additionalInfo: this.product.additionalInfo,
      additionalLedInfo: this.product.additionalLedInfo,
      assemblyDescription: this.product.assemblyDescription,
      promotedUntil: this.product.promotedUntil,
      categoryId: this.product.categoryId,
      isActive: this.product.isActive,
      isVisibleOnList: this.product.isVisibleOnList,
      inVisibleOnSearch: this.product.inVisibleOnSearch,
      isVirtualProduct: this.product.isVirtualProduct,
      attribute: '',
      instructionalVideo: this.product.instructional_video_link,
      instructionalVideo2: this.product.instructional_video_link_2
    });
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const editProduct = this.prepareEditProduct();
      this.productService.updateProduct(this.productId, editProduct)
        .subscribe(() => {
          this.spinnerService.hide();
          this.toastr.success('Product updated successfully');
          this.productService.getProduct(this.productId).subscribe(
            product => {
              this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
            });
        }, e => { this.spinnerService.hide() });
    }
  }

  onFeaturesChanged(event) {
    this.product.featureItems = event;
  }

  prepareEditProduct(): EditProduct {
    const formModel = this.productForm.value;
    const editProduct: EditProduct = {
      id: this.product.id,
      name: formModel.name as string,
      description: formModel.description as string,
      info: formModel.info as string,
      shortName: formModel.shortName as string,
      catalogueNumber: formModel.catalogueNumber as string,
      assets: formModel.assets as string,
      additionalInfo: formModel.additionalInfo as string,
      additionalLedInfo: formModel.additionalLedInfo as string,
      assemblyDescription: formModel.assemblyDescription as string,
      promotedUntil: formModel.promotedUntil != null ? this.datePipe.transform(formModel.promotedUntil, 'yyyy-MM-dd') : '',
      categories: null,
      categoryId: formModel.categoryId as number,
      isActive: formModel.isActive as boolean,
      isVisibleOnList: formModel.isVisibleOnList as boolean,
      inVisibleOnSearch: formModel.inVisibleOnSearch as boolean,
      isVirtualProduct: formModel.isVirtualProduct as boolean,
      documents: null,
      productImages: null,
      featureItems: null,
      usageImages: null,
      technicalDrawingImages: null,
      specificationRecords: null,
      childs: null,
      attribs: null,
      image: null,
      productGuid: null,
      preventProductCardGeneration: null,
      instructional_video_link: formModel.instructionalVideo as string,
      instructional_video_link_2: formModel.instructionalVideo2 as string,
      historyRecords: null,
    };
    return editProduct;
  }

  getLinkToPreview() {

    let link = '/produkt/';

    switch (this.userService.selectedLang().name) {

      case 'en':
        link = '/en/product/';
        break;
      case 'ro':
        link = '/ro/produs/'
        break;
      case 'pl':
        link = '/produkt/'
        break;
      case 'de':
        link = '/de/produkt/'
        break;
      case 'cz':
        link = '/cz/produkt/'
        break;
      case 'fr':
        link = '/fr/produit/'
        break;
      case 'es':
        link = '/es/producto/'
        break;
      case 'it':
        link = '/it/prodotto/'
        break;
      case 'ru':
        link = '/ru/tovary/'
        break;
    }

    return this.contentUrl + link + this.product.productGuid;

  }

  isValidForm() {
    return this.productForm.status === 'VALID';
  }

  get name() {
    return this.productForm.get('name');
  }

  get catalogueNumber() {
    return this.productForm.get('catalogueNumber');
  }

  get categoryId() {
    return this.productForm.get('categoryId');
  }

  getControl(formControl, name) {
    const originalControl = this.productForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }

  get isRestrictedUser() {
    return this.userService.isRestricted();
  }

  get isFixturesCategory() {
    return this.productForm.getRawValue().categoryId == this.categoryConstansService.PROFILE
  }

  get isLedCategory() {
    return this.productForm.getRawValue().categoryId == this.categoryConstansService.LED_TAPES
  }
}


<form [formGroup]="userForm" (ngSubmit)="onSubmit()" novalidate #uForm="ngForm">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" formControlName="name">
              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="phone">Phone</label>
              <input type="text" class="form-control" id="phone" formControlName="phone">
              <div *ngIf="phone.invalid && (phone.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="phone.errors.required">
                  Phone is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input class="form-control" type="text" formControlName="email" id="email">
              <div *ngIf="email.invalid && (uForm.submitted || email.dirty || email.touched)" class="alert alert-danger">
                <div *ngIf="email.errors.required">
                  Email address is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input class="form-control" type="password" formControlName="password"  id="password">
              <div *ngIf="password.invalid && (uForm.submitted || password.dirty || password.touched)" class="alert alert-danger">
                <div *ngIf="password.errors.required">
                  password is required.
                </div>
                <div *ngIf="password.errors.minlength">
                  Password must be at least 6 characters long.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="password_confirmation">Confirm password</label>
              <input class="form-control" type="password" formControlName="password_confirmation"  id="password_confirmation">
              <div *ngIf="password_confirmation.invalid && (uForm.submitted || password_confirmation.dirty || password_confirmation.touched)" class="alert alert-danger">
                <div *ngIf="password_confirmation.errors.required">
                  password is required.
                </div>
                <div *ngIf="password_confirmation.errors.minlength">
                  Password must be at least 6 characters long.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="role">Role</label>
              <select class="form-control" formControlName="role" id="role">
                <option value="patners-first">Partners first</option>
                <option value="business-supervisor">Business supervisor</option>
                <option value="standard">Standard user</option>
                <option value="restricted">Restricted</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <div class="form-group" *ngIf="langs">
              <label for="email" class="col-md-3 col-form-label">Content versions</label>
              <div class="col-md-3 col-form-label">
                <div class="form-check" *ngFor="let lang of langs.controls; let i=index">
                  <input class="form-check-input" type="checkbox" [formControl]="lang" id="lang-{{languages[i].id}}"/>
                  <label class="form-check-label" for="lang-{{languages[i].id}}">{{languages[i].name}}</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <input type="checkbox" id="notificationsEnabled" name="notificationsEnabled" formControlName="notificationsEnabled" />Notifications enable
            </div>
            <!--<div class="form-group">-->
              <!--<input type="checkbox" id="isActive" name="isActive" formControlName="isActive" /> Active-->
            <!--</div>-->
          </div>
        </div>
        <div class="card">

          <div class="card-footer">
            <button type="reset" class="btn btn-sm btn-danger" (click)="onCancel()">
              <i class="fa fa-ban"></i> cancel</button>
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="fa fa-dot-circle-o"></i> Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
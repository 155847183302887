<div class="card">
  <div class="card-header">
    {{productRelatedFeature.feature.name}}
  </div>
  <div class="card-body">
    <table class="table">
      <tr *ngFor="let pri of productRelatedFeature.items ">
        <td>{{pri.featureItem.name}}</td>
        <td>
          <label class="switch switch-3d switch-primary">
            <input type="checkbox" class="switch-input" value="{{pri.featureItem.id}}" (change)="featureItemChange($event)"
                   [checked]="pri.selected">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
        </td>
      </tr>
    </table>
  </div>
</div>
import {Component} from '@angular/core';
import {UserService} from 'app/user/user.service';
import {User} from 'app/user/user.model';
import {ToastrService} from 'ngx-toastr';
import {UserLanguage} from '../../user/user-language.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-settings',
  templateUrl: './app-user-settings.component.html',
  styleUrls: ['./app-user-settings.component.scss']
})

export class AppUserSettingsComponent {
  user: User;

  constructor(private userService: UserService, private toastr: ToastrService, private spinnerService: NgxSpinnerService) {
    this.userService.getUser().subscribe(u => {
      this.user = u;
    });
  }

  setLanguage(languageId: number) {
    this.spinnerService.show();
    this.userService.updateUserLanguage(this.user.id, languageId)
      .subscribe(user => {
          this.userService.dataChange.next(user);
          this.user = user;
          this.toastr.success('User language changed');
        },
        () => {},
        () => {
          this.spinnerService.hide();
        });
  }

  selectedLang(): String {
    return this.availableLanguages().find(al => al.id === this.user.languageId).name
  }

  availableLanguages(): UserLanguage[] {
    return this.user.availableLanguages;
  }
}

import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {CountryService} from '../country.service';
import {AddCountry} from './Addcountry.model';
import {Location} from '@angular/common';

@Component({
    selector: 'app-add-country',
    templateUrl: './add-country.component.html'
})
export class AddCountryComponent {

    countryForm: FormGroup;

    constructor(private fb: FormBuilder, private router: Router, private countryService: CountryService,
                private toastr: ToastrService, private location: Location) {
        this.createForm();
    }

    onSubmit() {
        const addCountry = this.prepareAddCountry();
        this.countryService.addCountry(addCountry)
            .subscribe(
            () => {
                this.toastr.success('Country created successfully');
                this.router.navigate(['./distributors/countries/list']);
            });
    }

    onCancel() {
        this.location.back();
    }

    prepareAddCountry(): AddCountry {
        const formModel = this.countryForm.value;
        return {
            name: formModel.name as string
        }
    }

    createForm() {
        this.countryForm = this.fb.group({
            name: ['', Validators.required]});
    }

    isValidForm() {
        return this.countryForm.status === 'VALID';
    }

    get name() {
        return this.countryForm.get('name');
    }
}


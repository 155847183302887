<div class="animated fadeIn">
  <div class="row">
    <div class="col-12 col-md-8">
      <div>
        <div style="width:100%">
          <input class="form-control" #filter placeholder="Filter" type="text">
        </div>
      </div>

      <mat-paginator [length]="database.data.length" [pageIndex]="0" [pageSize]="50"
        [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>

      <mat-table #table [dataSource]="dataSource" #sortFiles="matSort" matSort matSortActive="filetime"
        matSortDirection="desc">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="filename">
          <mat-header-cell *matHeaderCellDef mat-sort-header> File name </mat-header-cell>
          <mat-cell *matCellDef="let row"> <span [innerHTML]="row.filename"></span> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="filetime">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 20%"> Date </mat-header-cell>
          <mat-cell *matCellDef="let row" style="flex: 0 0 20%"> <span [innerHTML]="row.filetime"></span> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="filepath">
          <mat-header-cell *matHeaderCellDef mat-sort-header>File path </mat-header-cell>
          <mat-cell *matCellDef="let row"> <span [innerHTML]="row.filepath"></span> </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="text-center" style="flex: 0 0 15%"></mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-center" style="flex: 0 0 15%">
            <button type="button" class="btn btn-warning" (click)="getFileProductList(row.filename)">
              <i class="fa fa-info" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-primary" (click)="copyTextToClipboard(row.filepath)">
              <i class="fa fa-clone" aria-hidden="true"></i>
            </button>
            <a target="_blank" href="{{row.filepath}}" class="btn btn-primary" download="filename">
              <i class="fa fa-download" aria-hidden="true"></i>
            </a>
            <button type="button" class="btn btn-danger" (click)="remove(row.filename)">
              <i class="fa fa-trash"></i>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <div class="col-12 col-md-4">
      <user-file-uploader (onAllCompleted)="onFileUploaded()"></user-file-uploader>
    </div>
  </div>
</div>
<div [hidden]="!showModal" class="modal-backdrop fade in show" style="z-index: 1021 !important;"></div>

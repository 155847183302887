import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {UsersListComponent} from './users-list/users-list.component';
// import {QuillModule} from 'ngx-quill';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UsersRoutingModule} from './users-routing.module';
import {UsersService} from './users.service';
import {UsersAddComponent} from './users-add/users-add.component';
import {UsersEditComponent} from './users-edit/users-edit.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    UsersRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [UsersListComponent, UsersAddComponent, UsersEditComponent],
  providers: [
   UsersService, DatePipe
  ]
})
export class UsersModule { }


import {merge as observableMerge, fromEvent as observableFromEvent,  Observable ,  BehaviorSubject } from 'rxjs';
import {map, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfiguratorService } from '../configurator.service';
import { Configurator } from '../Configurator.model';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  templateUrl: './configurators-list.component.html'
})
export class ConfiguratorsListComponent implements OnInit {

  displayedColumns = ['id', 'name', 'actions'];
  dataSource: ConfiguratorsDatasource | null;
  database: ConfiguratorDatabase;
  modalRef: BsModalRef;
  configuratorId: number;
  typeFilterActive = false;

  @ViewChild('filter', {static: true}) public filter: ElementRef;
  @ViewChild(MatSort, {static: true}) public sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;

  constructor(private toastr: ToastrService, private router: Router,
    private configuratorService: ConfiguratorService, private modalService: BsModalService) {
  }

  ngOnInit(): void {
    this.database = new ConfiguratorDatabase(this.configuratorService);
    this.dataSource = new ConfiguratorsDatasource(this.database, this.paginator, this.sort)

    observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),)
      .subscribe(() => {
        if (!this.dataSource) { return; }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }


  redirectToEdit(configuratorId) {
    this.router.navigate(['./configurators/edit/' + configuratorId]);
  }


  onTypeChange(typeId) {
    const test = Number(typeId);
    this.dataSource.typeFilter = test;
    this.typeFilterActive = this.dataSource.typeFilter !== -1;
  }
}

export class ConfiguratorDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<Configurator[]> = new BehaviorSubject<Configurator[]>([]);
  get data(): Configurator[] { return this.dataChange.value; }

  constructor(private configuratorService: ConfiguratorService) {
    configuratorService.getConfigurators().subscribe(
      configurators => {
        this.dataChange.next(configurators);
      });
  }
}


export class ConfiguratorsDatasource extends DataSource<Configurator> {
  _filterChange = new BehaviorSubject('');
  get filter(): string { return this._filterChange.value; }
  set filter(filter: string) { this._filterChange.next(filter); }
  _typeFilterChange = new BehaviorSubject(-1);

  get typeFilter(): number {
    return this._typeFilterChange.value;
  }

  set typeFilter(typeId: number) {
    this._typeFilterChange.next(typeId);
  }
  prevFilterValue: string;

  constructor(private _database: ConfiguratorDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this.prevFilterValue = this.filter.toLowerCase();
  }

  connect(collectionViewer: CollectionViewer): Observable<Configurator[]> {
    const displayDataChanges = [
      this._database.dataChange,
      this._filterChange,
      this._sort.sortChange,
      this._typeFilterChange,
      this._paginator.page
    ];
    return observableMerge(...displayDataChanges).pipe(map(() => {

      const data = this._database.data.slice().filter((item: Configurator) => {
        if (item.name == null) {
          return false;
        }
        const searchStr = (item.name).toLowerCase();
        const searchResult = searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        return searchResult;
      });

      if (this.filter.toLowerCase() !== this.prevFilterValue) {
        this._paginator.pageIndex = 0;
      }
      this.prevFilterValue = this.filter.toLowerCase();

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }
  /** Returns a sorted copy of the database data. */
  sortData(data: Configurator[]): Configurator[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'id': [propertyA, propertyB] = [a.id, b.id]; break;
        case 'name': [propertyA, propertyB] = [a.name, b.name]; break;
        case 'city': [propertyA, propertyB] = [a.name, b.name]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

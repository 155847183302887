import {Injectable} from '@angular/core';

@Injectable()
export class CategoryConstantService {

  PROFILE: number = 1;
  ACCESSORIES: number = 2;
  LED_TAPES: number = 3;
  LAMPS: number = 4;
  ELECTRONICS: number = 5;
  COVERS: number = 6;
}

export class ApiGetPagesResponse {
  constructor(public data) {
  }
  public getData(): ApiPage[] {
    return this.data;
  }
}

export class ApiGetPageResponse {
  constructor(public data) {
  }

  public getData(): ApiPage {
    return this.data;
  }
}

export class ApiPage {
  constructor(public id: number,
              public name: string,
              public title: string,
              public meta_title: string,
              public meta_keywords: string,
              public meta_description: string,
              public content: string,
              public slug: string,
              public guid: string,
              public publicVisible: boolean) {
  }
}

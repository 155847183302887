<div class="animated fadeIn">
  <div class="example-container">
    <div class="example-header mb-1">
      <div class="container-fluid p-0">
      <div class="row">
        <div class="col-xs-12 col-md-4">
          <input class="form-control" #filter placeholder="Filter products" type="text">
        </div>
        <div class="col-xs-12 col-md-2">
          <select class="form-control" (change)="onActiveChange($event.target.value)">
            <option value="-1">All</option>
            <option value="1">Active</option>
            <option value="0">Non active</option>
          </select>
        </div>
        <div class="col-xs-12 col-md-4">
          <select class="form-control" (change)="onCategoryChange($event.target.value)">
            <option value="">Select category</option>
            <option *ngFor="let category of orderedCategories" [value]="category.id">{{category.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">

      </div>
      </div>

    </div>

    <mat-paginator #paginator [length]="dataSourceSize"
                   (page)="changePage($event)"
                   [pageIndex]="0" [pageSize]="30" [pageSizeOptions]="[30, 50, 100]">
    </mat-paginator>

    <mat-table #table [dataSource]="dataSource" matSort matSortActive="createdOn" matSortDirection="desc" (matSortChange)="sortData($event)">

      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Id</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef style="flex: 0 0 25%"> Name</mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex: 0 0 25%"><a [routerLink]="['/products/edit', row.id]">{{row.name}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="catalogueNumber">
        <mat-header-cell *matHeaderCellDef> Catalogue number</mat-header-cell>
        <mat-cell *matCellDef="let row"><a [routerLink]="['/products/edit', row.id]">{{row.catalogueNumber}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef> Category</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.category}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="modifiedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Modified on</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.modifiedOn | date : 'yyyy-MM-dd HH:mm'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="modifiedBy">
        <mat-header-cell *matHeaderCellDef> Modified by</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.modifiedBy }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Created on</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.createdOn | date : 'yyyy-MM-dd HH:mm'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="sortOrder">
        <mat-header-cell [ngClass]="{'hidden-column': !selectedCategoryId}" *matHeaderCellDef mat-sort-header style="flex: 0 0 5%"> Sort
          order
        </mat-header-cell>
        <mat-cell [ngClass]="{'hidden-column': !selectedCategoryId}" *matCellDef="let row" style="flex: 0 0 5%">
          <button class="btn btn-sm btn-primary"
                  type="button"
                  *ngIf="selectedCategoryId && selectedCategoryId == row.categoryId"
                  (click)="openSortModal(sortTemplate, row.id)">
            {{row.sortOrder}}<i class="pl-2 fa fa-sort" aria-hidden="true"></i>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef> Is active</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span *ngIf="row && row.isActive == 1" class="badge badge-success">Active</span>
          <span *ngIf="row && row.isActive == 0" class="badge badge-dark">Inactive</span>
        </mat-cell>
      </ng-container>
       <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="btn btn-sm btn-secondary mr-1" type="button"
          *ngIf="selectedCategoryId != null && selectedCategoryId == row.categoryId"
          (click)="sortUp(row.id)"
          [disabled]="dataSource.categoryFilter === -1">
            <i class="fa fa-chevron-up"></i>
          </button>
          <button class="btn btn-sm btn-secondary mr-1" type="button"
                  *ngIf="selectedCategoryId != null && selectedCategoryId == row.categoryId"
                  (click)="sortDown(row.id)"
                  [disabled]="dataSource.categoryFilter === -1">
            <i class="fa fa-chevron-down"></i>
          </button>
          <a class="btn btn-sm btn-primary b-0 mr-1" type="button" [routerLink]="['/products/edit', row.id]">
            <i class="fa fa-pencil"></i>
          </a>
          <button *ngIf="!isRestrictedUser" class="btn btn-sm btn-danger mr-1" type="button" (click)="openModal(template, row.id)">
            <i class="fa fa-trash"></i>
          </button>
  
          <button *ngIf="!row.isVirtualProduct" class="btn btn-sm btn-warning mr-1" type="button" (click)="copyProduct(row.id)" style="color:white !important;"><i class="fa fa-copy"></i></button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator #bottomPaginator [length]="dataSourceSize"
                   (page)="paginator.page.emit($event)"
                   (pageSize)="paginator.pageSize.emit($event)"
                   [pageIndex]="0" [pageSize]="30" [pageSizeOptions]="[30, 50, 100]">
    </mat-paginator>


  </div>
</div>
<ng-template #template>
  <div class="modal-body text-center">
    <p>Do you want to confirm?</p>
    <button type="button" class="btn btn-default" (click)="decline()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirm()">Yes</button>
  </div>
</ng-template>

<ng-template #sortTemplate>
  <form [formGroup]="productSortForm" (ngSubmit)="sortProduct()" novalidate #valueForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title">Enter product target position</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="sortPosition">Position</label>
        <input type="number" align="right" formControlName="sortPosition" id="sortPosition">
        <div *ngIf="sortPosition.invalid && (valueForm.submitted || sortPosition.dirty || sortPosition.touched)" class="alert alert-danger">
          <div *ngIf="sortPosition.errors.required">
            Target position is required.
          </div>
          <div *ngIf="sortPosition.errors.min">
            Value is to low.
          </div>
          <div *ngIf="sortPosition.errors.max">
            Value is to height.
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="modalRef.hide()">Cancel</button>
      <button type="submit" class="btn btn-primary">Save</button>
    </div>
  </form>
</ng-template>
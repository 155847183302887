
import {merge as observableMerge, fromEvent as observableFromEvent, BehaviorSubject, Observable} from 'rxjs';

import {map, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {Router} from "@angular/router";

import {ListCategory} from './list-category.model';
import {ProductService} from './../../api/product.service';
import {CategoryService} from 'app/views/products/categories/category.service';
import {ListProductCategory} from 'app/views/products/product-list/list-product-category.model';
import {ToastrService} from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-product-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  products: ListCategory[];

  displayedColumns = ['id', 'name', 'active', 'sortOrder', 'actions'];
  dataSource: CategoryDataSource | null;
  exampleDatabase: CategoryDatabase;
  orderedCategories: Observable<ListProductCategory[]>;
  selectedCategoryId: number;
  modalRef: BsModalRef;

  @ViewChild('filter', {static: true}) public filter: ElementRef;
  @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;
  private categoryId: number;

  constructor(private productService: ProductService, private categoryService: CategoryService, private toastr: ToastrService,
              private router: Router, private spinnerService: NgxSpinnerService, private modalService: BsModalService) {
  }

  ngOnInit() {
    this.spinnerService.show();
    this.getIntialData();
    this.orderedCategories = this.categoryService.getOrderedListProductCategories();
    observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),)
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  onCategoryChange(categoryId) {
    this.dataSource.categoryFilter = parseInt(categoryId);
    this.selectedCategoryId = categoryId;
  }

  getIntialData() {
    this.exampleDatabase = new CategoryDatabase(this.productService, this.categoryService, this.spinnerService);
    this.dataSource = new CategoryDataSource(this.exampleDatabase, this.paginator);
  }

  redirectToEdit(categoryId) {
    this.router.navigate(['./products/categories/edit/' + categoryId]);
  }

  sortUp(categoryId) {
    this.spinnerService.show();
    this.categoryService.categoriesFetch.next([]);
    this.productService.categorySortUp(categoryId).subscribe(() => {
      this.categoryService.getOrderedListProductCategories().subscribe(categories => {
        this.exampleDatabase.updateData(categories);
        this.spinnerService.hide();
      });
    });
  }

  sortDown(categoryId) {
    this.spinnerService.show();
    this.categoryService.categoriesFetch.next([]);
    this.productService.categorySortDown(categoryId).subscribe(() => {
      this.categoryService.getOrderedListProductCategories().subscribe(categories => {
        this.exampleDatabase.updateData(categories);
        this.spinnerService.hide();
      });
    });
  }


  openModal(template: TemplateRef<any>, categoryId: number) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
    this.categoryId = categoryId;
  }

  confirm(): void {
    this.productService.deleteCategory(this.categoryId)
      .subscribe(
        () => {
          this.toastr.success('Category removed successfully', '');
          this.deleteRowDataTable(this.categoryId);
        });
    this.modalRef.hide();
  }

  decline(): void {
    this.categoryId = null;
    this.modalRef.hide();
  }

  private deleteRowDataTable(itemId) {
    const dsData = this.exampleDatabase.data;
    const itemIndex = dsData.findIndex(obj => obj['id'] === itemId);
    dsData.splice(itemIndex, 1)
    this.exampleDatabase.dataChange.next(dsData);
  }
}

export class CategoryDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<ListProductCategory[]> = new BehaviorSubject<ListProductCategory[]>([]);

  get data(): ListProductCategory[] {
    return this.dataChange.value;
  }

  updateData(categories) {
    this.dataChange.next(categories);
  }

  constructor(private productService: ProductService, private categoryService: CategoryService,
              private spinnerService: NgxSpinnerService) {
    productService.getCategories().subscribe(
      categories => {

        for (const category of categories) {
          category.name = this.categoryService.updateCategoryName('---', category, categories) + ' ' + category.name;
        }

        const orderedCategories = this.categoryService.getOrderedCategories(categories);
        this.dataChange.next(orderedCategories);

        this.spinnerService.hide();
      });
  }
}

export class CategoryDataSource extends DataSource<ListProductCategory> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  prevFilterValue: string;

  _categoryFilterChange = new BehaviorSubject(-1);

  get categoryFilter(): number {
    return this._categoryFilterChange.value || -1;
  }

  set categoryFilter(categoryId: number) {
    this._categoryFilterChange.next(categoryId);
  }

  constructor(private _exampleDatabase: CategoryDatabase, private _paginator: MatPaginator) {
    super();
    this.prevFilterValue = this.filter.toLowerCase();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<ListProductCategory[]> {
    const displayDataChanges = [
      this._exampleDatabase.dataChange,
      this._filterChange,
      this._categoryFilterChange,
      this._paginator.page
    ];

    return observableMerge(...displayDataChanges).pipe(map(() => {

      const data = this._exampleDatabase.data.slice().filter((item: ListProductCategory) => {
        const categoryResult = this.categoryFilter === -1 || item.parentCategoryId === this.categoryFilter;
        if (item.name == null) {
          return false;
        }
        const searchStr = (item.name).toLowerCase();
        const searchResult = searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        return searchResult && categoryResult;
      });

      if (this.filter.toLowerCase() !== this.prevFilterValue) {
        this._paginator.pageIndex = 0;
      }
      this.prevFilterValue = this.filter.toLowerCase();

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return data.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect() {
  }
}
import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ProductRelatedFeature} from '../../product-related-feature.model';
import {ProductRelatedFeatureValue} from '../../product-related-feature-value';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-range-feature',
  templateUrl: './range-feature.component.html'
})
export class RangeFeatureComponent implements OnInit {


  @Input()
  productRelatedFeature: ProductRelatedFeature;

  @Output() featureItemChanged: EventEmitter<ProductRelatedFeatureValue> = new EventEmitter();

  @ViewChild('featureItemValueTemplate') featureItemValueTemplate: TemplateRef<any>;

  featureItemValueForm: FormGroup;
  modalRef: BsModalRef;
  selectedItem: ProductRelatedFeatureValue;

  constructor(private fb: FormBuilder, private modalService: BsModalService) {
  }

  ngOnInit() {
    this.selectedItem = this.productRelatedFeature.items
      .find(item => item.selected);

    const min = this.productRelatedFeature.items
      .map(item => item.featureItem.minIntValue)
      .reduce((a, b) => (a === null || a < b) ? a : b);

    const max = this.productRelatedFeature.items
      .map(item => item.featureItem.maxIntValue)
      .reduce((a, b) => (b === null || a < b) ? b : a);

    const validators: any = [Validators.required];
    if (min != null) {
      validators.push(Validators.min(min));
    }
    if (max != null) {
      validators.push(Validators.max(max));
    }

    this.featureItemValueForm = this.fb.group({
      featureValue: ['', validators]
    });
  }

  changeTriggered(event) {
    if (this.selectedItem == null || !this.selectedItem.selected) {
      event.preventDefault();
      this.modalRef = this.modalService.show(this.featureItemValueTemplate, {class: 'modal-sm'});
    } else {
      this.selectedItem.selected = false;
      this.selectedItem.value = null;
      this.featureItemChanged.emit(this.selectedItem);
      this.selectedItem = null;
    }
  }

  setFeatureItemValue() {
    if (this.isValidForm()) {
      const featureValue = this.featureItemValueForm.value.featureValue;

      const featureItem: ProductRelatedFeatureValue = this.productRelatedFeature.items
        .find(item => (
          (item.featureItem.minIntValue == null || featureValue >=  item.featureItem.minIntValue)
          && (item.featureItem.maxIntValue == null || featureValue <=  item.featureItem.maxIntValue)
        ));
      featureItem.value = featureValue;
      featureItem.selected = true;
      this.selectedItem = featureItem;
      this.featureItemChanged.emit(this.selectedItem);
      this.modalRef.hide();
    }
  }

  isValidForm() {
    return this.featureItemValueForm.status === 'VALID';
  }

  get featureValue() {
    return this.featureItemValueForm.get('featureValue');
  }
}

import { AddTeam, TraderPosition } from './../Distributor.model';
import { AddDistributor } from './../distributor-add/AddDistributor.model';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DistributorService } from '../distributor.service';
import { Country } from '../country/country.model';
import { CountryService } from '../country/country.service';
import { Distributor } from '../Distributor.model';
import { DatePipe, Location } from '@angular/common';
import { AddDistributorUser } from '../user-add/AddDistributorUser.model';
import { User } from 'app/user/user.model';

@Component({
  selector: 'helpers-edit',
  templateUrl: './helpers-edit.component.html',
  styleUrls: ['./helpers-edit.component.scss']
})
export class HelpersEditComponent {

  userForm: FormGroup;
  userId: number;
  user: AddTeam;
  roles: any[];
  regions: any[];

  constructor(private fb: FormBuilder, private distributorService: DistributorService, private router: Router,
    private route: ActivatedRoute, private toastr: ToastrService,
    private datePipe: DatePipe, private location: Location) {
    this.createForm();
    this.route.params.subscribe(params => {
      this.userId = parseInt(params['id']);
      distributorService.getTeamRoles().subscribe(
        roles => {
          this.roles = roles
          distributorService.getTeamRegions().subscribe(
            regions => {
              this.regions = regions
              distributorService.getTeamUser(this.userId).subscribe(
                user => {
                  this.user = user;
                  this.setFormValues();
                });
            }
          );
        }
      );
    });
  }

  onSubmit() {
    if (this.isValidForm()) {
      const user = this.prepareTeamEdit();
      this.distributorService.updateTeamUser(this.userId, user)
        .subscribe(
          () => {
            this.toastr.success('User updated successfully');
            this.router.navigate(['./distributors/helper/list']);
          });
    }
  }

  onCancel() {
    this.location.back();
  }

  createForm() {
    this.userForm = this.fb.group({
      is_active: [''],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      roles_ids: ['', Validators.required],
      regions_ids: [''],
      phone: '',
      mobile: '',
      order: '',
    });
  }

  prepareTeamEdit(): any {
    const formModel = this.userForm.value;

    const distributor: any = {
      id: this.userId,
      name: formModel.name as string,
      is_active: formModel.is_active as boolean,
      surname: formModel.surname as string,
      email: formModel.email as string,
      phone: formModel.phone as string,
      mobile: formModel.mobile as string,
      order: formModel.order as number,
      roles_ids: formModel.roles_ids as number[],
      regions_ids: formModel.regions_ids as number[],
    };

    return distributor;
  }

  isValidForm() {
    return this.userForm.status === 'VALID';
  }

  get is_active() {
    return this.userForm.get('is_active');
  }

  get name() {
    return this.userForm.get('name');
  }

  get surname() {
    return this.userForm.get('surname');
  }

  get email() {
    return this.userForm.get('email');
  }

  get number() {
    return this.userForm.get('number');
  }

  get phone() {
    return this.userForm.get('phone');
  }

  get mobile() {
    return this.userForm.get('mobile');
  }

  get roles_ids() {
    return this.userForm.get('roles_ids');
  }

  get regions_ids() {
    return this.userForm.get('regions_ids');
  }

  private setFormValues() {
    this.userForm.setValue({
      name: this.user.name as string,
      surname: this.user.surname as string,
      phone: this.user.phone as string,
      mobile: this.user.mobile as string,
      email: this.user.email as string,
      order: this.user.order as number,
      is_active: this.user.is_active as boolean,
      roles_ids: this.user.roles_ids as number[],
      regions_ids: this.user.regions_ids as number[],
    })
  }
}


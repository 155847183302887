export class FeatureItem {
  constructor(
    public id: number,
    public bindingValue: any,
    public name: string,
    public image: EditFeatureImage,
    public featureId: number,
    public minIntValue: number,
    public maxIntValue: number,
    public isActive: boolean,
    public isVisibleOnSite: boolean,
    public isVisibleOnConfigurator: boolean,
    public sortOrder: number) {
  }
}


export class EditFeatureImage {
  constructor(public id: number,
    public filename: string,
    public name: string,
    public directoryGuid: string,
    public thumbFilename: string) {
  }
}
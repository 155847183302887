
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from '../../error.service';
import { User } from '../../user/user.model';
import { UserService } from '../../user/user.service';
import { Observable } from 'rxjs';
import { Page } from './page/list-page.component/Page.model';
import { ApiGetPageResponse, ApiGetPagesResponse } from './api/api-page-responses.model';
import { AddPage } from './page/add-page-component/AddPage.model';
import { AddSlider } from './slider/add/AddSlider.model';
import { Application, ApplicationGroup } from './applications/list-applications/application.model';
import { ApiApplication, ApiApplicationGroup, ApiFilesAssociatedProducts } from './api/api-applications-response.model';
import { File } from './uploader/file-list/file-list.component';
import { ApiGetCssResponse } from './api/api-css-response.model';
import { CssCode } from './css/css.model';
import { SitePopup } from './site-popup/site-popup.model';
import { ApiGetSitePopup } from './api/api-site-popup.mode';

@Injectable()
export class ContentService {



  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  updateCssCode(code: any) {
    return this.http
      .put(this.apiUrl + '/css-code/update', JSON.stringify(code), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateCssCode')));
  }

  updatePopup(popup: any) {
    return this.http
    .put(this.apiUrl + '/site-popup/update?langId=' + this.user.languageId, JSON.stringify(popup), { headers: this.headers }).pipe(
    catchError(this.errorService.handleError<any>('updatePopup')));
  }


  addPage(addPage: AddPage) {
    return this.http
      .post(this.apiUrl + '/content?langId=' + this.user.languageId, JSON.stringify(addPage), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('addNews')));
  }

  addSlider(addSlider: AddSlider) {
    return this.http
      .post(this.apiUrl + '/content?langId=' + this.user.languageId, JSON.stringify(addSlider), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('addNews')));
  }

  getPage(pageId: number) {
    return this.http.get(this.apiUrl + '/content/' + pageId + '?langId=' + this.user.languageId).pipe(
      map((response) => {
        const page = (new ApiGetPageResponse(response)).getData();
        return new Page(page.id, page.name, page.title, page.meta_title, page.meta_keywords, page.meta_description, page.content, page.slug, page.guid,
          page.publicVisible != null ? page.publicVisible : false);
      }),
      catchError(this.errorService.handleError<any>('getPage')),);
  }

  getCssCode(): Observable<CssCode> {
    return this.http.get<ApiGetCssResponse>(this.apiUrl + '/css-code/edit').pipe(
      map((response) => {
        return response.data;
      }),
      catchError(this.errorService.handleError<any>('getCssCode')));
  }

  getSitePopup(): Observable<SitePopup> {
    return this.http.get<ApiGetSitePopup>(this.apiUrl + '/site-popup/edit?langId=' + this.user.languageId).pipe(
      map((response) => {
        return response.data;
      }),
      catchError(this.errorService.handleError<any>('getSitePopup')));

  }


  getPages(): Observable<Page[]> {
    return this.http.get(this.apiUrl + '/content?langId=' + this.user.languageId).pipe(
      map((response) => {
        const listPages: Page[] = [];
        for (const page of (new ApiGetPagesResponse(response)).getData()) {
          const row = new Page(
            page.id, page.name, page.title, page.meta_title, page.meta_keywords, page.meta_description, page.content, page.slug, page.guid, page.publicVisible != null ? page.publicVisible : false);
          listPages.push(row);
        }
        return listPages;
      }),
      catchError(this.errorService.handleError<any>('getPages')),);
  }

  updatePage(pageId: number, page: Page) {
    return this.http
      .put(this.apiUrl + '/content/' + pageId + '?langId=' + this.user.languageId,
        JSON.stringify(page), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updatePage')));
  }

  deletePage(pageId: number) {
    return this.http
      .delete(this.apiUrl + '/content/' + pageId + '?langId=' + this.user.languageId, { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('deletePage')));
  }

  deleteApplication(applicationId: number) {
    return this.http.delete(this.apiUrl + '/usageItem/' + applicationId, { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('deleteDistributor')));
  }

  getApplicationGroups() {
    return this.http.get<ApiApplicationGroup[]>(this.apiUrl + '/usages?langId=' + this.user.languageId).pipe(
      map((response) => {
        const listApplicationGroups: ApplicationGroup[] = [];

        for (const applicationGroup of response) {
          const applications: Application[] = [];
          for (const application of applicationGroup.items) {
            applications.push({
              id: application.id,
              title: application.title,
              slug: application.slug,
              content: application.content,
              galleryId: application.gallery_id,
              applicationGroupId: application.usage_id,
              sortOrder: application.sortOrder
            })
          }
          const row = new ApplicationGroup(applicationGroup.id, applicationGroup.title, applicationGroup.content, applicationGroup.slug, applications);
          listApplicationGroups.push(row);
        }
        return listApplicationGroups;
      }),
      catchError(this.errorService.handleError<any>('getApplications')),);
  }

  addApplicationGroup(applicationGroup: ApplicationGroup) {
    return this.http
      .post(this.apiUrl + '/usages?langId=' + this.user.languageId, JSON.stringify(applicationGroup), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('addNews')));
  }

  getApplicationGroup(applicationGroupId: number): Observable<ApplicationGroup> {
    return this.http.get<ApiApplicationGroup>(this.apiUrl + '/usages/' + applicationGroupId + '?langId=' + this.user.languageId).pipe(
      map((response) => {
        const applications: Application[] = [];
        for (const application of response.items) {
          applications.push({
            id: application.id,
            title: application.title,
            slug: application.slug,
            content: application.content,
            galleryId: application.gallery_id,
            applicationGroupId: application.usage_id,
            sortOrder: application.sortOrder
          })
        }
        return new ApplicationGroup(response.id, response.title, response.content, response.slug, applications);
      }),
      catchError(this.errorService.handleError<any>('getApplicationGroup')),);
  }

  updateApplicationGroup(id: number, data: { title: string; content: string }) {
    return this.http.put<ApiApplicationGroup>(this.apiUrl + '/usages/' + id + '?langId=' + this.user.languageId,
      JSON.stringify(data), { headers: this.headers }).pipe(
      map((response) => {
        return new ApplicationGroup(response.id, response.title, response.content, response.slug, null);
      }))
  }

  getAssociatedProductsByFilename(fileName: string): any {
    return this.http.post<ApiFilesAssociatedProducts>(this.apiUrl + '/products/getByFiles', { filename: fileName }, { headers: this.headers }).pipe(map(response => {
      return response
    }),catchError(this.errorService.handleError<any>('getAssociatedProductsByFilename')),);
  }

  addApplication(applicationGroupId: number, title: string): Observable<Application> {
    return this.http.post<ApiApplication>(this.apiUrl + '/usages/' + applicationGroupId + '/item?langId=' + this.user.languageId,
      JSON.stringify({ title: title }), { headers: this.headers }).pipe(
      map((application) => {
        return {
          id: application.id,
          title: application.title,
          content: application.content,
          galleryId: application.gallery_id,
          applicationGroupId: application.usage_id,
          sortOrder: application.sortOrder
        }
      }),
      catchError(this.errorService.handleError<any>('getApplication')),);
  }

  updateApplication(applicationId: number, data: { title: string; content: string, slug: string }) {
    return this.http
      .put(this.apiUrl + '/usageItem/' + applicationId + '?langId=' + this.user.languageId,
        JSON.stringify(data), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateApplication')));
  }

  getContentFiles(): Observable<File[]> {
    return this.http.get(this.apiUrl + '/contentfiles?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getContentFiles')));
  }

  deleteContentFile(fileName: string) {
    if (confirm('Do you really want to delete this file ? (related product documents may be deleted)')) {
      return this.http
        .post(this.apiUrl + '/contentfiles', { filename: fileName }, { headers: this.headers }).pipe(
        catchError(this.errorService.handleError<any>('deleteDistributor')));
    }
  }
}

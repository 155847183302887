import { Component, ElementRef, OnInit } from "@angular/core";
import { UserService } from "../../user/user.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver";
@Component({
  selector: "app-sidebar",
  templateUrl: "./app-sidebar.component.html",
})
export class AppSidebar implements OnInit {
  constructor(
    private el: ElementRef,
    private userService: UserService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const nativeElement: HTMLElement = this.el.nativeElement,
      parentElement: HTMLElement = nativeElement.parentElement;
    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }
    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);
  }

  isAdmin(): boolean {
    return this.userService.isAdmin();
  }

  isRestricted(): boolean {
    return this.userService.isRestricted();
  }

  getRaport() {
    return this.http
      .get(environment.apiUrl + "/trader/raport")
      .subscribe(
        (data:any) => {
          const separator = '|';
          const keys = Object.keys(data[0]);
          const csvContent =
            keys.join(separator) +
            '\n' +
            data.map(row => {
              return keys.map(k => {
                let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                cell = cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');
                if (cell.search(/("|,|\n)/g) >= 0) {
                  cell = `"${cell}"`;
                }
                return cell;
              }).join(separator);
            }).join('\n');

          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          saveAs(blob, "traders_raport.csv");
        },
        (error) => {
          console.error("There was an error!", error);
        }
      );
  }
}

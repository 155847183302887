
import {fromEvent as observableFromEvent,  Observable } from 'rxjs';

import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { EditProductImage } from 'app/views/products/edit-product/edit-product.model';
import { FineUploader } from 'fine-uploader';
import { ProductService } from 'app/views/products/api/product.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../user/user.service';
import { User } from '../../../../user/user.model';
import { AuthenticationService } from '../../../../auth/authentication.service';
import { Helpers } from '../../../../helpers/helpers.service';
import { FileDatasource, FileDatabase } from 'app/views/content/uploader/file-list/file-list.component';
import { ContentService } from 'app/views/content/content.service';
import { environment } from '../../../../../environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'album-documents',
  templateUrl: './album-documents.component.html',
  styleUrls: ['./album-documents.component.scss']
})
export class AlbumDocumentsComponent implements OnInit, AfterViewInit {

  @Input()
  divId: string;

  @Input()
  fileType: number;

  @Input()
  images: EditProductImage[];

  @Input()
  productId: number;

  @Input()
  productCard: boolean;

  user: User;
  editFileForm: FormGroup;
  uploader: FineUploader;
  imagesAllowedExtensions = ['jpeg', 'jpg', 'gif', 'png'];
  documentsAllowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'rtf', 'html', 'zip', 'rar', 'svg'];
  directoryGuid?: string;
  filenameToDelete?: string;
  modalRef: BsModalRef;
  fileId: number;
  fileName: string;
  helpers: any;
  product: any;

  canSee = false;

  displayedColumns = ['filename', 'filetime', 'filepath', 'actions'];
  dataSource: FileDatasource | null;
  database: FileDatabase;

  @ViewChild('filter',{static: true}) public filter: ElementRef;

  @ViewChild(MatSort, {static: true}) public sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;

  constructor(private productService: ProductService, private userService: UserService, private modalService: BsModalService,
    private toastr: ToastrService, private fb: FormBuilder, private spinnerService: NgxSpinnerService,
    private authService: AuthenticationService, private contentService: ContentService) {

    this.editFileForm = this.fb.group({
      name: ['', Validators.required]
    });

    this.helpers = new Helpers();

  }
  attachToProduct(fileObject: any) {
    this.spinnerService.show();
    this.hide(true);
    this.productService.attachDocumentFileToProduct(fileObject, this.productId).subscribe(() => {
      this.productService.getProduct(this.productId).subscribe(
        product => {
          (this.images as any) = product.documents;
          this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
          this.spinnerService.hide();
        });
    });
  }

  show(e: any) {
    this.canSee = true;
  }

  hide(e: any) {
    this.canSee = false;
  }
  ngOnInit(): void {
    this.reloadLastModifieds();
    this.database = new FileDatabase(this.contentService);
    this.dataSource = new FileDatasource(this.database, this.paginator, this.sort)

    if (this.filter) {
      observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
        debounceTime(150),
        distinctUntilChanged(),)
        .subscribe(() => {
          if (!this.dataSource) {
            return;
          }
          this.dataSource.filter = this.filter.nativeElement.value;
        });
    }
  }
  onFileUploaded() {
    this.contentService.getContentFiles().subscribe(
      files => {
        this.database.updateData(files);
      });
  }

  remove(filename: string): void {
    this.spinnerService.show();
    this.contentService.deleteContentFile(filename)
      .subscribe(() => {
        this.toastr.success('File deleted successfully', '');
        this.deleteRowDataTable(filename);
        this.productService.getProduct(this.productId).subscribe(
          product => {
            (this.images as any) = product.documents;
            this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
            this.spinnerService.hide();
          });
      }
      )
  }

  deleteRowDataTable(filename) {
    const dsData = this.database.data;
    const itemIndex = dsData.findIndex(obj => obj['filename'] === filename);
    dsData.splice(itemIndex, 1)
    this.database.dataChange.next(dsData);
  }
  copyTextToClipboard(text) {
    const txtArea = document.createElement('textarea');

    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;
    document.body.appendChild(txtArea);
    txtArea.select();
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      this.toastr.success('File path copied to the clipboard');
      if (successful) {
        return true;
      }
    } catch (err) {
      this.toastr.success('Ups! Something went wrong :(');
    }
    document.body.removeChild(txtArea);
    return false;
  }

  ngAfterViewInit() {
    this.getInitialData();
  }

  getInitialData() {
    this.userService.getUser().subscribe(user => {
      this.user = user;
      this.uploader = this.configureUploader(this.divId, this);
    })
    this.contentService.getContentFiles().subscribe(
      files => {
        this.database.updateData(files);
      });
  }

  configureUploader(elementId: string, that: any) {
    const uri: string = environment.apiUrl + '/uploader';
    return new FineUploader({
      element: document.getElementById(elementId),
      autoUpload: true,
      request: {
        endpoint: uri,
        customHeaders: {
          'Authorization': `Bearer ${that.authService.getToken()}`
        }
      },
      validation: {
        allowedExtensions: that.imagesAllowedExtensions.concat(that.documentsAllowedExtensions)
      },

      callbacks: {
        onSubmit: function (options) {
          this.setParams({ isContentFile: true });
          that.spinnerService.show();
        },
        onAllComplete: function (succeeded, failed) {
          this.reset();
          if (succeeded) {
            that.show(true);
            that.productService.getProduct(that.productId).subscribe(
              product => {
                that.images = product.documents;
                that.helpers.setHistoryRecords(that.userService.isRestricted(), product);
                that.spinnerService.hide();
              });

            that.contentService.getContentFiles().subscribe(
              files => {
                that.database.updateData(files);
              });
          }
        }
      }
    });
  }

  openDeleteModal(template: TemplateRef<any>, directoryGuid: string, filename: string) {
    this.directoryGuid = directoryGuid;
    this.filenameToDelete = filename;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  declineDelete(): void {
    this.modalRef.hide();
    this.directoryGuid = '';
    this.filenameToDelete = '';
  }
  confirmDelete(): void {
    if (this.directoryGuid === 'content_files') {
      this.productService.detachDocumentFileFromProduct(this.filenameToDelete, this.directoryGuid, this.productId).subscribe(
        response => {
          if (response.success === true) {
            this.toastr.success('Image removed successfuly', '');
            this.images = this.images.filter(item => (item.name !== this.filenameToDelete));
            this.productService.getProduct(this.productId).subscribe(
              product => {
                this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
                this.spinnerService.hide();
              });
          } else {
            this.toastr.error('Internal server error', '');
          }
          this.directoryGuid = '';
          this.filenameToDelete = '';
        },
        error => {
        },
        () => {
        }
      );
    } else {
      this.productService.deleteFile(this.directoryGuid, this.fileType)
        .subscribe(
          response => {
            if (response.success === true) {
              this.toastr.success('Image removed successfuly', '');
              this.images = this.images.filter(item => item.directoryGuid !== this.directoryGuid);
              this.productService.getProduct(this.productId).subscribe(
                product => {
                  this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
                  this.spinnerService.hide();
                });
            } else {
              this.toastr.error('Internal server error', '');
            }
            this.directoryGuid = '';
            this.filenameToDelete = '';
          },
          error => {
          },
          () => {

          });
    }
    this.modalRef.hide();
  }

  openEditModal(template: TemplateRef<any>, fileId: number, fileName: string) {
    this.fileId = fileId;
    this.fileName = fileName;

    this.editFileForm.setValue({
      name: this.fileName
    });
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirmEdit(): void {
    this.fileName = this.editFileForm.value.name;
    this.productService.updateFile(this.fileId, this.fileType, this.fileName)
      .subscribe(
        response => {
          this.toastr.success('File renamed successfuly', '');
          this.images.find(item => item.id === this.fileId).name = this.fileName;
          this.fileId = null;
          this.fileName = null;

          this.productService.getProduct(this.productId).subscribe(
            product => {
              this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
              this.spinnerService.hide();
            });

        },
        error => {
        },
        () => {

        });

    this.modalRef.hide();
  }

  declineEdit(): void {
    this.modalRef.hide();
    this.fileId = null;
    this.fileName = null;
  }

  moveBefore(index: number) {

    if (Number(this.fileType) === 3) {
      return this.moveDocumentBefore(index);
    }

    if (index !== 0) {
      this.spinnerService.show();
      const image = this.images[index];
      const reference = this.images[index - 1];
      this.productService.imageMoveBefore(image.id, reference.id, this.fileType).subscribe(data => {
        this.images = data;
        this.spinnerService.hide();
        this.reloadLastModifieds();
      });
    }
  }

  moveAfter(index: number) {

    if (Number(this.fileType) === 3) {
      return this.moveDocumentAfter(index);
    }
    if (index + 1 < this.images.length) {
      this.spinnerService.show();
      const image = this.images[index];
      const reference = this.images[index + 1];
      this.productService.imageMoveAfter(image.id, reference.id, this.fileType).subscribe(data => {
        this.images = data;
        this.reloadLastModifieds();
      });
    }
  }

  moveDocumentBefore(index: number) {
    if (index !== 0) {
      this.spinnerService.show();
      const image = this.images[index];
      const reference = this.images[index - 1];
      this.productService.documentMoveBefore(image.id, reference.id).subscribe(data => {
        this.images = data;
        this.reloadLastModifieds();
      });
    }
  }

  moveDocumentAfter(index: number) {
    if (index + 1 < this.images.length) {
      this.spinnerService.show();
      const image = this.images[index];
      const reference = this.images[index + 1];
      this.productService.documentMoveAfter(image.id, reference.id).subscribe(data => {
        this.images = data;
        this.reloadLastModifieds();
      });
    }
  }

  preventProductCardGenerationChange(event) {
    this.productService.preventProductCardGeneration(this.productId, !event.target.checked).subscribe(() => {
      this.spinnerService.hide();
      this.reloadLastModifieds();
    });
  }

  reloadLastModifieds() {
    this.productService.getProduct(this.productId).subscribe(
      product => {
        this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
        this.spinnerService.hide();
      });
  }
}

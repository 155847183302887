<form
  [formGroup]="userForm"
  (ngSubmit)="onSubmit()"
  novalidate
  #dform="ngForm"
>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col mb-2">
        <span class="mr-2">Active </span>
        <label class="switch switch-lg switch-3d switch-primary">
          <input
            type="checkbox"
            class="switch-input"
            formControlName="is_active"
          />
          <span class="switch-label"></span>
          <span class="switch-handle"></span>
        </label>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-lg-6">
                <div>




                <div class="mt-4">

                  <div class="form-group">
                    <label for="lead">Main image</label>
                    <div class="row">
                      <div class="col-6"></div>
                      <div class="col-6"></div>
                    </div>
                    <div id="qq-template" hidden>
                      <div
                        class="qq-uploader-selector qq-uploader"
                        qq-drop-area-text="Drop files here"
                      >
                        <div
                          class="qq-total-progress-bar-container-selector qq-total-progress-bar-container"
                        >
                          <div
                            role="progressbar"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            class="qq-total-progress-bar-selector qq-progress-bar qq-total-progress-bar"
                          ></div>
                        </div>
                        <div
                          class="qq-upload-drop-area-selector qq-upload-drop-area"
                          qq-hide-dropzone
                        >
                          <span class="qq-upload-drop-area-text-selector"></span>
                        </div>
                        <div class="buttons">
                          <div class="qq-upload-button-selector qq-upload-button">
                            <div>Select file</div>
                          </div>
                        </div>
                        <span class="qq-drop-processing-selector qq-drop-processing">
                          <span>Processing dropped files...</span>
                          <span
                            class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"
                          ></span>
                        </span>
                        <ul
                          class="qq-upload-list-selector qq-upload-list"
                          aria-live="polite"
                          aria-relevant="additions removals"
                        >
                          <li>
                            <div class="qq-progress-bar-container-selector">
                              <div
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                class="qq-progress-bar-selector qq-progress-bar"
                              ></div>
                            </div>
                            <span
                              class="qq-upload-spinner-selector qq-upload-spinner"
                            ></span>
                            <img
                              class="qq-thumbnail-selector"
                              qq-max-size="100"
                              qq-server-scale
                            />
                            <span
                              class="qq-upload-file-selector qq-upload-file"
                            ></span>
                            <span
                              class="qq-edit-filename-icon-selector qq-edit-filename-icon"
                              aria-label="Edit filename"
                            ></span>
                            <input
                              class="qq-edit-filename-selector qq-edit-filename"
                              tabindex="0"
                              type="text"
                            />
                            <span
                              class="qq-upload-size-selector qq-upload-size"
                            ></span>
                            <span
                              role="status"
                              class="qq-upload-status-text-selector qq-upload-status-text"
                            ></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <helpers-image
                    *ngIf="user"
                    divId="team-logo"
                    (eventImage)="setMainImage($event)"
                    [image]="user.main_image_src"
                    [userId]="userId"
                    fileType="3"
                    >
                    </helpers-image>
                  </div>
                </div>
                </div>
              </div>
              <div class="form-group col-lg-6">

              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                  <label for="info">Roles</label>
                  <select class="form-control" formControlName="roles_ids" multiple="multiple" required>
                      <option *ngFor="let role of roles" [value]="role.id">{{role.title}}</option>
                  </select>
                  <div *ngIf="roles_ids.invalid && (roles_ids.dirty || roles_ids.touched || dform.submitted)" class="alert alert-danger">
                      <div *ngIf="roles_ids.errors.required">
                          Role is required.
                      </div>
                  </div>
              </div>
              <div class="form-group col-lg-6">
                  <label for="info">Regions</label>
                  <select class="form-control" formControlName="regions_ids" multiple="multiple">
                      <option value="0">No region</option>
                      <option *ngFor="let region of regions" [value]="region.id">{{region.title}}</option>
                  </select>
                  <div *ngIf="regions_ids.invalid && (regions_ids.dirty || regions_ids.touched || dform.submitted)" class="alert alert-danger">
                      <div *ngIf="regions_ids.errors.required">
                          Region is required.
                      </div>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                />
                <div
                  *ngIf="
                    name.invalid &&
                    (name.dirty || name.touched || dform.submitted)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="name.errors.required">Name is required.</div>
                </div>
              </div>

              <div class="form-group col-sm-6">
                <label for="surname">Surname</label>
                <input
                  type="text"
                  class="form-control"
                  id="surname"
                  formControlName="surname"
                />
                <div
                  *ngIf="
                    surname.invalid &&
                    (surname.dirty || surname.touched || dform.submitted)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="surname.errors.required">
                    Surname is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="name">Tel</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  formControlName="phone"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="name">Mobile</label>
                <input
                  type="text"
                  class="form-control"
                  id="mobile"
                  formControlName="mobile"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="email">email</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="email">order</label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="order"
                  formControlName="order"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-sm btn-warning"
              (click)="onCancel()"
            >
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="fa fa-dot-circle-o"></i> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

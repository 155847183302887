<div class="animated fadeIn">
  <div class="example-container">
    <div class="example-header">
      <div style="width:100%">
        <input class="form-control" #filter placeholder="Filter" type="text">
      </div>
    </div>

    <mat-paginator #paginator [length]="database.data.length" [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <mat-table #table [dataSource]="dataSource" matSort>

      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> <span [innerHTML]="row.name"></span> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button type="button" class="btn btn-primary mr-1" (click)="redirectToEdit(row.id)">
            Edit
          </button>
          <button type="button" class="btn btn-danger" (click)="openModal(template, row.id)">
            Delete
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>Do you want to confirm?</p>
    <button type="button" class="btn btn-default" (click)="confirm()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="decline()">No</button>
  </div>
</ng-template>
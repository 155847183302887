import { AfterViewInit, Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { EditFeatureImage } from 'app/views/products/features/featureItem.model';
import { FineUploader } from 'fine-uploader';
import { environment } from '../../../../../../environments/environment';
import { UserService } from '../../../../../user/user.service';
import { User } from '../../../../../user/user.model';
import { AuthenticationService } from '../../../../../auth/authentication.service';
import { ProductService } from 'app/views/products/api/product.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'edit-feature-item-image',
  templateUrl: './edit-feature-item-image.component.html',
  styleUrls: ['./edit-feature-item-image.component.scss']
})
export class EditFeatureItemImageComponent implements AfterViewInit {

  @Input()
  divId: string;

  @Input()
  fileType: number;


  @Input() image: EditFeatureImage;

  @Output() notify: EventEmitter<EditFeatureImage> = new EventEmitter<EditFeatureImage>();


  @Input()
  featureItemId: number;

  @Input()
  featureId: number

  @Input()
  languageId: number;


  user: User;
  uploader: FineUploader;
  imagesAllowedExtensions = ['jpeg', 'jpg', 'gif', 'png', 'svg'];
  directoryGuid
  modalRef: BsModalRef;




  constructor(private productService: ProductService, private userService: UserService, private spinnerService: NgxSpinnerService,
    private modalService: BsModalService, private toastr: ToastrService,
    private authService: AuthenticationService) {

  }

  ngAfterViewInit() {
    this.getInitialData();

  }

  getInitialData() {
    this.userService.getUser().subscribe(user => {
      this.user = user;
      this.uploader = this.configureUploader(this.divId, this);
    })

  }

  configureUploader(elementId: string, that: any) {

    return new FineUploader({
      element: document.getElementById(elementId),
      autoUpload: true,
      multiple: false,
      request: {
        endpoint: environment.apiUrl + '/uploader',
        customHeaders: {
          'Authorization': `Bearer ${that.authService.getToken()}`
        },
        params: {
          fileType: that.fileType,
          featureItemId: that.featureItemId,
          languageId: that.user.languageId
        }
      },
      validation: {
        allowedExtensions: that.imagesAllowedExtensions
      },
      callbacks: {
        onSubmit: function (options) {
          that.spinnerService.show();
        },
        onAllComplete: function (succeeded, failed) {
          that.productService.getFeature(that.featureId).subscribe(
            feature => {
              switch (parseInt(that.fileType)) {
                case 10:
                  that.image = feature.items.filter(a => a.id === that.featureItemId)[0].image;
                  break;
                default:
                  break;
              }
              this.reset();
              that.notify.emit(feature.items.filter(a => a.id === that.featureItemId)[0].image);
              that.spinnerService.hide();
            });
        }
      }
    });
  }


  openDeleteModal(template: TemplateRef<any>, directoryGuid: string) {
    this.directoryGuid = directoryGuid;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  declineDelete(): void {
    this.modalRef.hide();
    this.directoryGuid = '';
  }

  confirmDelete(): void {
    this.spinnerService.show();
    this.productService.deleteFile(this.directoryGuid)
      .subscribe(
        response => {
          if (response.success === true) {
            this.toastr.success('Image removed successfuly', '');
            this.directoryGuid = '';
          } else {
            this.toastr.error('Internal server error', '');
          }
        },
        error => {
        },
        () => {

        });
        this.productService.getFeature(this.featureId).subscribe(
      feature => {
        switch (this.fileType) {
          case 10:
          this.image = feature.items.filter(a => a.id === this.featureItemId)[0].image;
            break;
          default:
            break;
        }
        this.notify.emit(feature.items.filter(a => a.id === this.featureItemId)[0].image);
          });
    this.spinnerService.hide();
    this.modalRef.hide();
  }
}

import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConfiguratorsListComponent} from './configurators-list/configurators-list.component';
import {EditConfiguratorComponent} from './configurator-edit/edit-configurator.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Configurators'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: ConfiguratorsListComponent,
        data: {
          title: 'ConfiguratorList'
        }
      },
      {
        path: 'edit/:id',
        component: EditConfiguratorComponent,
        data: {
          title: 'EditConfigurator'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ConfiguratorsRoutingModule { }

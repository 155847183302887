import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Import Containers
import { FullLayout, SimpleLayout } from './containers';
import { CanActivateViaAuthGuard } from 'app/can-activate-via-auth-guard';
import { CanActivateViaAdminGuard } from './can-activate-via-admin-guard';
import { CanActivateViaStandardUserGuard } from './can-activate-via-standard-guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayout,
    canActivate: [CanActivateViaAuthGuard],
    // canActivateChild: [CanActivateViaAuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'blog',
        loadChildren: () => import('./views/blog/blog.module').then(m => m.BlogModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'download',
        loadChildren: () => import('./views/download/download.module').then(m => m.DownloadModule)
      },
      {
        path: 'distributors',
        loadChildren: () => import('./views/distributors/distributors.module').then(m => m.DistributorsModule)
      },
      {
        path: 'content',
        loadChildren: () => import('./views/content/content.module').then(m => m.ContentModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'configurators',
        loadChildren: () => import('./views/configurators/configurators.module').then(m => m.ConfiguratorsModule)
      },
      {
        path: 'lumen-output-range',
        loadChildren: () => import('./views/lumen-output-range/lumen-output-range.module').then(m => m.LumenOutputRangeModule)
      },
      {
        path: 'dependencies',
        loadChildren: () => import('./views/dependencies/dependencies.module').then(m => m.DependenciesModule)
      },
      {
        path: 'pimcore',
        loadChildren: () => import('./views/pimcore/pimcore.module').then(m => m.PimcoreModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./views/projects/projects.module').then(m => m.ProjectsModule)
      },
    ]
  },
  {
    path: 'pages',
    component: SimpleLayout,
    data: {
      title: 'Pages'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule),
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [CanActivateViaAuthGuard, CanActivateViaStandardUserGuard, CanActivateViaAdminGuard]
})
export class AppRoutingModule { }

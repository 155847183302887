<form
  [formGroup]="postAddForm"
  (ngSubmit)="onSubmit()"
  novalidate
  #pform="ngForm"
>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Basic information
            <button type="submit" class="btn btn-sm btn-primary float-right">
              <i class="fa fa-save"></i> Submit
            </button>
            <button
              type="button"
              class="btn btn-sm btn-warning float-right mr-2"
              (click)="onCancel()"
            >
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary float-right mr-2"
              [routerLink]="['/distributors/post/list']"
            >
              <i class="fa fa-arrow-left"></i> Post list
            </button>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="title">Title</label>
              <input
                type="text"
                class="form-control"
                id="title"
                formControlName="title"
              />
              <div
                *ngIf="
                  title.invalid &&
                  (title.dirty || title.touched || pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="title.errors.required">Title is required.</div>
              </div>
            </div>
            <div class="form-group">
              <label for="info">Main category</label>
              <select
                class="form-control"
                formControlName="mainCategoryId"
                name="mainCategoryId"
              >
                <option
                  *ngFor="let category of categories"
                  [value]="category.id"
                >
                  {{ category.title }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="info">Subcategory name</label>
              <select
                class="form-control"
                formControlName="categoryId"
              >
              <option value="">No subcategory</option>
                <option
                  *ngFor="
                    let category of subCategories
                      | subCategory : selectedMainCategoryId
                  "
                  [value]="category.id"
                >
                  {{ category.title }}
                </option>
              </select>
              <div
                *ngIf="
                  categoryId.invalid &&
                  (categoryId.dirty || categoryId.touched || pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="categoryId.errors.required">
                  Category is required.
                </div>
              </div>
            </div>

            <div class="form-group col-lg-4 p-0">
              <label for="title">Publication date</label>
              <input
                type="text"
                class="form-control"
                id="publicationDate"
                #dppo="bsDatepicker"
                placeholder="yyyy-mm-dd"
                [bsConfig]="{
                  dateInputFormat: 'YYYY-MM-DD',
                  showTodayButton: true
                }"
                formControlName="publicationDate"
                bsDatepicker
              />
              <span class="input-group-addon" (click)="dppo.toggle()"
                ><i class="fa fa-calendar"></i
              ></span>
              <div
                *ngIf="
                  publicationDate.invalid &&
                  (publicationDate.dirty ||
                    publicationDate.touched ||
                    pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="publicationDate.errors.required">
                  Publication date is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="info">Link name</label>
              <select class="form-control" formControlName="linkName" required>
                <option *ngFor="let item of linkNames" [value]="item.value">
                  {{ item.label }}
                </option>
              </select>
              <div
                *ngIf="
                  linkName.invalid &&
                  (linkName.dirty || linkName.touched || pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="linkName.errors.required">
                  Link name is required.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/.col-->
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">Description</div>
          <div class="card-body">
            <angular-tinymce
              formControlName="description"
              ngDefaultControl
            ></angular-tinymce>
          </div>
        </div>
        <div class="card">
          <div class="card-footer">
            <button
              type="reset"
              class="btn btn-sm btn-danger"
              (click)="onCancel()"
            >
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="fa fa-dot-circle-o"></i> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../../user/user.model';
import {ErrorService} from '../../error.service';
import {UserService} from '../../user/user.service';
import {Observable} from 'rxjs';
import {ApiGetUsersResponse} from './api/api-users-responses.model';
import {AddUser} from './add-user.model';
import {UserLanguage} from '../../user/user-language.model';
import {UserResponse} from '../../user/user-response.model';

@Injectable()
export class UsersService {

  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getUsers(): Observable<User[]> {
    return this.http.get<ApiGetUsersResponse>(this.apiUrl + '/users?langId=' + this.user.languageId).pipe(
      map((response: ApiGetUsersResponse) => {
        const users = [];

        for (const userResponse of response.data) {
          const user: User = {
            id: userResponse.id,
            name: userResponse.name,
            phone: userResponse.phone,
            email: userResponse.email,
            isVerified: userResponse.isVerified,
            notificationsEnabled: userResponse.notificationsEnabled,
            languageId: userResponse.languageId,
            availableLanguages: userResponse.languages.data,
            role: userResponse.role
          }
          users.push(user);
        }
        return users;
      }),
      catchError(this.errorService.handleError<any>('getUsers')),);
  }

  getUser(userId: number): Observable<User> {
    return this.http.get<UserResponse>(this.apiUrl + '/users/' + userId + '?langId=' + this.user.languageId).pipe(
      map(u => {
        return {
          id: u.id,
          name: u.name,
          phone: u.phone,
          email: u.email,
          isVerified: u.isVerified,
          notificationsEnabled: u.notificationsEnabled,
          languageId: u.language_id,
          availableLanguages: u.languages,
          role: u.role
        }
      }),
      catchError(this.errorService.handleError<any>('getUsers')),);
  }


  deleteUser(userId: number) {
    return this.http
      .delete(this.apiUrl + '/users/' + userId + '?langId=' + this.user.languageId, {headers: this.headers}).pipe(
      catchError(this.errorService.handleError<any>('deleteUser')));
  }

  addUser(user: AddUser): Observable<User> {
    return this.http
      .post(this.apiUrl + '/users', JSON.stringify(user), {headers: this.headers}).pipe(
      catchError(this.errorService.handleError<any>('addUser')));
  }

  updateUser(userId: number, user: any) {
    return this.http
      .put(this.apiUrl + '/users/' + userId,
        JSON.stringify(user), {headers: this.headers}).pipe(
      catchError(this.errorService.handleError<any>('updateUser')));
  }

  getLanguages(): Observable<UserLanguage[]> {
    return this.http.get<UserLanguage>(this.apiUrl + '/languages').pipe(
      map((response) => response),
      catchError(this.errorService.handleError<any>('getLanguages')),);
  }
}

import { CategoryList } from './Distributor.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subCategory'
})
export class SubCategoryPipe implements PipeTransform {
  public transform(items: Array<CategoryList>, categoryId: number): any {
    if (!items || !categoryId) {
      return items;
    }else{
      return items.filter(item => parseInt(item.parent_id as any) === parseInt(categoryId as any));
    }
  }
}

import { TraderPosition } from './../Distributor.model';
import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {DistributorService} from '../distributor.service';
import {Country} from '../country/country.model';
import {CountryService} from '../country/country.service';
import {Distributor} from '../Distributor.model';
import {DatePipe, Location} from '@angular/common';
import { AddDistributorUser } from '../user-add/AddDistributorUser.model';
import { User } from 'app/user/user.model';

@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent {

  distributorForm: FormGroup;
  distributorId: number;
  distributor: AddDistributorUser;
  countries: Country[];
  positions: TraderPosition[] = [];
  supervisors: User[] = [];
  partners: User[] = [];

  constructor(private fb: FormBuilder, private distributorService: DistributorService, private router: Router,
              private route: ActivatedRoute, private countryService: CountryService, private toastr: ToastrService,
              private datePipe: DatePipe, private location: Location) {
    this.createForm();
    this.route.params.subscribe(params => {
      this.distributorId = parseInt(params['id']);
      countryService.getCountries().subscribe(
        countries => {
          this.countries = countries
          distributorService.getUser(this.distributorId).subscribe(
            distributor => {
              this.distributor = distributor;
              this.setFormValues();
            });
        }
      );
    });
    this.distributorService.getPositions().subscribe(positions => {
      this.positions = positions;
    });
    this.distributorService.getMenagers('patners-first').subscribe(partners => {
      this.partners = partners;
    });
    this.distributorService.getMenagers('business-supervisor').subscribe(supervisors => {
      this.supervisors = supervisors;
    });
  }

  onSubmit() {
    if (this.isValidForm()) {
      const distributor = this.prepareEditDistributor();
      this.distributorService.updateDistributorUser(this.distributorId, distributor)
        .subscribe(
          () => {
            this.toastr.success('Distributtor created successfully');
            // this.router.navigate(['./distributors/user/list']);
          });
    }
  }

  onCancel() {
    this.location.back();
  }

  createForm() {
    this.distributorForm = this.fb.group({
      company: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      trader_position_id: ['', Validators.required],
      address: ['', Validators.required],
      city: '',
      postalCode: '',
      voivodeship_id: '',
      country_id: [null, Validators.required],
      email: [''],
      www: '',
      tel: '',
      tel2: '',
      type: [0, Validators.required],
      latitude: '',
      longitude: '',
      isActive: [''],
      is_verified: [''],
      is_visible_www: [''],
      is_show_room: false,
      is_service: false,
      is_stock: false,
      is_iteam: false,
      partner_first_id: [''],
      supervisor_id: [''],
    });
  }

  prepareEditDistributor(): any {
    const formModel = this.distributorForm.value;

    console.log(formModel);

    const distributor: any = {
      id: this.distributorId,
      name: formModel.name as string,
      surname: formModel.surname as string,
      company: formModel.company as string,
      address: formModel.address as string,
      postalCode: formModel.postalCode as string,
      city: formModel.city as string,
      country_id: formModel.country_id,
      country: null,
      voivodeship_id: formModel.voivodeship_id,
      tel: formModel.tel as string,
      tel2: formModel.tel2 as string,
      email: formModel.email as string,
      www: formModel.www as string,
      type: formModel.type,
      latitude: formModel.latitude as string,
      longitude: formModel.longitude as string,
      isActive: formModel.isActive as boolean,
      is_verified: formModel.is_verified as boolean,
      is_visible_www: formModel.is_visible_www as boolean,
      partner_first_id: formModel.partner_first_id as boolean,
      supervisor_id: formModel.supervisor_id as boolean,
      is_show_room: formModel.is_show_room ? 1 : 0,
      is_service: formModel.is_service ? 1 : 0,
      is_stock: formModel.is_stock ? 1 : 0,
      is_iteam: formModel.is_iteam ? 1 : 0,
    };
    return distributor;
  }

  isValidForm() {
    return this.distributorForm.status === 'VALID';
  }

  get isActive() {
    return this.distributorForm.get('isActive');
  }

  get is_verified() {
    return this.distributorForm.get('is_verified');
  }

  get is_visible_www() {
    return this.distributorForm.get('is_visible_www');
  }

  get company() {
    return this.distributorForm.get('company');
  }

  get name() {
    return this.distributorForm.get('name');
  }

  get surname() {
    return this.distributorForm.get('surname');
  }

  get email() {
    return this.distributorForm.get('email');
  }

  get address() {
    return this.distributorForm.get('address');
  }

  get country_id() {
    return this.distributorForm.get('country_id');
  }
  get voivodeship_id(){
    return this.distributorForm.get('voivodeship_id');
  }

  get trader_position_id() {
    return this.distributorForm.get('trader_position_id');
  }
  get is_show_room() {
    return this.distributorForm.get('is_show_room');
  }
  get is_service() {
    return this.distributorForm.get('is_service');
  }
  get is_stock() {
    return this.distributorForm.get('is_stock');
  }
  get is_iteam() {
    return this.distributorForm.get('is_iteam');
  }
  get supervisor_id() {
    return this.distributorForm.get('supervisor_id');
  }
  get partner_first_id() {
    return this.distributorForm.get('partner_first_id');
  }

  private setFormValues() {
    this.distributorForm.setValue({
      company: this.distributor.company as string,
      name: this.distributor.name as string,
      surname: this.distributor.surname as string,
      address: this.distributor.address as string,
      postalCode: this.distributor.postalCode,
      city: this.distributor.city as string,
      country_id: this.distributor.country_id,
      voivodeship_id: this.distributor.voivodeship_id,
      tel: this.distributor.tel as string,
      tel2: this.distributor.tel2,
      email: this.distributor.email as string,
      www: this.distributor.www as string,
      type: this.distributor.type,
      latitude: this.distributor.latitude as string,
      longitude: this.distributor.longitude as string,
      isActive: this.distributor.isActive as boolean,
      is_verified: this.distributor.is_verified as boolean,
      is_visible_www: this.distributor.is_visible_www as boolean,
      trader_position_id: this.distributor.trader_position_id as number,
      is_show_room: this.distributor.is_show_room as boolean,
      is_service: this.distributor.is_service as boolean,
      is_stock: this.distributor.is_stock as boolean,
      is_iteam: this.distributor.is_iteam as boolean,
      supervisor_id: this.distributor.supervisor_id as number,
      partner_first_id: this.distributor.partner_first_id as number,
    })
  }
}


<form
  [formGroup]="postEditForm"
  (ngSubmit)="onSubmit()"
  novalidate
  #pform="ngForm"
>
  <div class="animated fadeIn">
    <div class="col mb-2">
      <span class="mr-2">Active </span>
      <label class="switch switch-lg switch-3d switch-primary">
        <input
          type="checkbox"
          class="switch-input"
          formControlName="isActive"
        />
        <span class="switch-label"></span>
        <span class="switch-handle"></span>
      </label>
      &nbsp;
      <span class="ml-2 mr-2">Main</span>
      <label class="switch switch-lg switch-3d switch-primary">
        <input type="checkbox" class="switch-input" formControlName="isMain" />
        <span class="switch-label"></span>
        <span class="switch-handle"></span>
      </label>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Basic information
            <button type="submit" class="btn btn-sm btn-primary float-right">
              <i class="fa fa-save"></i> Submit
            </button>
            <button
              type="button"
              class="btn btn-sm btn-warning float-right mr-2"
              (click)="onCancel()"
            >
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary float-right mr-2"
              [routerLink]="['/distributors/post/list']"
            >
              <i class="fa fa-arrow-left"></i> Post list
            </button>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="title">Title</label>
              <input
                type="text"
                class="form-control"
                id="title"
                formControlName="title"
              />
              <div
                *ngIf="
                  title.invalid &&
                  (title.dirty || title.touched || pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="title.errors.required">Title is required.</div>
              </div>
            </div>

            <div class="form-group">
              <label for="info">Main category</label>
              <select
                class="form-control"
                formControlName="mainCategoryId"
                name="mainCategoryId"
              >
                <option
                  *ngFor="let category of categories"
                  [value]="category.id"
                >
                  {{ category.title }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="info">Subcategory</label>
              <select
                class="form-control"
                formControlName="categoryId"
              >
              <option value="">No subcategory</option>
                <option
                  *ngFor="
                    let category of subCategories
                      | subCategory : selectedMainCategoryId
                  "
                  [value]="category.id"
                >
                  {{ category.title }}
                </option>
              </select>
              <div
                *ngIf="
                  categoryId.invalid &&
                  (categoryId.dirty || categoryId.touched || pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="categoryId.errors.required">
                  Category is required.
                </div>
              </div>
            </div>

            <div class="form-group col-lg-4 p-0">
              <label for="title">Publication date</label>
              <input
                type="text"
                class="form-control"
                id="publicationDate"
                #dppo="bsDatepicker"
                placeholder="yyyy-mm-dd"
                [bsConfig]="{
                  dateInputFormat: 'YYYY-MM-DD',
                  showTodayButton: true
                }"
                formControlName="publicationDate"
                bsDatepicker
              />
              <span class="input-group-addon" (click)="dppo.toggle()"
                ><i class="fa fa-calendar"></i
              ></span>
              <div
                *ngIf="
                  publicationDate.invalid &&
                  (publicationDate.dirty ||
                    publicationDate.touched ||
                    pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="publicationDate.errors.required">
                  Publication date is required.
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="info">Link name</label>
              <select class="form-control" formControlName="linkName" required>
                <option *ngFor="let item of linkNames" [value]="item.value">
                  {{ item.label }}
                </option>
              </select>
              <div
                *ngIf="
                  linkName.invalid &&
                  (linkName.dirty || linkName.touched || pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="linkName.errors.required">
                  Link name is required.
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="description">Description</label>
              <angular-tinymce
                formControlName="description"
                ngDefaultControl
              ></angular-tinymce>
              <div
                *ngIf="
                  description.invalid &&
                  (description.dirty || description.touched || pform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="description.errors.required">
                  Description is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="lead">Main image</label>
              <div class="row">
                <div class="col-6"></div>
                <div class="col-6"></div>
              </div>
              <div id="qq-template" hidden>
                <div
                  class="qq-uploader-selector qq-uploader"
                  qq-drop-area-text="Drop files here"
                >
                  <div
                    class="qq-total-progress-bar-container-selector qq-total-progress-bar-container"
                  >
                    <div
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="qq-total-progress-bar-selector qq-progress-bar qq-total-progress-bar"
                    ></div>
                  </div>
                  <div
                    class="qq-upload-drop-area-selector qq-upload-drop-area"
                    qq-hide-dropzone
                  >
                    <span class="qq-upload-drop-area-text-selector"></span>
                  </div>
                  <div class="buttons">
                    <div class="qq-upload-button-selector qq-upload-button">
                      <div>Select file</div>
                    </div>
                  </div>
                  <span class="qq-drop-processing-selector qq-drop-processing">
                    <span>Processing dropped files...</span>
                    <span
                      class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"
                    ></span>
                  </span>
                  <ul
                    class="qq-upload-list-selector qq-upload-list"
                    aria-live="polite"
                    aria-relevant="additions removals"
                  >
                    <li>
                      <div class="qq-progress-bar-container-selector">
                        <div
                          role="progressbar"
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          class="qq-progress-bar-selector qq-progress-bar"
                        ></div>
                      </div>
                      <span
                        class="qq-upload-spinner-selector qq-upload-spinner"
                      ></span>
                      <img
                        class="qq-thumbnail-selector"
                        qq-max-size="100"
                        qq-server-scale
                      />
                      <span
                        class="qq-upload-file-selector qq-upload-file"
                      ></span>
                      <span
                        class="qq-edit-filename-icon-selector qq-edit-filename-icon"
                        aria-label="Edit filename"
                      ></span>
                      <input
                        class="qq-edit-filename-selector qq-edit-filename"
                        tabindex="0"
                        type="text"
                      />
                      <span
                        class="qq-upload-size-selector qq-upload-size"
                      ></span>
                      <span
                        role="status"
                        class="qq-upload-status-text-selector qq-upload-status-text"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
              <post-main-image
                *ngIf="postEdit"
                divId="post-main-image"
                (eventImage)="setMainImage($event)"
                [image]="postEdit.mainImageSrc"
                [postId]="postEdit.id"
                fileType="3"
              >
              </post-main-image>
            </div>
            <div class="form-group">
              <label for="lead">Add file</label>
              <div class="row">
                <div class="col-6"></div>
                <div class="col-6"></div>
              </div>
              <div id="qq-template" hidden>
                <div
                  class="qq-uploader-selector qq-uploader"
                  qq-drop-area-text="Drop files here"
                >
                  <div
                    class="qq-total-progress-bar-container-selector qq-total-progress-bar-container"
                  >
                    <div
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="qq-total-progress-bar-selector qq-progress-bar qq-total-progress-bar"
                    ></div>
                  </div>
                  <div
                    class="qq-upload-drop-area-selector qq-upload-drop-area"
                    qq-hide-dropzone
                  >
                    <span class="qq-upload-drop-area-text-selector"></span>
                  </div>
                  <div class="buttons">
                    <div class="qq-upload-button-selector qq-upload-button">
                      <div>Select file</div>
                    </div>
                  </div>
                  <span class="qq-drop-processing-selector qq-drop-processing">
                    <span>Processing dropped files...</span>
                    <span
                      class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"
                    ></span>
                  </span>
                  <ul
                    class="qq-upload-list-selector qq-upload-list"
                    aria-live="polite"
                    aria-relevant="additions removals"
                  >
                    <li>
                      <div class="qq-progress-bar-container-selector">
                        <div
                          role="progressbar"
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          class="qq-progress-bar-selector qq-progress-bar"
                        ></div>
                      </div>
                      <span
                        class="qq-upload-spinner-selector qq-upload-spinner"
                      ></span>
                      <img
                        class="qq-thumbnail-selector"
                        qq-max-size="100"
                        qq-server-scale
                      />
                      <span
                        class="qq-upload-file-selector qq-upload-file"
                      ></span>
                      <span
                        class="qq-edit-filename-icon-selector qq-edit-filename-icon"
                        aria-label="Edit filename"
                      ></span>
                      <input
                        class="qq-edit-filename-selector qq-edit-filename"
                        tabindex="0"
                        type="text"
                      />
                      <span
                        class="qq-upload-size-selector qq-upload-size"
                      ></span>
                      <span
                        role="status"
                        class="qq-upload-status-text-selector qq-upload-status-text"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
              <post-add-file
                *ngIf="postEdit"
                divId="post-files"
                (eventImage)="setMainImage($event)"
                [files]="postEdit.files"
                [postId]="postEdit.id"
                fileType="3"
              >
              </post-add-file>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group">Add Link</div>
            <div class="form-group">
              <label for="linkNameDef">Link name</label>
              <input
                type="text"
                class="form-control"
                id="linkNameDef"
                name="linkNameDef"
                formControlName="linkNameDef"
              />
            </div>
            <div class="form-group">
              <label for="linkUrl">Add URL</label>
              <input
                type="text"
                class="form-control"
                id="linkUrl"
                name="linkUrl"
                formControlName="linkUrl"
              />
            </div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                (click)="storePostLink()"
              >
                Add
              </button>
            </div>
            <div
              class="mt-4"
              *ngIf="postEdit && postEdit.links && postEdit.links.length"
            >
              <post-link-table
                [links]="postEdit.links"
                [postId]="postId"
              ></post-link-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-footer">
            <button
              type="reset"
              class="btn btn-sm btn-danger"
              (click)="onCancel()"
            >
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-primary ml-2">
              <i class="fa fa-dot-circle-o"></i> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

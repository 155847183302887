import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {QuillModule} from 'ngx-quill'
import {TabsModule} from 'ngx-bootstrap/tabs';

import {AddProductComponent} from './add-product/add-product.component';
import {EditProductComponent} from './edit-product/edit-product.component';
import {ProductListComponent} from './product-list/product-list.component';

import {CategoryListComponent} from './categories/category-list/category-list.component';
import {EditCategoryComponent} from './categories/edit-category/edit-category.component';
import {AddCategoryComponent} from './categories/add-category/add-category.component';

import {FeatureListComponent} from './features/feature-list/feature-list.component';
import {EditFeatureComponent} from './features/edit-feature/edit-feature.component';
import {AddFeatureComponent} from './features/add-feature/add-feature.component';

import {ProductService} from './api/product.service';
import {ProductsRoutingModule} from './products-routing.module';
 
import {CategoryService} from 'app/views/products/categories/category.service';
import {EditProductRelatedProductsComponent} from 'app/views/products/edit-product/related-products/edit-product-related-products.component';
import {EditProductAlbumImagesComponent} from 'app/views/products/edit-product/album-images/edit-product-album-images.component';
import {EditProductMainImageComponent} from 'app/views/products/edit-product/main-image/edit-product-main-image.component';
import {ProductFeaturesComponent} from './edit-product/product-features/product-features.component';
import {CategoryConstantService} from './categories/category-constant.service';
import {ProductAdditionalDataComponent} from './edit-product/product-additional-data/product-additional-data.component';
import {environment} from '../../../environments/environment';
import {FeatureComponent} from './edit-product/product-features/components/feature/feature.component';
import {RangeFeatureComponent} from './edit-product/product-features/components/range-feature/range-feature.component';
import {ProductsComponent} from './products/products.component';
import {EditFeatureItemImageComponent} from './features/edit-feature/image/edit-feature-item-image.component';
import { AlbumDocumentsComponent } from './edit-product/album-documents/album-documents.component';
import { ContentService } from '../content/content.service';
import { GalleryService } from '../content/gallery.service';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { CdkTableModule } from '@angular/cdk/table';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TinyMceModule } from 'angular-tinymce';
import { SharedModule } from '../shared/shared.module';
export function uploaderHandler(blobInfo, success, failure) {

  const token = JSON.parse(localStorage.getItem('current_klus_simpliq_user'));

  let xhr, formData;
  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST',  environment.apiUrl + '/uploader');
  xhr.setRequestHeader('Authorization', 'Bearer ' + token.access_token);
  xhr.onload = function() {
    let json;

    if (xhr.status !== 200) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location !== 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };
  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
}
@NgModule({
  imports: [
    ProductsRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TinyMceModule.forRoot({
      baseURL : '/assets/tinymce',
      skin_url : '/assets/tinymce/skins/lightgray',
      theme_url : '/assets/tinymce/themes/modern/theme.min.js',
      tinymceScriptURL : '/assets/tinymce/tinymce.min.js',
      menubar: false,
      toolbar_items_size: 'small',
      autoresize_max_height: 450,
      autoresize_min_height: 300,
      image_advtab: true,
      images_upload_url: environment.apiUrl + '/uploader',
      images_upload_base_path: '',
      toolbar1: 'bold italic underline strikethrough |styleselect formatselect fontselect fontsizeselect | image code',
      toolbar2: 'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink ',
      plugins: ['autoresize link image lists table code'],
      verify_html: false,
      valid_children : '+body[style]',
      relative_urls : false,
      // images_upload_credentials: true,
      // images_reuse_filename: true,
      images_upload_handler: uploaderHandler,
    })
  ],
  declarations: [
    AddProductComponent,
    EditProductComponent,
    ProductListComponent,
    CategoryListComponent,
    EditCategoryComponent,
    AddCategoryComponent,
    FeatureListComponent,
    EditFeatureComponent,
    AddFeatureComponent,
    EditProductRelatedProductsComponent,
    EditProductAlbumImagesComponent,
    EditProductMainImageComponent,
    ProductFeaturesComponent,
    ProductAdditionalDataComponent,
    FeatureComponent,
    RangeFeatureComponent,
    ProductsComponent,
    EditFeatureItemImageComponent,
    AlbumDocumentsComponent,
  ],
  entryComponents: [
  ],
  providers: [
    DatePipe,
    BsModalService,
    ContentService,
    GalleryService,
    CategoryService,
    ProductService,
    CategoryConstantService
  ]
})
export class ProductsModule {}


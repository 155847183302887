<div class="animated fadeIn">
  <div class="example-container">
    <div class="example-header">
      <mat-form-field floatPlaceholder="never">
        <input matInput #filter placeholder="Filter features">
      </mat-form-field>
      <div style="width:5%"></div>
      <div style="width:30%">
        <select class="form-control" (change)="onCategoryChange($event.target.value)">
          <option value="-1">Select category</option>
          <option *ngFor="let category of orderedCategories | async" [value]="category.id">{{category.name}}</option>
        </select>
      </div>
    </div>

    <mat-paginator #paginator [length]="featureDatabase.data.length" [pageIndex]="0" [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <mat-table #table [dataSource]="dataSource" matSort>

      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Id</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Nazwa</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.name}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="categoryName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Kategoria</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.categoryName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="modifiedOn" >
        <mat-header-cell *matHeaderCellDef mat-sort-header> Zmodyfikowano</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.modifiedOn | date : 'yyyy-mm-dd HH:mm'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdOn" >
        <mat-header-cell *matHeaderCellDef mat-sort-header> Utworzono</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.createdOn | date : 'yyyy-mm-dd HH:mm'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="sortOrder" >
        <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 5%"> Sort order</mat-header-cell>
        <mat-cell *matCellDef="let row" style="flex: 0 0 5%">{{row.sortOrder}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef> Is active</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span *ngIf="row && row.active == 1" class="badge badge-success">Active</span>
          <span *ngIf="row && row.active == 0" class="badge badge-dark">Inactive</span>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="isVisibleOnSite">
        <mat-header-cell *matHeaderCellDef> Is visible</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span *ngIf="row && row.isVisibleOnSite == 1" class="badge badge-success">Active</span>
          <span *ngIf="row && row.isVisibleOnSite == 0" class="badge badge-dark">Inactive</span>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="btn btn-sm btn-primary" type="button" (click)="redirectToEdit(row.id)">Edit</button>
          <button class="btn btn-sm btn-danger"
                  type="button"
                  *ngIf="!isRestrictedUser"
                  (click)="openModal(template, row.id)">Delete</button>
          <button class="btn btn-sm btn-secondary" type="button" *ngIf="!isRestrictedUser && categoryFilterActive" (click)="sortUp(row.id)" [disabled]="dataSource.categoryFilter === -1">
            <i class="fa fa-chevron-up"></i>
          </button>
          <button class="btn btn-sm btn-secondary" type="button"
                  *ngIf="!isRestrictedUser && categoryFilterActive" (click)="sortDown(row.id)" [disabled]="dataSource.categoryFilter === -1">
            <i class="fa fa-chevron-down"></i>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  </div>
</div>
<ng-template #template>
  <div class="modal-body text-center">
    <p>Do you want to confirm?</p>
    <button type="button" class="btn btn-default" (click)="confirm()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="decline()">No</button>
  </div>
</ng-template>
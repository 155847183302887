<div class="col-md-12">
    <div class="row">
        <div class="col-md-12">
            Last modified by&nbsp;:&nbsp;<span id="lastInRelations"></span>
        </div>
    </div>
        <div class="row mt-3">
                <div class="col-md-6">
                    <h5 >Related products list</h5>
                    <form class="example-form" (ngSubmit)="onAutocompleteFormSubmit()">
                        <div class="form-group">
                            <input class="form-control" [formControl]="autocompleteControl" [matAutocomplete]="auto" placeholder="Enter product name"
                                   type="text">
                        </div>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="autocompleteOptionSelected($event.option.value)">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" class="tt-dropdown-menu">
                            <span class="tt-option-span">
                                    {{ option.name }} / Ref. {{option.catalogueNumber}}
                            </span>
                            </mat-option>
                        </mat-autocomplete>
                    </form>
                
                    <div *ngIf="childs">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th style="width: 5%">Id</th>
                                    <th style="width: 20%">Name</th>
                                    <th style="width: 20%">Catalogue number</th>
                                    <th style="width: 20%">Extra information</th>
                                    <th style="width: 20%">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let child of childs">
                                    <td>{{child.id}}</td>
                                    <td>
                                        <a [routerLink]="['/products/edit', child.id]">{{child.name}}</a>
                                    </td>
                                    <td>{{child.catalogueNumber}}</td>
                                    <td><div *ngIf="child.bindingAngle">bindingAngle : {{child.bindingAngle}}</div></td>
                                    <td>
                                        <button *ngIf="isExtraRelationInfoProduct(child.categoryId)" class="btn btn-sm btn-warning mr-1" type="button" (click)="edit(child.id)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                        <button class="btn btn-sm btn-danger" type="button" (click)="confirmDelete(child.id)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ng-template #extraRelationInfoTemplate>
                        <div class="modal-header">
                            <h4 class="modal-title">Enter additional information</h4>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="extraRelationInfoForm">
                                <div class="form-group">
                                    <label for="bindingAngle">Binding angle</label>
                                    <select class="form-control" formControlName="bindingAngle">
                                        <option value="">None</option>
                                        <option *ngFor="let value of bindingAngleValues" [value]="value">{{value}}</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" (click)="declineExtraRelationInfo()">No</button>
                            <button type="button" class="btn btn-primary" (click)="confirmExtraRelationInfo()" [disabled]="!isValidForm()">Yes</button>
                        </div>
                    </ng-template>
                </div>
                
                <div class="col-md-6">
                    <h5 >Products with <strong>{{productName}}</strong> on their relation list</h5>
                    <form class="example-form" (ngSubmit)="onAutocompleteFormSubmit()">
                        <div class="form-group">
                            <input class="form-control" [formControl]="autocompleteControl" [matAutocomplete]="parentAutocomplete" placeholder="Enter product name"
                                   type="text">
                        </div>
                        <mat-autocomplete #parentAutocomplete="matAutocomplete" [displayWith]="displayFn" (optionSelected)="autocompleteParentOptionSelected($event.option.value)">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" class="tt-dropdown-menu">
                            <span class="tt-option-span">
                                    {{ option.name }} / Ref. {{option.catalogueNumber}}
                            </span>
                            </mat-option>
                        </mat-autocomplete>
                    </form>
                
                    <div *ngIf="parents">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th style="width: 5%">Id</th>
                                    <th style="width: 20%">Name</th>
                                    <th style="width: 20%">Catalogue number</th>
                                    <th style="width: 20%">Extra information</th>
                                    <th style="width: 20%">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let parent of parents">
                                    <td>{{parent.id}}</td>
                                    <td>
                                        <a [routerLink]="['/products/edit', parent.id]">{{parent.name}}</a>
                                    </td>
                                    <td>{{parent.catalogueNumber}}</td>
                                    <td><div *ngIf="parent.bindingAngle">bindingAngle : {{parent.bindingAngle}}</div></td>
                                    <td>
                                        <button class="btn btn-sm btn-danger" type="button" (click)="confirmDeleteParent(parent.id)">Delete</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                
                    <ng-template #deleteTemplate>
                        <div class="modal-header">
                            <h4 class="modal-title">Confirm</h4>
                        </div>
                        <div class="modal-body">
                            <p>Do you want to delete this related product?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" (click)="declineDelete()">No</button>
                            <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                        </div>
                    </ng-template>
                
                    <ng-template #extraRelationInfoTemplate>
                        <div class="modal-header">
                            <h4 class="modal-title">Enter additional information</h4>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="extraRelationInfoForm">
                                <div class="form-group">
                                    <label for="bindingAngle">Binding angle2</label>
                                    <select class="form-control" formControlName="bindingAngle">
                                        <option value="">None</option>
                                        <option *ngFor="let value of bindingAngleValues" [value]="value">{{value}}</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" (click)="declineExtraRelationInfo()">No</button>
                            <button type="button" class="btn btn-primary" (click)="confirmExtraRelationInfo()" [disabled]="!isValidForm()">Yes</button>
                        </div>
                    </ng-template>
                </div>
                </div>
                
</div>

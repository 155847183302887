export class ApiGetCountriesResponse {
  constructor(public data) {
  }

  public getData(): ApiCountry[] {
    return this.data;
  }
}

export class ApiGetCountryResponse {
  constructor(public data) {
  }

  public getData(): ApiCountry {
    return this.data;
  }
}

export class ApiCountry {
  constructor(public id: number,
              public name: string) {
  }
}

import { CategoryList } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { UserLanguage } from 'app/user/user-language.model';
import { environment } from '../../../../environments/environment';
import { UserService } from 'app/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as Moment from 'moment';
import { DistributorService } from '../distributor.service';
import { CategoryEdit } from './category-edit.model';

@Component({
  selector: 'category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit {
  categoryId: number;
  categoryEditForm: FormGroup;
  categories: CategoryList[];
  modalRef: BsModalRef;
  languages: UserLanguage[];
  category: CategoryEdit;

  apiUrl: string = environment.pageUrl;

  constructor(private fb: FormBuilder, private router: Router, private distributorsService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService, private route: ActivatedRoute,
    private userService: UserService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.route.params.subscribe(params => {
      this.categoryId = parseInt(params['id']);
      this.distributorsService.getLanguages().subscribe(langs => {
        this.languages = langs
        this.distributorsService.getCategory(this.categoryId).subscribe(
          (category: CategoryEdit) => {
            this.category = category;
            this.setFormValues();
            this.spinnerService.hide();
          }
        );
      });
    });
    this.getIntialData();
    this.createForm();
  }

  getIntialData() {
    this.distributorsService.getAllCategories().subscribe(
      categories => {
        this.categories = categories;
      });
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const categoryData: CategoryEdit = this.getData();
      this.distributorsService.updateCategory(categoryData, this.categoryId)
        .subscribe(
          (res) => {
            this.toastr.success('Category updated successfully');
            this.spinnerService.hide();
            this.router.navigate(['/distributors/categories/list']);
          });
    }
  }

  onCancel() {
    this.location.back()
  }

  private setFormValues() {
    this.categoryEditForm.setValue({
      isActive: this.category.isActive as boolean,
      title: this.category.title as string,
      description: this.category.description as string,
    });
  }


  createForm() {
    this.categoryEditForm = this.fb.group({
      isActive: [''],
      title: ['', Validators.required],
      description: [''],
    });
  }

  private getData(): any {

    const formModel = this.categoryEditForm.value;

    return {
      is_active: formModel.isActive as boolean,
      title: formModel.title as string,
      description: formModel.description as string
    }
  }

  isValidForm() {
    return this.categoryEditForm.status === 'VALID';
  }
  get metaTitle() {
    return this.categoryEditForm.get('metaTitle');
  }

  get metaDescription() {
    return this.categoryEditForm.get('metaDescription');
  }

  get metaKeywords() {
    return this.categoryEditForm.get('metaKeywords');
  }

  get isActive() {
    return this.categoryEditForm.get('isActive');
  }

  get publicationDate() {
    return this.categoryEditForm.get('publicationDate');
  }

  get title() {
    return this.categoryEditForm.get('title');
  }

  get slug() {
    return this.categoryEditForm.get('slug');
  }

  get lead() {
    return this.categoryEditForm.get('lead');
  }

  get content() {
    return this.categoryEditForm.get('content');
  }

  get blogMainCategoriesId() {
    return this.categoryEditForm.get('blogMainCategoriesId');
  }

  get blogArticleTagsId() {
    return this.categoryEditForm.get('blogArticleTagsId');
  }

  get contentControl() {
    const formControl = new FormControl(this.categoryEditForm.value.content as string, Validators.required);
    return this.getControl(formControl, 'content');
  }
  get summaryControl() {
    const formControl = new FormControl(this.categoryEditForm.value.summary as string, Validators.required);
    return this.getControl(formControl, 'summary');
  }

  getControl(formControl, name) {
    const originalControl = this.categoryEditForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }

  getLinkToPreview() {

    let link = '/blog/';

    switch (this.userService.selectedLang().name) {

      case 'en':
        link = '/en/blog/';
        break;
      case 'ro':
        link = '/ro/blog/'
        break;
      case 'pl':
        link = '/blog/'
        break;
      case 'de':
        link = '/de/blog/'
        break;
      case 'cz':
        link = '/cz/blog/'
        break;
      case 'fr':
        link = '/fr/blog/'
        break;
      case 'es':
        link = '/es/blog/'
        break;
      case 'it':
        link = '/it/blog/'
        break;
      case 'ru':
        link = '/ru/blog/'
        break;

    }
    return '';
    // return this.apiUrl + link + this.categories.slug + '?isPreview=1';

  }
}


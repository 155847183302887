import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {Attribute, EditProduct, EditProductSpecification} from '../edit-product.model';
import {ProductService} from '../../api/product.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {User} from '../../../../user/user.model';
import {UserService} from '../../../../user/user.service';
import { Helpers } from 'app/helpers/helpers.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-product-additional-data',
  templateUrl: './product-additional-data.component.html'
})
export class ProductAdditionalDataComponent implements OnInit {

  @Input()
  product: EditProduct;

  @Input()
  productId: number;

  COLOR_FEATURE_ITEM_ID = 1;

  attribute: Attribute;
  specification: EditProductSpecification;
  user: User;

  modalRef: BsModalRef;
  attributeForm: FormGroup;
  specificationForm: FormGroup;
  helpers : any;

  constructor(private productService: ProductService, private formBuilder: FormBuilder, private modalService: BsModalService,
              private toastr: ToastrService, private userService: UserService) {

                this.helpers = new Helpers();
               }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.user = user;
    });
    this.createForm();
  }


  createForm() {
    this.attributeForm = this.formBuilder.group({
      description: ['', Validators.required]
    });

    this.specificationForm = this.formBuilder.group({
      colorId: ['', Validators.required],
      refNumber: ['', Validators.required],
      availableLengths: ['', Validators.required],
    })
  }


  opelDeleteAttributeModal(template: TemplateRef<any>, attribute: Attribute) {
    this.attribute = attribute;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirmAttributeDelete() {
    this.productService.deleteAttrib(this.attribute.id).subscribe(() => {
      this.product.attribs = this.product.attribs.filter(a => a.id !== this.attribute.id);
      this.modalRef.hide();
      this.attribute = null;
      
      this.productService.getProduct(this.productId).subscribe(
        product => {
          this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
        });

    });
  }

  declineAttributeDelete(): void {
    this.modalRef.hide();
    this.attribute = null;
  }

  openEditAttributeModal(template: TemplateRef<any>, attribute: Attribute) {
    this.attribute = (attribute !== null ?  attribute : new Attribute(null, ''));

    this.attributeForm.setValue({
      description: this.attribute.description
    });

    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
  }

  confirmAttributeEdit(): void {
    const description = this.attributeForm.value.description;
    if (this.attribute.id !== null) {
      this.productService.updateAttrib(this.attribute.id, description)
        .subscribe(() => {
          this.toastr.success('Attribute renamed successfuly', '');
          this.product.attribs.find(attrib => attrib.id === this.attribute.id).description = description;
          this.attribute = null;

          this.productService.getProduct(this.productId).subscribe(
            product => {
              this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
            });
          
        });
    } else {
      this.productService.addAttrib(this.productId, this.attributeForm.value.description).subscribe(attribs => {
        this.product.attribs = attribs.map(a => new Attribute(a.id, a.description))

        this.productService.getProduct(this.productId).subscribe(
          product => {
            this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
          });
      });
    }
    this.modalRef.hide();
  }

  declineAttributeEdit(): void {
    this.modalRef.hide();
    this.attribute = null;
  }

  openEditSpecificationModal(template: TemplateRef<any>, specification: EditProductSpecification): void {
    this.specification = (specification !== null ? specification : new EditProductSpecification(null, null, '',  '', ''));

    this.specificationForm.setValue({
      colorId: this.specification.colorId,
      refNumber: this.specification.refNumber,
      availableLengths: this.specification.availableLengths
    });

    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
  }

  confirmSpecificationEdit(): void {
    if (this.specification.id !== null) {

      const spec: EditProductSpecification = new EditProductSpecification(this.specification.id, this.specificationForm.value.colorId, '',
        this.specificationForm.value.refNumber, this.specificationForm.value.availableLengths);
      this.productService.updateSpecificationRecord(spec).subscribe(response => {
          this.toastr.success('Specification changed successfuly', '');
          this.product.specificationRecords = response
            .map( r => new EditProductSpecification(r.id, r.colorId, r.color, r.refNumber, r.availableLengths))
          this.specificationForm.reset();

          this.productService.getProduct(this.productId).subscribe(
            product => {
              this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
            });

        });
    } else {
      this.productService.addSpecificationRecord(this.productId, {
        id: null,
        colorId: this.specificationForm.value.colorId,
        color: '',
        refNumber: this.specificationForm.value.refNumber,
        availableLengths: this.specificationForm.value.availableLengths}).subscribe( records => {
        this.product.specificationRecords = records
          .map( r => new EditProductSpecification(r.id, r.colorId, r.color, r.refNumber, r.availableLengths))
        this.specificationForm.reset();
        this.productService.getProduct(this.productId).subscribe(
          product => {
            this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
          });
      });
    }

    this.modalRef.hide();
  }
  declineSpecificationEdit(): void {
    this.modalRef.hide();
    this.specification = null;
  }

  opelDeleteSpecificationModal(template: TemplateRef<any>, specification: EditProductSpecification) {
    this.specification = specification;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirmSpecificationDelete() {
    this.productService.deleteSpecificationRecord(this.specification.id).subscribe(() => {
      this.product.specificationRecords = this.product.specificationRecords.filter(sr => sr.id !== this.specification.id);
      this.modalRef.hide();
      this.specification = null;

      this.productService.getProduct(this.productId).subscribe(
        product => {
          this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
        });

    });
  }

  declineSpecificationDelete(): void {
    this.modalRef.hide();
    this.specification = null;
  }

  attributeSortUp(attributeId: number) {
    this.productService.attributeSortUp(attributeId).subscribe((res) => {
        this.product.attribs = res;

        this.productService.getProduct(this.productId).subscribe(
          product => {
            this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
          });
    });
  }
  attributeSortDown(attributeId: number) {
    this.productService.attributeSortDown(attributeId).subscribe((res) => {
      this.product.attribs = res;

      this.productService.getProduct(this.productId).subscribe(
        product => {
          this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
        });
    });
  }

  specificationSortUp(specId: number) {
    this.productService.specificationSortUp(specId).subscribe((res) => {
        this.product.specificationRecords = res;
        this.productService.getProduct(this.productId).subscribe(
          product => {
            this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
          });
    });
  }

  specificationSortDown(specId: number) {
    this.productService.specificationSortDown(specId).subscribe((res) => {
      this.product.specificationRecords = res;
      this.productService.getProduct(this.productId).subscribe(
        product => {
          this.helpers.setHistoryRecords(this.userService.isRestricted(), product);
        });
    });
  }

  getSpecFeatureItems() {
    return this.product.featureItems.filter(i => i.featureId === this.COLOR_FEATURE_ITEM_ID)
  }

  getFeatureItemName(featureItemId: number) {
    this.getSpecFeatureItems().filter(i => i.id === featureItemId)
  }
  get isRestrictedUser() {
    return this.userService.isRestricted();
  }
 
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {UsersListComponent} from './users-list/users-list.component';
import {CanActivateViaAdminGuard} from '../../can-activate-via-admin-guard';
import {UsersAddComponent} from './users-add/users-add.component';
import {UsersEditComponent} from './users-edit/users-edit.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Users'
    },
    canActivate: [CanActivateViaAdminGuard],
    children: [
      {
        path: 'list',
        component: UsersListComponent,
        data: {
          title: 'Users list'
        }
      },
      {
        path: 'add',
        component: UsersAddComponent,
        data: {
          title: 'Add user'
        }
      },
      {
        path: 'edit/:id',
        component: UsersEditComponent,
        data: {
          title: 'Edit user'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AddFeature} from './add-feature.model';
import {ProductService} from './../../api/product.service';
import {ListProductCategory} from '../../product-list/list-product-category.model';
import {CategoryService} from '../../categories/category.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add-product-feature',
  templateUrl: './add-feature.component.html'
})
export class AddFeatureComponent implements OnInit {

  categories: ListProductCategory[];
  featureForm: FormGroup;
  submitting = false;

  constructor(private fb: FormBuilder, private productService: ProductService, private categoryService: CategoryService,
              private router: Router, private toastr: ToastrService, private location: Location) {
    this.createForm();
    categoryService.getOrderedListProductCategories().subscribe(
      categories => {
        this.categories = categories;
      });
  }


  ngOnInit(): void {
    this.featureForm.get('isRangeFeature').valueChanges.subscribe(
      (checked: boolean) => {
        if (checked) {
          this.featureForm.get('rangeUnit').setValidators([Validators.required]);
        } else {
          this.featureForm.get('rangeUnit').clearValidators();
        }
        this.featureForm.get('rangeUnit').updateValueAndValidity();
      }
    )
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.submitting = true;
      const addFeature = this.prepareSaveProduct();
      this.productService.addFeature(addFeature)
        .subscribe(
          response => {
            this.router.navigate(['./products/features/edit/' + response.data.id]);
            this.toastr.success('Feature created successfuly', '');
          },
          error => this.submitting = false);
    }
  }

  onCancel() {
    this.location.back();
  }

  prepareSaveProduct(): AddFeature {
    const formModel = this.featureForm.value;

    const saveAddProduct: AddFeature = {
      name: formModel.name as string,
      isActive: formModel.isActive as boolean,
      isVisibleOnSite: formModel.isVisibleOnSite as boolean,
      categoryId: formModel.categoryId as number,
      isRangeFeature: formModel.isRangeFeature as boolean,
      rangeUnit: formModel.rangeUnit as string,
      sortOrder: formModel.sortOrder as number
    };
    return saveAddProduct;
  }

  createForm() {
    this.featureForm = this.fb.group({
      name: ['', Validators.required],
      isActive: false,
      isVisibleOnSite: false,
      isRangeFeature: false,
      categoryId: ['', Validators.required],
      rangeUnit: ''
    });
  }

  isValidForm() {
    return this.featureForm.status === 'VALID';
  }

  get name() {
    return this.featureForm.get('name');
  }

  get rangeUnit() {
    return this.featureForm.get('rangeUnit');
  }

  get categoryId() {
    return this.featureForm.get('categoryId');
  }
}


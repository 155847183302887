import { DistributorService } from './../distributor.service';
import { CategoryList, SubCategoryAdd } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { UserLanguage } from 'app/user/user-language.model';
import { environment } from '../../../../environments/environment';
import { UserService } from 'app/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as Moment from 'moment';

@Component({
  selector: 'sub-category-edit',
  templateUrl: './sub-category-edit.component.html',
  styleUrls: ['./sub-category-edit.component.scss']
})
export class SubCategoryEditComponent implements OnInit {
  categoryId: number;
  categoryEditForm: FormGroup;
  categories: CategoryList[];
  linkNames: any[];
  modalRef: BsModalRef;
  languages: UserLanguage[];
  subCategory: any;

  apiUrl: string = environment.pageUrl;

  constructor(private fb: FormBuilder, private router: Router, private distributorsService: DistributorService,
    private toastr: ToastrService, private datePipe: DatePipe, private location: Location,
    private spinnerService: NgxSpinnerService, private route: ActivatedRoute,
    private userService: UserService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.route.params.subscribe(params => {
      this.categoryId = parseInt(params['id']);
      this.distributorsService.getLanguages().subscribe(langs => {
        this.languages = langs
        this.distributorsService.getSubCategory(this.categoryId).subscribe(
          category => {
            this.subCategory = category;
            this.setFormValues();
            this.spinnerService.hide();
          }
        );
      });
    });
    this.getIntialData();
    this.createForm();
  }

  setMainImage(image: string) {
    if (this.subCategory) {
      this.subCategory.mainImageSrc = image;
    }
  }

  getIntialData() {
    this.distributorsService.getAllCategories().subscribe(
      categories => {
        this.categories = categories;
        this.spinnerService.hide();
      });

    this.distributorsService.getLinkNames().subscribe(
      links => {
        this.linkNames = links;
        this.spinnerService.hide();
      }
    );
  }

  onSubmit() {
    if (this.isValidForm()) {
      this.spinnerService.show();
      const data = this.getData();
      this.distributorsService.updateSubCategory(data, this.categoryId)
        .subscribe(
          (res) => {
            this.toastr.success('Category updated successfully');
            this.spinnerService.hide();
          });
    }else{
      alert('err');
      console.log(this.categoryEditForm);
    }
  }

  onCancel() {
    this.location.back()
  }

  private setFormValues() {
    this.categoryEditForm.setValue({
      isActive: this.subCategory.isActive as boolean,
      title: this.subCategory.title as string,
      order: this.subCategory.order as number,
      description: this.subCategory.description as string,
      linkName: this.subCategory.linkName as string,
      parentId: this.subCategory.parentId as number,
    });
  }

  createForm() {
    this.categoryEditForm = this.fb.group({
      isActive: [''],
      title: ['', Validators.required],
      description: [''],
      order: [''],
      linkName: ['', Validators.required],
      parentId: ['', Validators.required],
    });
  }

  private getData(): any {

    const formModel = this.categoryEditForm.value;

    return {
      is_active: formModel.isActive as boolean,
      title: formModel.title as string,
      order: formModel.order as number,
      description: formModel.description as string,
      parent_id: formModel.parentId as number,
      link_name: formModel.linkName as string,
    }
  }

  isValidForm() {
    return this.categoryEditForm.status === 'VALID';
  }

  get isActive() {
    return this.categoryEditForm.get('isActive');
  }

  get title() {
    return this.categoryEditForm.get('title');
  }

  get order() {
    return this.categoryEditForm.get('order');
  }

  get description() {
    return this.categoryEditForm.get('description');
  }

  get parentId() {
    return this.categoryEditForm.get('parentId');
  }

  get linkName() {
    return this.categoryEditForm.get('linkName');
  }

  get contentControl() {
    const formControl = new FormControl(this.categoryEditForm.value.content as string, Validators.required);
    return this.getControl(formControl, 'content');
  }
  get summaryControl() {
    const formControl = new FormControl(this.categoryEditForm.value.summary as string, Validators.required);
    return this.getControl(formControl, 'summary');
  }

  getControl(formControl, name) {
    const originalControl = this.categoryEditForm.get(name);
    if (originalControl.dirty) {
      formControl.markAsDirty();
    }
    if (originalControl.touched) {
      formControl.markAsTouched();
    }
    return formControl;
  }

}


import {Component, ElementRef, OnInit, TemplateRef} from '@angular/core';
import {AuthenticationService} from 'app/auth/authentication.service';
import {UserService} from 'app/user/user.service';
import {User} from 'app/user/user.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeader implements OnInit {

  user: User;
  changePasswordForm: FormGroup;

  constructor(private el: ElementRef, private authenticationService: AuthenticationService, private toastr: ToastrService,
              private modalService: BsModalService, private userService: UserService, private fb: FormBuilder) {
      this.userService.getUser().subscribe((user) => {
        if (user != null) {
          this.user = user;
        }
      });
  }

  ngOnInit(): void {
    const nativeElement: HTMLElement = this.el.nativeElement,
      parentElement: HTMLElement = nativeElement.parentElement;
    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }
    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);

    this.userService.getUser()
    .subscribe(
    result => {
      this.user = result;
    },
    error => {
    },
    () => {
      // 'onCompleted' callback.
    });

    this.changePasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  logout() {
    this.authenticationService.logout();
  }

  confirmChange() {
    if (this.changePasswordForm.status === 'VALID') {
      const form = this.changePasswordForm.value;
      this.userService.changePassword(form.password).subscribe(() => {
        this.toastr.success('Password successfully changed')
        // this.modalRef.hide()
      });
    }
  }

  openModal(template: TemplateRef<any>, ) {
    // this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  get password() {
    return this.changePasswordForm.get('password');
  }

  selectedLang(): String {
    return this.userService.selectedLang().name;
  }
}

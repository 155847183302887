
import {merge as observableMerge, fromEvent as observableFromEvent, Observable, BehaviorSubject} from 'rxjs';

import {map, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Country} from '../country.model';
import {CountryService} from '../country.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';

@Component({
  templateUrl: './countries-list.component.html'
})
export class CountriesListComponent implements OnInit {

  displayedColumns = ['id', 'name', 'actions'];
  dataSource: CountryDatasource | null;
  database: CountryDatabase;
  modalRef: BsModalRef;
  countryId: number;

  @ViewChild('filter', {static: true}) public filter: ElementRef;
  @ViewChild(MatSort, {static: true}) public sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;

  constructor(private toastr: ToastrService, private router: Router, private modalService: BsModalService,
              private countryService: CountryService) {

  }

  ngOnInit(): void {
    this.database = new CountryDatabase(this.countryService);
    this.dataSource = new CountryDatasource(this.database, this.paginator, this.sort)

    observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),)
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  redirectToEdit(countryId) {
    this.router.navigate(['./distributors/countries/edit/' + countryId]);
  }

  openModal(template: TemplateRef<any>, countryId: number) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
    this.countryId = countryId;
  }

  confirm(): void {
    this.countryService.deleteCountry(this.countryId)
      .subscribe(
        response => {
            this.toastr.success('Distributor removed successfully', '');
            this.deleteRowDataTable(this.countryId);
        });
    this.modalRef.hide();
  }

  decline(): void {
    this.countryId = null;
    this.modalRef.hide();
  }

  private deleteRowDataTable(itemId) {
    const dsData = this.database.data;
    const itemIndex = dsData.findIndex(obj => obj['id'] === itemId);
    dsData.splice(itemIndex, 1)
    this.database.dataChange.next(dsData);
  }
}

export class CountryDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);

  get data(): Country[] {
    return this.dataChange.value;
  }

  constructor(private countryService: CountryService) {
    countryService.getCountries().subscribe(
      countries => {
        this.dataChange.next(countries);
      });
  }
}


export class CountryDatasource extends DataSource<Country> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  prevFilterValue: string;

  constructor(private _database: CountryDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this.prevFilterValue = this.filter.toLowerCase();
  }

  connect(collectionViewer: CollectionViewer): Observable<Country[]> {
    const displayDataChanges = [
      this._database.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];
    return observableMerge(...displayDataChanges).pipe(map(() => {

      const data = this._database.data.slice().filter((item: Country) => {
        if (item.name == null) {
          return false;
        }
        const searchStr = (item.name).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      if (this.filter.toLowerCase() !== this.prevFilterValue) {
        this._paginator.pageIndex = 0;
      }
      this.prevFilterValue = this.filter.toLowerCase();

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }
  /** Returns a sorted copy of the database data. */
  sortData(data: Country[]): Country[] {
    if (!this._sort.active || this._sort.direction == '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number|string = '';
      let propertyB: number|string = '';

      switch (this._sort.active) {
        case 'id': [propertyA, propertyB] = [a.id, b.id]; break;
        case 'name': [propertyA, propertyB] = [a.name, b.name]; break;
      }

      let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}

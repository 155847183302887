<form
  [formGroup]="userForm"
  (ngSubmit)="onSubmit()"
  novalidate
  #dform="ngForm"
>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                />
                <div
                  *ngIf="
                    name.invalid &&
                    (name.dirty || name.touched || dform.submitted)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="name.errors.required">Name is required.</div>
                </div>
              </div>

              <div class="form-group col-sm-6">
                <label for="surname">Surname</label>
                <input
                  type="text"
                  class="form-control"
                  id="surname"
                  formControlName="surname"
                />
                <div
                  *ngIf="
                    surname.invalid &&
                    (surname.dirty || surname.touched || dform.submitted)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="surname.errors.required">
                    Surname is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="name">Tel</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  formControlName="phone"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="name">Mobile</label>
                <input
                  type="text"
                  class="form-control"
                  id="mobile"
                  formControlName="mobile"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="email">email</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-sm btn-warning"
              (click)="onCancel()"
            >
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="fa fa-dot-circle-o"></i> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

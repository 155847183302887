import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {DistributorService} from '../distributor.service';
import {AddDistributor} from './AddDistributor.model';
import {CountryService} from '../country/country.service';
import {Country} from '../country/country.model';
import {DatePipe, Location} from '@angular/common';

@Component({
  selector: 'app-add-distributor',
  templateUrl: './add-distributor.component.html'
})
export class AddDistributorComponent {

  distributorForm: FormGroup;
  countries: Country[];

  constructor(private fb: FormBuilder, private distributorService: DistributorService, private router: Router,
              private countryService: CountryService, private toastr: ToastrService, private datePipe: DatePipe,
              private location: Location) {
    this.createForm();
    countryService.getCountries().subscribe(
      countries => {
        this.countries = countries;
      }
    );
  }

  onSubmit() {
    if (this.isValidForm()) {
      const addDistributor = this.prepareAddDistributor();
      this.distributorService.addDistributor(addDistributor)
        .subscribe(() => {
            this.toastr.success('Distributtor created successfully');
            this.router.navigate(['./distributors/list']);
          });
    }
  }

  onCancel() {
    this.location.back();
  }

  prepareAddDistributor(): AddDistributor {
    const formModel = this.distributorForm.value;

    const addDistributor: AddDistributor = {
      name: formModel.name as string,
      address: formModel.address as string,
      postalCode: formModel.postalCode as string,
      city: formModel.city as string,
      country_id: formModel.country_id,
      voivodeship_id: formModel.voivodeship_id,
      tel: formModel.tel as string,
      tel2: formModel.tel2 as string,
      email: formModel.email as string,
      www: formModel.www as string,
      type: formModel.type,
      latitude: formModel.latitude as string,
      longitude: formModel.longitude as string,
      isActive: formModel.isActive as boolean
    };
    return addDistributor;
  }

  createForm() {
    this.distributorForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: '',
      postalCode: '',
      voivodeship_id: '',
      country_id: [null, Validators.required],
      email: '',
      www: '',
      tel: '',
      tel2: '',
      type: [0, Validators.required],
      latitude: '',
      longitude: '',
      isActive: false
    });
  }

  isValidForm() {
    return this.distributorForm.status === 'VALID';
  }

  get name() {
    return this.distributorForm.get('name');
  }

  get address() {
    return this.distributorForm.get('address');
  }
  get country_id() {
    return this.distributorForm.get('country_id');
  }
}


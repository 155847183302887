
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import 'rxjs/Rx';
import {ListProductCategory} from 'app/views/products/product-list/list-product-category.model';
import {User} from '../../../user/user.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ErrorService} from '../../../error.service';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../user/user.service';
import {ApiGetFeaturesResponse} from '../api/api-features-responses.model';
import {ListFeature} from '../features/feature-list/list-feature.model';
import {ProductService} from '../api/product.service';
import {Feature} from '../features/feature.model';
import {FeatureItem} from '../features/featureItem.model';

@Injectable()
export class CategoryService {

  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  categories: ListProductCategory[];
  categoriesFetch: BehaviorSubject<ListProductCategory[]> = new BehaviorSubject<ListProductCategory[]>([]);
  orderedCategories: ListProductCategory[];

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService,
              private productService: ProductService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getOrderedListProductCategories(): Observable<ListProductCategory[]> {
    if(this.categoriesFetch.value.length > 0){
      return this.categoriesFetch.asObservable();
    }else{
      return this.productService.getCategories().pipe(map(
        categories => {
          this.categories = categories.map(c => new ListProductCategory(c.id, c.name, c.parentCategoryId, c.sortOrder, c.isActive));
          for (const category of this.categories) {
            category.name = this.updateCategoryName('---', category, this.categories) + category.name;
          }
          this.categoriesFetch.next(this.getOrderedCategories(this.categories));
          return this.categoriesFetch.value;
        }));
    }

  }

  public updateCategoryName(prefix: string, category: ListProductCategory, allCategories: ListProductCategory[]) {
    if (category.parentCategoryId != null) {
      return prefix + this.updateCategoryName(prefix, allCategories.find(c => c.id == category.parentCategoryId), allCategories);
    }
    return '';
  }

  public getOrderedCategories(categories: ListProductCategory[]) {
    this.orderedCategories = [];
    this.updateCategoriesOrder(categories.filter(c => c.parentCategoryId == null), categories);

    return this.orderedCategories;
  }

  /**
   * return features related with given category and with all parent categories
   * @returns {Observable<ListFeature[]>}
   */
  getRelatedFeatures(categoryId: number, recursive: boolean): Observable<Feature[]> {
    return this.http.get(this.apiUrl + '/categories/' + categoryId + '/features?recursive=' + (recursive ? 1 : 0) + '&langId=' + this.user.languageId).pipe(
      map((response: ApiGetFeaturesResponse) => {
        const listFeatures = [];
        for (const feature of response.data) {

          const featureItems: FeatureItem[] = [];
          for (const featureItemResp of feature.items.data) {
            featureItems.push(new FeatureItem(featureItemResp.id, featureItemResp.bindingValue, featureItemResp.name,featureItemResp.image,
              featureItemResp.featureId, featureItemResp.minIntValue, featureItemResp.maxIntValue, featureItemResp.isActive,featureItemResp.isVisibleOnSite,featureItemResp.isVisibleOnConfigurator,
              featureItemResp.sortOrder));
          }

          const newListFeature = new Feature(feature.id, feature.name, feature.categoryId,
            featureItems, feature.isRangeFeature, feature.rangeUnit, feature.isActive, feature.isVisibleOnSite, feature.sortOrder);
          listFeatures.push(newListFeature);
        } 
        return listFeatures;
      }),
      catchError(this.errorService.handleError<any>('getCategoryRelatedFeatures')),);
  }

  private updateCategoriesOrder(parents: ListProductCategory[], allCategories: ListProductCategory[]) {
    parents = parents.sort((category1, category2) => {
      return category1.sortOrder - category2.sortOrder;
    });

    for (const parentCategoryIndex in parents) {
      const parentCategory = parents[parentCategoryIndex];
      this.orderedCategories.push(parentCategory);

      const children = allCategories.filter(c => c.parentCategoryId == parentCategory.id);
      if (children.length > 0) {
        this.updateCategoriesOrder(children, allCategories);
      }
    }
  }
}

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DistributorService } from '../distributor.service';
import { PostLink } from './../Distributor.model';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'post-link-table',
  templateUrl: './post-link-table.component.html',
  styleUrls: ['./post-link-table.component.scss']
})
export class PostLinkTableComponent implements OnInit {
  @Input() public links: PostLink[];
  @Input() public postId: number;
  public modalRef: BsModalRef;

  public selectedLink: PostLink = {
    name: '',
    url: ''
  };

  constructor(public distributorService: DistributorService, public modalService: BsModalService,
    private toastr: ToastrService, private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  public detachPostLink(linkId: number) {
    this.distributorService.detachPostLink(linkId, this.postId).subscribe(response => {
      this.links = response;
    });
  }

  public openModal(template: TemplateRef<any>, item: PostLink) {
    this.selectedLink = JSON.parse(JSON.stringify(item));
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  public confirm() {
    this.spinnerService.show();
    this.distributorService.updatePostLink(this.selectedLink, this.postId).subscribe(links => {
      this.links = links;
      this.spinnerService.hide();
      this.modalRef.hide();
      this.toastr.success('Link updated');
    })
  }

  decline(): void {
    this.selectedLink = {
      name: '',
      url: '',
    };
    this.modalRef.hide();
  }
}



<li *ngIf="user" class="nav-item dropdown pr-4" dropdown placement="bottom right">
  <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
     dropdownToggle (click)="false">
    <span class="d-md-down-none mr-2">
      <strong>content language:</strong>
    </span>
    <img src="/assets/img/flags/{{selectedLang() | lowercase}}.svg" width="30px" class="mr-1">
  </a>
  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
    <button class="dropdown-item"  *ngFor="let lang of availableLanguages()" (click)="setLanguage(lang.id)" >
      <i class="fa fa-globe"></i> {{lang.name}}
    </button>
  </div>
</li>
import { TraderPosition } from './../Distributor.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DistributorService } from '../distributor.service';
import { CountryService } from '../country/country.service';
import { Country } from '../country/country.model';
import { DatePipe, Location } from '@angular/common';
import { AddDistributorUser } from './AddDistributorUser.model';

@Component({
  selector: 'user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent {

  positions: TraderPosition[] = [];
  distributorForm: FormGroup;
  countries: Country[];

  constructor(private fb: FormBuilder, private distributorService: DistributorService, private router: Router,
    private countryService: CountryService, private toastr: ToastrService, private datePipe: DatePipe,
    private location: Location) {
    this.createForm();
    countryService.getCountries().subscribe(
      countries => {
        this.countries = countries;
      }
    );
    this.distributorService.getPositions().subscribe(positions => {
      this.positions = positions;
    });
  }
  onSubmit() {
    if (this.isValidForm()) {
      const addDistributor = this.prepareAddUser();
      this.distributorService.addDistributorUser(addDistributor)
        .subscribe(response => {
            this.toastr.success('Distributtor created successfully');
            this.router.navigate(['./distributors/user/edit/' + response.id]);
          });
    }
  }

  onCancel() {
    this.location.back();
  }

  prepareAddUser(): AddDistributorUser {
    const formModel = this.distributorForm.value;

    const addDistributor: AddDistributorUser = {
      name: formModel.name as string,
      surname: formModel.surname as string,
      trader_position_id: formModel.trader_position_id as number,
      company: formModel.company as string,
      address: formModel.address as string,
      postalCode: formModel.postalCode as string,
      city: formModel.city as string,
      country_id: formModel.country_id as number,
      voivodeship_id: formModel.voivodeship_id as number,
      tel: formModel.tel as string,
      tel2: formModel.tel2 as string,
      email: formModel.email as string,
      www: formModel.www as string,
      type: formModel.type,
      latitude: formModel.latitude as string,
      longitude: formModel.longitude as string,
      is_show_room: formModel.is_show_room as boolean,
      is_service: formModel.is_service as boolean,
      is_stock: formModel.is_stock as boolean,
      is_iteam: formModel.is_iteam as boolean,
    };
    return addDistributor;
  }

  createForm() {
    this.distributorForm = this.fb.group({
      company: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      trader_position_id: ['', Validators.required],
      address: ['', Validators.required],
      city: '',
      postalCode: '',
      voivodeship_id: '',
      country_id: [null, Validators.required],
      email: [''],
      www: '',
      tel: '',
      tel2: '',
      type: [0, Validators.required],
      latitude: '',
      longitude: '',
      is_show_room: false,
      is_service: false,
      is_stock: false,
      is_iteam: false,
    });
  }

  isValidForm() {
    return this.distributorForm.status === 'VALID';
  }

  get company() {
    return this.distributorForm.get('company');
  }
  get name() {
    return this.distributorForm.get('name');
  }
  get surname() {
    return this.distributorForm.get('surname');
  }
  get address() {
    return this.distributorForm.get('address');
  }
  get country_id() {
    return this.distributorForm.get('country_id');
  }
  get email() {
    return this.distributorForm.get('email');
  }
  get trader_position_id() {
    return this.distributorForm.get('trader_position_id');
  }
  get is_show_room() {
    return this.distributorForm.get('is_show_room');
  }
  get is_service() {
    return this.distributorForm.get('is_service');
  }
  get is_stock() {
    return this.distributorForm.get('is_stock');
  }
  get is_iteam() {
    return this.distributorForm.get('is_iteam');
  }
}

